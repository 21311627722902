import * as React from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Slider, { SliderThumb } from '@mui/material/Slider';
// import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

// Custom styles for ArgonTypography
import ArgonBox from "components/ArgonBox";
// import ArgonTypography from "components/ArgonTypography";

const SpaiSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

function SpaiThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

SpaiThumbComponent.propTypes = {
  children: PropTypes.node,
};

export default function SpaiRangeSliderBar({min, max, from, to, label, scale, setRange}) {
  return (
    // <Stack direction="row" spacing={2}>
    <div>
      {/* <ArgonTypography variant="caption" fontWeight="medium" color="text">
        {valuesLabelFormat(range)}
      </ArgonTypography> */}
      <ArgonBox p={1} borderRadius="md">
        <SpaiSlider
          slots={{ thumb: SpaiThumbComponent }}
          defaultValue={[from, to]}
          min={min}
          step={1}
          max={max}
          onChange={(e, newRange) => setRange(newRange)}
        />
      </ArgonBox>
    {/* </Stack> */}
    </div>
  );
}

SpaiRangeSliderBar.defaultProps = {
  min: 1,
  max: 100,
  from: 1,
  to: 100,
  label: "",
};

SpaiRangeSliderBar.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  label: PropTypes.string,
  setRange: PropTypes.func.isRequired,
};