import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessIcon from '@mui/icons-material/Business';
import SummarizeIcon from '@mui/icons-material/Summarize';

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI contexts
import { setCurrentSection, useArgonController, setCurrentTicker } from "context";

// Page components
import Header from "layouts/company/components/Header";
import ScoreEarningCall from "layouts/company/components/ScoreEarningCall";
import ScoreFinance from "layouts/company/components/ScoreFinance";
import ScoreInvestment from "layouts/company/components/ScoreInvestment";
import ScoreIndustry from "layouts/company/components/ScoreIndustry";
import ScorePeer from "layouts/company/components/ScorePeer";
import ScoreIncome from "layouts/company/components/ScoreIncome";
import ScoreBalanceSheet from "layouts/company/components/ScoreBalanceSheet";
import ScoreCashFlow from "layouts/company/components/ScoreCashFlow";
import ProfileOverview from "layouts/company/components/ProfileOverview";
import ProfileOperations from "layouts/company/components/ProfileOperations";
import ProfileCompetition from "layouts/company/components/ProfileCompetition";

let resultAPICallMap = new Map();

function Company() {
  const [controller, dispatch] = useArgonController();
  const { accessInfo, currentSection } = controller;
  const [errorMsg, setErrorMsg] = useState('');

  const [companyOverview, setCompanyOverview] = useState({});
  const [historyAndMilestones, setHistoryAndMilestones] = useState({});
  const [ownershipAndManagement, setOwnershipAndManagement] = useState({});
  const [operationsAndCapabilities, setOperationsAndCapabilities] = useState({});
  const [competitiveLandscape, setCompetitiveLandscape] = useState({});
  const [innovationAndTechnology, setInnovationAndTechnology] = useState({});
  const [sustainabilityAndCorporateResponsibility,
        setSustainabilityAndCorporateResponsibility] = useState({});
  const [externalFactors, setExternalFactors] = useState({});
  const [loadingCompany, setLoadingCompany] = useState(true);

  const [selectedTab, setSelectedTab] = useState(1);
  const [companyFinance, setCompanyFinance] = useState({});
  const [earningCallScores, setEarningCallScores] = useState({});
  const [financialScores, setFinancialScores] = useState({});
  const [industryScores, setIndustryScores] = useState({});
  const [incomeScores, setIncomeScores] = useState({});
  const [balanceSheetScores, setBalanceSheetScores] = useState({});
  const [cashflowScores, setCashflowScores] = useState({});
  const [investmentScores, setInvestmentScores] = useState({});
  const [bestPeers, setBestPeers] = useState([]);
  const [worstPeers, setWorstPeers] = useState([]);
  const [loadingCompanyScores, setLoadingCompanyScores] = useState(true);
  const [showProgress, setShowProgress] = useState(true);

  // Get ticker from URL
  let { ticker } = useParams()
  ticker = ticker.toUpperCase()
  const logo = Utils.getLogoForTicker(ticker);

  const handleTabChanged = (newTabValue) => {
    setSelectedTab(newTabValue);
  };

  useEffect(() => {
    if (currentSection !== '/company') {
      setCurrentSection(dispatch, '/company');
    }
  }, [ currentSection, dispatch ]);

  useEffect(() => {
    if (!loadingCompany) {
      return;
    };

    if (ticker !== ':ticker') {
      setCurrentTicker(dispatch, ticker);
    }

    let api_cmd = Constants.API_IP_ADDRESS
      + 'get_company_by_ticker?ticker=' + ticker;
    const fetchData = async () => {
      fetch(api_cmd)
      .then((response) => response.json())
      .then((data) => {
        handleCompanyFetched(data);
        resultAPICallMap.set(api_cmd, data);
      })
      .catch((err) => {
        setLoadingCompany(false);
        console.log(api_cmd, err.message);
      });
    };

    const handleCompanyFetched = (data) => {
      setShowProgress(false);

      setCompanyOverview({
        'cik': Utils.getKeyValue(data['company_overview'], 'cik'),
        'ticker': Utils.getKeyValue(data['company_overview'], 'ticker'),
        'exchange': Utils.getKeyValue(data['company_overview'], 'exchange'),
        'name': Utils.getKeyValue(data['company_overview'], 'name'),
        'short_name': Utils.getKeyValue(data['company_overview'], 'short_name'),
        'phone': Utils.getKeyValue(data['company_overview'], 'phone'),
        'website': Utils.getKeyValue(data['company_overview'], 'website'),
        'founders': Utils.getKeyValue(data['company_overview'], 'founders'),
        'founding_year': Utils.getKeyValue(data['company_overview'], 'founding_year'),
        'headquarters_location': Utils.getKeyValue(data['company_overview'], 'headquarters_location'),
        'industry': Utils.getKeyValue(data['company_overview'], 'industry'),
        'sector': Utils.getKeyValue(data['company_overview'], 'sector'),
        'total_employees_worldwide': Utils.getKeyValue(data['company_overview'], 'total_employees_worldwide'),
        'legal_structure': Utils.getKeyValue(data['company_overview'], 'legal_structure'),
        'legal_structure_tags': Utils.getKeyValue(data['company_overview'], 'legal_structure_tags'),
      });

      setHistoryAndMilestones({
        'company_history': Utils.getKeyValue(data['company_overview'], 'company_history'),
        'company_history_milestones': Utils.getKeyValue(data['company_history_and_milestones'], 'company_history_milestones'),
        'mergers_and_acquisitions': Utils.getKeyValue(data['company_history_and_milestones'], 'mergers_and_acquisitions'),
      })

      setOwnershipAndManagement({
        'institutional_ownership': Utils.getKeyValue(data['ownership_and_management'], 'institutional_ownership'),
        'insider_ownership': Utils.getKeyValue(data['ownership_and_management'], 'insider_ownership'),
        'executive_team': Utils.getKeyValue(data['ownership_and_management'], 'executive_team'),
        'list_executives': Utils.getKeyValue(data['ownership_and_management'], 'list_executives'),
        'management_changes': Utils.getKeyValue(data['ownership_and_management'], 'management_changes'),
      })

      setOperationsAndCapabilities({
        'geographic_presence': Utils.getKeyValue(data['operations_and_capabilities'], 'geographic_presence'),
        'operations_capabilities': Utils.getKeyValue(data['operations_and_capabilities'], 'operations_capabilities'),
        'product_or_service_tags': Utils.getKeyValue(data['operations_and_capabilities'], 'product_or_service_tags'),
        'business_segments': Utils.getKeyValue(data['operations_and_capabilities'], 'business_segments'),
        'suppliers_and_partners': Utils.getKeyValue(data['operations_and_capabilities'], 'suppliers_and_partners'),
        'outsourcing_alliances': Utils.getKeyValue(data['operations_and_capabilities'], 'outsourcing_alliances'),
      })

      setCompetitiveLandscape({
        'competitors': Utils.getKeyValue(data['competitive_landscape'], 'competitors'),
        'competitor_tickers': Utils.getKeyValue(data['competitive_landscape'], 'competitor_tickers'),
        'industry_peers': Utils.getKeyValue(data['competitive_landscape'], 'industry_peers'),
        'market_position': Utils.getKeyValue(data['competitive_landscape'], 'market_position'),
        'competitive_advantages': Utils.getKeyValue(data['competitive_landscape'], 'competitive_advantages'),
      })

      setInnovationAndTechnology({
        'innovation_strategy': Utils.getKeyValue(data['innovation_and_technology'], 'innovation_strategy'),
        'R_and_D_and_technology': Utils.getKeyValue(data['innovation_and_technology'], 'R_and_D_and_technology'),
        'emerging_tech_adoption': Utils.getKeyValue(data['innovation_and_technology'], 'emerging_tech_adoption'),
      })

      setSustainabilityAndCorporateResponsibility({
        'ESG_initiatives': Utils.getKeyValue(data['sustainability_and_corporate_responsibility'], 'ESG_initiatives'),
      })

      setExternalFactors(Utils.getKeyValue(data, 'external_factors'));

      setLoadingCompany(false);
    };

    if (resultAPICallMap.has(api_cmd)) {
      handleCompanyFetched(resultAPICallMap.get(api_cmd));
    } else {
      fetchData();
    }
  }, [ dispatch, ticker, loadingCompany ]);

  useEffect(() => {
    if (!loadingCompanyScores) {
      return;
    };

    if (ticker !== ':ticker') {
      setCurrentTicker(dispatch, ticker);
    }

    if (accessInfo.api_key.length == 0 ) {
      return;
    }

    let api_cmd = Constants.API_IP_ADDRESS
      + 'get_all_scores_for_ticker?ticker=' + ticker
      + '&api-key=' + accessInfo.api_key;
    const fetchData = async () => {
      fetch(api_cmd)
      .then((response) => response.json())
      .then((data) => {
        handleCompanyFetched(data);
        resultAPICallMap.set(api_cmd, data);
      })
      .catch((err) => {
        setLoadingCompanyScores(false);
        console.log(api_cmd, err.message);
        setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
      });
    };

    const handleCompanyFetched = (data) => {
      setShowProgress(false);

      setCompanyFinance(Utils.getKeyValue(data, 'company_finance'));

      setIndustryScores({
        'name': Utils.getKeyValue(data['economy']['sector_analysis'], 'name'),
        'analysis': Utils.getKeyValue(data['economy']['sector_analysis'], 'analysis'),
        'outlook_score': Utils.getKeyValue(data['economy']['sector_analysis'], 'outlook_score'),
        'relevant_indicators': Utils.getKeyValue(data['economy']['sector_analysis'], 'relevant_indicators'),
        'gdp_contribution_pct': Utils.getKeyValue(data['economy']['sector_analysis'], 'gdp_contribution_pct'),
        'gdp_contribution_growth': Utils.getKeyValue(data['economy']['sector_analysis'], 'gdp_contribution_growth'),
        'num_peers': Utils.getKeyValue(data['economy'], 'peers'),
      });

      setBestPeers(Utils.getKeyValue(data, 'best_peers'));
      setWorstPeers(Utils.getKeyValue(data, 'worst_peers'));

      setFinancialScores({
        'published_at': Utils.getKeyValue(data['financial_summary'], 'published_at'),
        'liquidity_and_solvency_analysis': Utils.getKeyValue(data['financial_summary'],'liquidity_and_solvency_analysis'),
        'liquidity_and_solvency_score': Utils.getKeyValue(data['financial_summary'], 'liquidity_and_solvency_score'),
        'profitability_analysis': Utils.getKeyValue(data['financial_summary'], 'profitability_analysis'),
        'profitability_score': Utils.getKeyValue(data['financial_summary'], 'profitability_score'),
        'valuation_analysis': Utils.getKeyValue(data['financial_summary'], 'valuation_analysis'),
        'valuation_score': Utils.getKeyValue(data['financial_summary'], 'valuation_score'),
        'dividends_and_payouts_analysis': Utils.getKeyValue(data['financial_summary'], 'dividends_and_payouts_analysis'),
        'dividends_and_payouts_score': Utils.getKeyValue(data['financial_summary'], 'dividends_and_payouts_score'),
        'growth_and_innovation_analysis': Utils.getKeyValue(data['financial_summary'], 'growth_and_innovation_analysis'),
        'growth_and_innovation_score': Utils.getKeyValue(data['financial_summary'], 'growth_and_innovation_score'),
        'cash_flow_and_efficiency_analysis': Utils.getKeyValue(data['financial_summary'], 'cash_flow_and_efficiency_analysis'),
        'cash_flow_and_efficiency_score': Utils.getKeyValue(data['financial_summary'], 'cash_flow_and_efficiency_score'),
        'risk_and_volatility_analysis': Utils.getKeyValue(data['financial_summary'], 'risk_and_volatility_analysis'),
        'risk_and_volatility_score': Utils.getKeyValue(data['financial_summary'], 'risk_and_volatility_score'),
        'macroeconomic_data_impact_analysis': Utils.getKeyValue(data['financial_summary'], 'macroeconomic_data_impact_analysis'),
        'macroeconomic_data_impact_score': Utils.getKeyValue(data['financial_summary'], 'macroeconomic_data_impact_score'),
        'finance_conclusion': Utils.getKeyValue(data['financial_summary'], 'finance_conclusion'),
        'financial_overall_score': Utils.getKeyValue(data['financial_summary'], 'financial_overall_score')
      });

      setInvestmentScores({
        'published_at': Utils.getKeyValue(data['financial_summary'], 'published_at'),
        'investment_opportunity_for_conservative_analysis':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_for_conservative_analysis'),
        'investment_opportunity_score_for_conservative_investor':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_score_for_conservative_investor'),
        'investment_opportunity_for_balanced_analysis':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_for_balanced_analysis'),
        'investment_opportunity_score_for_balanced_investor':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_score_for_balanced_investor'),
        'investment_opportunity_for_growth_analysis':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_for_growth_analysis'),
        'investment_opportunity_score_for_growth_investor':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_score_for_growth_investor'),
        'investment_opportunity_for_speculative_analysis':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_for_speculative_analysis'),
        'investment_opportunity_score_for_speculative_investor':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_score_for_speculative_investor'),
        'investment_opportunity_for_income_analysis':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_for_income_analysis'),
        'investment_opportunity_score_for_income_investor':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_score_for_income_investor'),
        'investment_opportunity_for_value_analysis':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_for_value_analysis'),
        'investment_opportunity_score_for_value_investor':
          Utils.getKeyValue(data['financial_summary'], 'investment_opportunity_score_for_value_investor'),
        'investment_conclusion': Utils.getKeyValue(data['financial_summary'], 'investment_conclusion')
      });

      setEarningCallScores({
        'code': Utils.getKeyValue(data['earning_call'], 'code'),
        'published_at': Utils.getKeyValue(data['earning_call'], 'published_at'),
        'financial_highlights': Utils.getKeyValue(data['earning_call'], 'financial_highlights'),
        'revenue_and_earnings_highlights': Utils.getKeyValue(data['earning_call'], 'revenue_and_earnings_highlights'),
        'financial_performance_score': Utils.getKeyValue(data['earning_call'], 'financial_performance_score'),
        'next_quarter_guidance': Utils.getKeyValue(data['earning_call'], 'next_quarter_guidance'),
        'next_year_guidance': Utils.getKeyValue(data['earning_call'], 'next_year_guidance'),
        'guidance_vs_expectations': Utils.getKeyValue(data['earning_call'], 'guidance_vs_expectations'),
        'guidance_and_expectations_score': Utils.getKeyValue(data['earning_call'], 'guidance_and_expectations_score'),
        'competitive_positioning': Utils.getKeyValue(data['earning_call'], 'competitive_positioning'),
        'new_products_and_markets': Utils.getKeyValue(data['earning_call'], 'new_products_and_markets'),
        'disruptive_technologies': Utils.getKeyValue(data['earning_call'], 'disruptive_technologies'),
        'industry_trends_and_challenges': Utils.getKeyValue(data['earning_call'], 'industry_trends_and_challenges'),
        'competitive_strategy_score': Utils.getKeyValue(data['earning_call'], 'competitive_strategy_score'),
        'cost_management_and_efficiency': Utils.getKeyValue(data['earning_call'], 'cost_management_and_efficiency'),
        'ongoing_investments_and_restructuring': Utils.getKeyValue(data['earning_call'], 'ongoing_investments_and_restructuring'),
        'operational_efficiency_score': Utils.getKeyValue(data['earning_call'], 'operational_efficiency_score'),
        'supply_chain_and_inflation_impact': Utils.getKeyValue(data['earning_call'], 'supply_chain_and_inflation_impact'),
        'macroeconomic_factors_impact': Utils.getKeyValue(data['earning_call'], 'macroeconomic_factors_impact'),
        'external_factors_score': Utils.getKeyValue(data['earning_call'], 'external_factors_score'),
        'management_perception': Utils.getKeyValue(data['earning_call'], 'management_perception'),
        'management_response': Utils.getKeyValue(data['earning_call'], 'management_response'),
        'management_score': Utils.getKeyValue(data['earning_call'], 'management_score'),
        'investment_opportunity_score': Utils.getKeyValue(data['earning_call'], 'investment_opportunity_score'),
        'outlook_sentiment_score': Utils.getKeyValue(data['earning_call'], 'outlook_sentiment_score'),
        'conclusion': Utils.getKeyValue(data['earning_call'], 'conclusion'),
      });

      setIncomeScores({
        'published_at': Utils.getKeyValue(data['income'], 'published_at'),
        'periods': JSON.parse(Utils.getKeyValue(data['income'], 'periods').replaceAll("'", '"')),
        'data_code': Utils.getKeyValue(data['income'], 'data_code'),
        'is_quarter': Utils.getKeyValue(data['income'], 'is_quarter'),
        'revenue_growth_analysis': Utils.getKeyValue(data['income'], 'revenue_growth_analysis'),
        'revenue_growth_outlook_score': Utils.getKeyValue(data['income'], 'revenue_growth_outlook_score'),
        'gross_profit_margin_analysis': Utils.getKeyValue(data['income'], 'gross_profit_margin_analysis'),
        'gross_profit_margin_outlook_score': Utils.getKeyValue(data['income'], 'gross_profit_margin_outlook_score'),
        'operating_expenses_analysis': Utils.getKeyValue(data['income'], 'operating_expenses_analysis'),
        'operating_expenses_outlook_score': Utils.getKeyValue(data['income'], 'operating_expenses_outlook_score'),
        'operating_income_analysis': Utils.getKeyValue(data['income'], 'operating_income_analysis'),
        'operating_income_outlook_score': Utils.getKeyValue(data['income'], 'operating_income_outlook_score'),
        'non_operating_income_analysis': Utils.getKeyValue(data['income'], 'non_operating_income_analysis'),
        'non_operating_income_outlook_score': Utils.getKeyValue(data['income'], 'non_operating_income_outlook_score'),
        'net_income_analysis': Utils.getKeyValue(data['income'], 'net_income_analysis'),
        'net_income_outlook_score': Utils.getKeyValue(data['income'], 'net_income_outlook_score'),
        'earnings_per_share_analysis': Utils.getKeyValue(data['income'], 'earnings_per_share_analysis'),
        'earnings_per_share_outlook_score': Utils.getKeyValue(data['income'], 'earnings_per_share_outlook_score'),
        'potential_red_flags': Utils.getKeyValue(data['income'], 'potential_red_flags'),
        'improvement_opportunities': Utils.getKeyValue(data['income'], 'improvement_opportunities'),
        'earnings_forecast': Utils.getKeyValue(data['income'], 'earnings_forecast'),
        'capital_allocation_decisions': Utils.getKeyValue(data['income'], 'capital_allocation_decisions'),
        'overall_financial_health_outlook_score': Utils.getKeyValue(data['income'], 'overall_financial_health_outlook_score'),
        'conclusion': Utils.getKeyValue(data['income'], 'conclusion'),
      });

      setBalanceSheetScores({
        'published_at': Utils.getKeyValue(data['balance_sheet'], 'published_at'),
        'periods': JSON.parse(Utils.getKeyValue(data['income'], 'periods').replaceAll("'", '"')),
        'data_code': Utils.getKeyValue(data['balance_sheet'], 'data_code'),
        'is_quarter': Utils.getKeyValue(data['balance_sheet'], 'is_quarter'),
        'current_assets_analysis': Utils.getKeyValue(data['balance_sheet'], 'current_assets_analysis'),
        'non_current_assets_analysis': Utils.getKeyValue(data['balance_sheet'], 'non_current_assets_analysis'),
        'intangible_assets_analysis': Utils.getKeyValue(data['balance_sheet'], 'intangible_assets_analysis'),
        'assets_outlook_score': Utils.getKeyValue(data['balance_sheet'], 'assets_outlook_score'),
        'current_liabilities_analysis': Utils.getKeyValue(data['balance_sheet'], 'current_liabilities_analysis'),
        'long_term_liabilities_analysis': Utils.getKeyValue(data['balance_sheet'], 'long_term_liabilities_analysis'),
        'liabilities_outlook_score': Utils.getKeyValue(data['balance_sheet'], 'liabilities_outlook_score'),
        'shareholders_equity_analysis': Utils.getKeyValue(data['balance_sheet'], 'shareholders_equity_analysis'),
        'shareholders_equity_outlook_score': Utils.getKeyValue(data['balance_sheet'], 'shareholders_equity_outlook_score'),
        'liquidity_ratios_analysis': Utils.getKeyValue(data['balance_sheet'], 'liquidity_ratios_analysis'),
        'debt_to_assets_ratio_analysis': Utils.getKeyValue(data['balance_sheet'], 'debt_to_assets_ratio_analysis'),
        'debt_to_equity_analysis': Utils.getKeyValue(data['balance_sheet'], 'debt_to_equity_analysis'),
        'liquidity_and_solvency_outlook_score': Utils.getKeyValue(data['balance_sheet'], 'liquidity_and_solvency_outlook_score'),
        'working_capital_management_analysis': Utils.getKeyValue(data['balance_sheet'], 'working_capital_management_analysis'),
        'working_capital_management_outlook_score': Utils.getKeyValue(data['balance_sheet'], 'working_capital_management_outlook_score'),
        'off_balance_sheet_items_analysis': Utils.getKeyValue(data['balance_sheet'], 'off_balance_sheet_items_analysis'),
        'potential_red_flags': Utils.getKeyValue(data['balance_sheet'], 'potential_red_flags'),
        'asset_utilization_and_liability_minimization': Utils.getKeyValue(data['balance_sheet'], 'asset_utilization_and_liability_minimization'),
        'capital_structure_optimization': Utils.getKeyValue(data['balance_sheet'], 'capital_structure_optimization'),
        'debt_and_equity_tradeoff_optimization': Utils.getKeyValue(data['balance_sheet'], 'debt_and_equity_tradeoff_optimization'),
        'overall_balance_sheet_health_outlook_score': Utils.getKeyValue(data['balance_sheet'], 'overall_balance_sheet_health_outlook_score'),
        'conclusion': Utils.getKeyValue(data['balance_sheet'], 'conclusion'),
      });

      setCashflowScores({
        'published_at': Utils.getKeyValue(data['cashflow'], 'published_at'),
        'periods': JSON.parse(Utils.getKeyValue(data['income'], 'periods').replaceAll("'", '"')),
        'data_code': Utils.getKeyValue(data['cashflow'], 'data_code'),
        'is_quarter': Utils.getKeyValue(data['cashflow'], 'is_quarter'),
        'operating_cashflow_analysis': Utils.getKeyValue(data['cashflow'], 'operating_cashflow_analysis'),
        'working_capital_changes_analysis': Utils.getKeyValue(data['cashflow'], 'working_capital_changes_analysis'),
        'operating_cashflow_outlook_score': Utils.getKeyValue(data['cashflow'], 'operating_cashflow_outlook_score'),
        'capital_expenditures_analysis': Utils.getKeyValue(data['cashflow'], 'capital_expenditures_analysis'),
        'ma_activity_analysis': Utils.getKeyValue(data['cashflow'], 'ma_activity_analysis'),
        'investing_cashflow_outlook_score': Utils.getKeyValue(data['cashflow'], 'investing_cashflow_outlook_score'),
        'debt_repayment_analysis': Utils.getKeyValue(data['cashflow'], 'debt_repayment_analysis'),
        'dividend_payment_analysis': Utils.getKeyValue(data['cashflow'], 'dividend_payment_analysis'),
        'share_repurchase_analysis': Utils.getKeyValue(data['cashflow'], 'share_repurchase_analysis'),
        'capital_markets_access_analysis': Utils.getKeyValue(data['cashflow'], 'capital_markets_access_analysis'),
        'financing_cashflow_outlook_score': Utils.getKeyValue(data['cashflow'], 'financing_cashflow_outlook_score'),
        'free_cashflow_analysis': Utils.getKeyValue(data['cashflow'], 'free_cashflow_analysis'),
        'free_cashflow_outlook_score': Utils.getKeyValue(data['cashflow'], 'free_cashflow_outlook_score'),
        'cash_and_equivalents_analysis': Utils.getKeyValue(data['cashflow'], 'cash_and_equivalents_analysis'),
        'cash_and_equivalents_outlook_score': Utils.getKeyValue(data['cashflow'], 'cash_and_equivalents_outlook_score'),
        'non_cash_items_analysis': Utils.getKeyValue(data['cashflow'], 'non_cash_items_analysis'),
        'non_cash_items_outlook_score': Utils.getKeyValue(data['cashflow'], 'non_cash_items_outlook_score'),
        'cash_conversion_cycle_analysis': Utils.getKeyValue(data['cashflow'], 'cash_conversion_cycle_analysis'),
        'cash_conversion_cycle_outlook_score': Utils.getKeyValue(data['cashflow'], 'cash_conversion_cycle_outlook_score'),
        'potential_red_flags': Utils.getKeyValue(data['cashflow'], 'potential_red_flags'),
        'significant_cashflow_changes_or_unusual_patterns': Utils.getKeyValue(data['cashflow'], 'significant_cashflow_changes_or_unusual_patterns'),
        'overall_cashflow_health_outlook_score': Utils.getKeyValue(data['cashflow'], 'overall_cashflow_health_outlook_score'),
        'conclusion': Utils.getKeyValue(data['cashflow'], 'conclusion'),
      });

      setLoadingCompanyScores(false);
    };

    if (resultAPICallMap.has(api_cmd)) {
      handleCompanyFetched(resultAPICallMap.get(api_cmd));
    } else {
      fetchData();
    }
  }, [ accessInfo, dispatch, ticker, loadingCompanyScores ]);

  const renderOverviewPage = () => {
    if (errorMsg.length === 0) {
      return (
        <Grid container spacing={3} justifyContent="space-evenly">
          <Grid item xs={12}>
            <ScoreFinance
              title="Financial Analysis"
              analysis={financialScores}
              finance={companyFinance}
              showProgress={loadingCompanyScores}
            />
          </Grid>
          <Grid item xs={12}>
            <ScoreInvestment
              title="Evaluating Investment Opportunities"
              data={investmentScores}
              showProgress={loadingCompanyScores}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4.5}>
            <ScoreIndustry
              title="Industry Outlook"
              data={industryScores}
              industry_name={Utils.getKeyValue(companyOverview, 'industry')}
              showProgress={loadingCompanyScores}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3.75}>
            <ScorePeer
              title="Highest Performant Peers"
              peers={bestPeers}
              loadingCompanies={loadingCompanyScores}
              subTitle={Utils.getKeyValue(companyOverview, 'industry')}
              hideName={Constants.RANKING_HIDE_NAME}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3.75}>
            <ScorePeer
              title="Lowest Performant Peers"
              peers={worstPeers}
              loadingCompanies={loadingCompanyScores}
              subTitle={Utils.getKeyValue(companyOverview, 'industry')}
              hideName={Constants.RANKING_HIDE_NAME}
            />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container spacing={3} justifyContent="space-evenly">
          <Grid item xs={12}>
            <ScoreFinance
              title="Financial Analysis"
              analysis={financialScores}
              finance={companyFinance}
              showProgress={loadingCompanyScores}
            />
          </Grid>
        </Grid>
      )
    }
  }

  const renderStatementPage = () => {
    return (
      <Grid container spacing={3} justifyContent="space-evenly">
        <Grid item xs={12}>
          <ScoreIncome
            title="Income Statement"
            ticker={ticker}
            data={incomeScores}
            showProgress={loadingCompanyScores}
          />
        </Grid>
        <Grid item xs={12}>
          <ScoreBalanceSheet
            title="Balance Sheet Statement"
            ticker={ticker}
            data={balanceSheetScores}
            showProgress={loadingCompanyScores}
          />
        </Grid>
        <Grid item xs={12}>
          <ScoreCashFlow
            title="Cash Flow Statement"
            ticker={ticker}
            data={cashflowScores}
            showProgress={loadingCompanyScores}
          />
        </Grid>
      </Grid>
    )
  }

  const renderProfilePage = () => {
    return (
      <Grid container spacing={3} justifyContent="space-evenly">
        <Grid item xs={12} md={6} xl={4}>
          <ProfileOverview
            title="Company Profile"
            overview={companyOverview}
            history={historyAndMilestones}
            showProgress={loadingCompany}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <ProfileOperations
            title="Business Ecosystem"
            operations={operationsAndCapabilities}
            competition={competitiveLandscape}
            showProgress={loadingCompany}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <ProfileCompetition
            title="Strategic Positioning & Innovation Capabilities"
            competition={competitiveLandscape}
            innovation={innovationAndTechnology}
            showProgress={loadingCompany}
          />
        </Grid>
      </Grid>
    )
  }

  const renderEarningCallPage = () => {
    return (
      <Grid container spacing={3} justifyContent="space-evenly">
        <Grid item xs={12}>
          <ScoreEarningCall
            title={"Earning Call"}
            data={earningCallScores}
            showProgress={loadingCompanyScores}
          />
        </Grid>
      </Grid>
    )
  }

  const renderSelectedPage = () => {
    if (selectedTab === 0) {
      return renderOverviewPage();
    } else if (selectedTab === 1) {
      return renderProfilePage();
    } else if (selectedTab === 2) {
      return renderStatementPage();
    } else if (selectedTab === 3) {
      return renderEarningCallPage();
    }
    return null;
  };

  return (
    <DashboardLayout bgColor="#FF6347" >
      <DashboardNavbar />
      <Header
        logo={logo}
        name={Utils.getKeyValue(companyOverview, 'short_name')}
        headquartersLocation={Utils.getKeyValue(companyOverview, 'headquarters_location')}
        tabLabels={["Summary", "Profile", "Financials"]} //, "Earning Call"]}
        tabIconNames={[<SummarizeIcon />, <BusinessIcon />, <AccountBalanceIcon/>]}
        selectedTab={selectedTab}
        showProgress={showProgress}
        handleTabChanged={handleTabChanged}
      />
      <ArgonBox mt={5} mb={3}>
        {renderSelectedPage()}
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Company;
