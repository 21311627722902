// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// SPAI components
import SpaiMultiTypographyWithLabel from "components/SpaiMultiTypographyWithLabel";

function ProfileOperations({title, competition, operations, showProgress}) {
  const [competitors, setCompetitors] = useState([]);

  useEffect(() => {
    if (competition === undefined || Object.keys(competition).length == 0) {
      return;
    }

    try {
      const content = JSON.parse(competition['competitors'].replaceAll("'", '"'));
      setCompetitors(content.map((item) => {
        return item.name + ' (' + item.stock_ticker + ')';
      }));
    } catch {
      console.error(competition['competitors']);
    }
  }, [ competition ]);

  return (
    <Card>
      <Stack pt={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          {showProgress ? <CircularProgress size={26} color='info' /> : null}
        </ArgonBox>
      </Stack>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2} pb={2} px={2}
      >
        <Grid item sm container rowSpacing={2}>
          <Grid item xs={12} container direction="column">
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Geographic Presence'
                contents={[
                  operations.geographic_presence,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Operations Capabilities'
                contents={[
                  operations.operations_capabilities,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Business Segments'
                contents={[
                  operations.business_segments,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Suppliers & Partners'
                contents={[
                  operations.suppliers_and_partners,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Outsourcing Alliances'
                contents={[
                  operations.outsourcing_alliances,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Competitors'
                contents={competitors}
                showContent={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

ProfileOperations.defaultProps = {
  showProgress: false,
};

ProfileOperations.propTypes = {
  title: PropTypes.string.isRequired,
  competition: PropTypes.object.isRequired,
  operations: PropTypes.object.isRequired,
  showProgress: PropTypes.bool,
};

export default ProfileOperations;
