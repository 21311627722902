import React, { useEffect } from "react";

import YouTube from 'react-youtube';

// @mui material components
import Card from "@mui/material/Card";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Argon Dashboard 2 MUI contexts
import { useArgonController, setCurrentSection } from "context";

// Page components
import PricingCard from "layouts/home/components/PricingCard";
import SpaiMultiTypographyWithLabel from "components/SpaiMultiTypographyWithLabel";
import SwiperIndustry from "layouts/home/components/SwiperIndustry";
import SwiperRanking from "layouts/home/components/SwiperRanking";
import SwiperScore from "layouts/home/components/SwiperScore";
import SwiperIntroduction from "layouts/home/components/SwiperIntroduction";

// CSS data
import "assets/css/image.css";

class YoutubeIntro extends React.Component {
  render() {
    const opts = {
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

    return <YouTube videoId="VHYvD27-_vs" opts={opts} onReady={this._onReady} />;
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}


function Home() {
  const [controller, dispatch] = useArgonController();
  const { currentSection } = controller;
  const specs = [
    { label: "Unlimited Daily Request", includes: false },
    { label: "Company Database", includes: false },
    { label: "Financial Analysis", includes: false },
    { label: "Investment Analysis", includes: false },
    { label: "Statements Analysis", includes: false },
    { label: "Earnings Call Analysis", includes: false },
    { label: "Company Ranking", includes: false },
    { label: "Industry Ranking", includes: false },
    { label: "Customizable Analysis", includes: false },
    { label: "Integration via APIs", includes: false },
  ];

  function modifySpecs(features) {
    const clonedSpecs = specs.map(item => ({ ...item }));
    if (features.length !== specs.length) {
      console.log('Size mismatched');
      return clonedSpecs;
    }
    for (var i = 0; i < features.length; i++) {
      clonedSpecs[i].includes = features[i];
    }
    return clonedSpecs;
  }

  useEffect(() => {
    if (currentSection !== '/company') {
      setCurrentSection(dispatch, '/company');
    }
  }, [currentSection, dispatch]);

  return (
    <DashboardLayout bgColor="#FFA500">
      <DashboardNavbar />
      <ArgonBox pt={3} pb={10}>
        <Grid container spacing={4} justifyContent="space-evenly">
          <Grid item xs={12} md={6} key='swiper'>
            <SwiperIntroduction title="Why Invests4U?" />
          </Grid>
          <Grid item xs={12} md={6} key='swiper' zIndex={10}>
            <Card sx={{ backgroundColor: '#000000' }}>
              <YoutubeIntro />
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>

      <Divider />

      <ArgonBox py={5} px={3} pb={10}>
        <ArgonBox display="flex" justifyContent="center" alignItems="center" pt={1} pb={5}>
          <ArgonTypography variant="h3">Features Summary</ArgonTypography>
        </ArgonBox>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item xs={12} md={10}>
            <img
              class='img-display'
              srcSet={`https://live.staticflickr.com/65535/53863737826_c43d796569_k.jpg?w=240&fit=crop&auto=format&dpr=2 2x`}
              src={`https://live.staticflickr.com/65535/53863737826_c43d796569_k.jpg?w=240&fit=crop&auto=format`}
              alt='summary'
              loading="lazy"
            />
          </Grid>
        </Grid>
      </ArgonBox>

      <Divider />

      <ArgonBox py={5} px={3} pb={10}>
        <ArgonBox display="flex" justifyContent="center" alignItems="center" pt={1} pb={5}>
          <ArgonTypography variant="h3">Streamlined Financial Analysis with AI</ArgonTypography>
        </ArgonBox>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item sm={12} md={6} xl={5}>
            <ArgonBox>
              <SpaiMultiTypographyWithLabel
                label="Our AI-powered agent system meticulously analyzes the financials of publicly 
                  listed companies, delivering investors a quick and clear understanding of the 
                  company's situation. No more getting lost in lengthy statements."
                contents={[
                  "Comprehensive Coverage: Our system covers all critical financial metrics, ensuring no key data is overlooked.",
                  "Large Company Coverage: We analyze a wide range of companies, from small cap to multi-trillion dollar firms.",
                  "Concise Scoring: Complex data is distilled into easy-to-understand scores, highlighting the most important insights.",
                  "Instant Insights: Receive immediate, actionable insights to make informed decisions with confidence.",
                  "Time-Saving Efficiency: Save valuable time compared to manual reviews, allowing you to focus on strategic planning."
                ]}
                showContent={true}
                labelVariant="h6"
                labelFontWeight="regular"
                labelColor="dark"
                contentVariant="h6"
                contentColor="dark"
                contentFontWeight="regular"
                contentIndent={1}
              />
            </ArgonBox>
          </Grid>
          <Grid item sm={12} md={4} xl={4}>
            <SwiperScore />
          </Grid>
        </Grid>
      </ArgonBox>

      <Divider />

      <ArgonBox py={5} px={3} pb={10}>
        <ArgonBox display="flex" justifyContent="center" alignItems="center" pt={1} pb={5}>
          <ArgonTypography variant="h3">Company Leaderboard</ArgonTypography>
        </ArgonBox>
        <Grid container spacing={5} justifyContent="space-evenly">
          <Grid item sm={12} md={10}>
            <ArgonTypography variant="h5" fontWeight="regular" color="dark">
              Discover the best investment opportunities with our Company Leaderboard. By leveraging 
              comprehensive company analysis and a flexible ranking system, we help you quickly 
              identify the most promising investments tailored to your specific strategies.
            </ArgonTypography>
          </Grid>
          <Grid item xs={12} key='swiper'>
            <SwiperRanking />
          </Grid>
        </Grid>
      </ArgonBox>

      <Divider />

      <ArgonBox py={5} px={3} pb={10}>
        <ArgonBox display="flex" justifyContent="center" alignItems="center" pt={1} pb={5}>
          <ArgonTypography variant="h3">Industry Benchmarking</ArgonTypography>
        </ArgonBox>
        <Grid container spacing={1} justifyContent="space-evenly">
          <Grid item sm={12} md={6} xl={5}>
            <ArgonBox>
              <SpaiMultiTypographyWithLabel
                label="Our system analyzes industries using key financial metrics, offering 
                  investors ranked insights to swiftly identify trends and promising opportunities."
                contents={[
                  "Comprehensive Industry Analysis: Gain in-depth insights into 144 different industries, covering all critical financial metrics.",
                  "Top Performer Rankings: Our ranking system highlights the best-performing industries, making it easy to spot leaders.",
                  "Emerging Insights: Discover new and emerging trends within various industries, giving you an edge in the market.",
                  "Efficient Investment Research: Streamline your investment research process, saving time and effort while uncovering valuable opportunities."
                ]}
                showContent={true}
                labelVariant="h6"
                labelFontWeight="regular"
                labelColor="dark"
                contentVariant="h6"
                contentColor="dark"
                contentFontWeight="regular"
                contentIndent={1}
              />
            </ArgonBox>
          </Grid>
          <Grid item sm={12} md={4} xl={4}>
            <SwiperIndustry />
          </Grid>
        </Grid>
      </ArgonBox>

      <Divider />

      <ArgonBox py={5}>
        <ArgonBox display="flex" justifyContent="center" alignItems="center" pt={1} pb={5}>
          <ArgonTypography variant="h3">Our Pricing</ArgonTypography>
        </ArgonBox>
        <Grid container spacing={1} justifyContent="space-evenly">
          <Grid item xs={10} sm={8} md={5} lg={4} key='basic'>
            <PricingCard
              title="Individual"
              // price={{ currency: "", value: "$99.99+" }}
              price={{ currency: "", value: "Contact Us" }}
              // subPrice="per user per month"
              specifications={modifySpecs([
                false, true, true, true, true, false, false, false, false, false
              ])}
              dialogMessage="I'd like to sign up for the Individual subscription plan."
              action={{
                label: "Join us",
                color: "info",
                showArrow: true
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={7} md={5} lg={3} key='pro'>
            <PricingCard
              title="PRO"
              price={{ currency: "", value: "$199.99+" }}
              subPrice="per user per month"
              specifications={modifySpecs([
                true, true, true, true, true, true, true, false, false
              ])}
              dialogMessage="I'd like to sign up for the Pro subscription plan."
              action={{
                label: "Join us",
                color: "info",
                showArrow: true
              }}
            />
          </Grid> */}
          <Grid item xs={10} sm={8} md={5} lg={4} key='expert'>
            <PricingCard
              title="Enterprise"
              price={{ currency: "", value: "Contact Us" }}
              // subPrice="&nbsp;"
              specifications={modifySpecs([
                true, true, true, true, true, true, true, true, true, true
              ])}
              dialogMessage="I'd like to sign up for the Enterprise subscription plan."
              action={{
                label: "Join us",
                color: "info",
                showArrow: true
              }}
            />
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Home;