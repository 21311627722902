// react-routers components
import React from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

// SPAI components
import SpaiLinearProgress from "components/SpaiLinearProgressWithLabel";

// Shared config & utils
import * as Utils from "utils/utils";

function SpaiLinearProgressWithLabelAndMultiContent({value, title, contents, showContent}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const color = value ? Utils.getScoreLabelAndColor(value)[0] : "info";

  return (
    <div>
      <SpaiLinearProgress
        value={value}
        color={color}
        variant="gradient"
        label={title}
      />
      {showContent ? (
        <ArgonBox p={1} borderRadius="md"
          sx={({ palette: { grey, background } }) => ({
            backgroundColor: darkMode ? background.default : grey[100],
          })}
        >
          <Stack>
            {contents.map((content, index) => {
              content = content.replaceAll('[]', '');
              if (content === undefined || content.length === 0) {
                return null;
              }
              return (
                <ArgonTypography key={index} variant="caption" color="dark" display="block" pb={0.5}>
                  &#x27A3; {content}
                </ArgonTypography>
              )}
            )}
          </Stack>
        </ArgonBox>
      ) : null}
    </div>
  );
}

SpaiLinearProgressWithLabelAndMultiContent.defaultProps = {
  content: "",
  showContent: false,
};

// Typechecking props for the ReportListInfo
SpaiLinearProgressWithLabelAndMultiContent.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  contents: PropTypes.array.isRequired,
  showContent: PropTypes.bool,
};

export default SpaiLinearProgressWithLabelAndMultiContent;