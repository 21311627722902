import * as React from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

// Custom styles for ArgonTypography
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

const SpaiSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

function SpaiThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

SpaiThumbComponent.propTypes = {
  children: PropTypes.node,
};

export default function SpaiSliderBarWithLabel({min, max, value, label, setValue}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <div>
      <ArgonTypography variant="caption" fontWeight="bold" color="text"
        pb={2} pt={1} px={1} borderRadius={1.5}
        sx={({ palette: { grey, background } }) => ({
          backgroundColor: darkMode ? background.default : grey[100],
        })}
      >
        {label}
      </ArgonTypography>
      <ArgonBox pt={1} px={3} borderRadius="md"
        sx={({ palette: { grey, background } }) => ({
          backgroundColor: darkMode ? background.default : grey[100],
        })}
      >
        <SpaiSlider
          slots={{ thumb: SpaiThumbComponent }}
          defaultValue={value}
          step={1}
          min={min}
          max={max}
          onChange={(e, newValue) => setValue(newValue)}
          valueLabelDisplay="auto"
          marks
        />
      </ArgonBox>
    </div>
  );
}

SpaiSliderBarWithLabel.defaultProps = {
  min: 0,
  max: 10,
  value: 5,
  label: "",
};

SpaiSliderBarWithLabel.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};