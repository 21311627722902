// react-routers components
import React, { useState } from "react";

// Lottie animation
import Lottie from 'react-lottie';

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

// Shared config & utils
import * as Constants from "config/Constants"

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

// SPAI components
import SpaiLinearProgressWithLabelAndContent from "components/SpaiLinearProgressWithLabelAndContent";
import useWindowDimensions from "utils/useWindowDimensions";

// Images
import investLottieData from "assets/images/invest.json";

function ScoreInvestment({title, data, showProgress}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [showAnalysis, setShowAnalysis] = useState(false);
  const { _, width } = useWindowDimensions();

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: investLottieData,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }};

  let overviewStackSize = Constants.CIRCULAR_SCORE_SIZE;
  if (width < 700) {
    overviewStackSize = width * 0.8;
  } else if (showAnalysis) {
    overviewStackSize = Constants.CIRCULAR_SCORE_WITH_LABEL_SIZE;
  }

  const isValid = (Object.keys(data).length > 0);
  if (!isValid) {
    return (
      <Card>
        <Stack pt={2} px={2} direction="row">
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {title}
            </ArgonTypography>
          </ArgonBox>
        </Stack>
        <ArgonBox px={2} py={1}>
          <ArgonTypography variant="button" fontWeight='regular' color="text">
            {Constants.NO_AUTHORIZED_MESSAGE}
          </ArgonTypography>
        </ArgonBox>
      </Card>
    )
  }

  return (
    <Card>
      <Stack pt={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          {showProgress ? <CircularProgress size={26} color='info' /> : (
            <Tooltip title="Show Details">
              <ArgonButton
                color="info"
                size="small" 
                circular 
                iconOnly 
                onClick={() => {
                  setShowAnalysis(!showAnalysis);
                }}
              >
                {showAnalysis ? <Icon>arrow_upward</Icon> : <Icon>arrow_downward</Icon>}
              </ArgonButton>
            </Tooltip>
          )}
        </ArgonBox>
      </Stack>
      {/* {showAnalysis ? (
        <ArgonBox px={2}>
          <ArgonBox p={1} borderRadius="md"
            sx={({ palette: { grey, background } }) => ({
              backgroundColor: darkMode ? background.default : grey[100],
            })}
          >
            <ArgonTypography variant="caption" color="text" textGradient>
              {data.conclusion}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      ) : null} */}
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2} pb={2} px={2}
      >
        <Grid container columnSpacing={2}>
          <Grid item sm={12} lg={3} container display='flex' alignItems='center'>
            <ArgonBox p={1}>
              <Lottie 
                options={lottieOptions}
                width={showAnalysis ? "35%" : "15%"}
              />
              <ArgonBox p={1} borderRadius="md"
                sx={({ palette: { grey, background } }) => ({
                  backgroundColor: darkMode ? background.default : grey[100],
                })}
              >
                <ArgonTypography variant="caption" color="dark" display="block">
                  &#x27A3;  {data.investment_conclusion}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </Grid>
          <Grid item sm container columnSpacing={4}>
            <Grid item sm={12} lg={5.5} container direction="column">
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.investment_opportunity_score_for_conservative_investor*10}
                  title="Conservative"
                  content={data.investment_opportunity_for_conservative_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.investment_opportunity_score_for_balanced_investor*10}
                  title='Balanced'
                  content={data.investment_opportunity_for_balanced_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.investment_opportunity_score_for_growth_investor*10}
                  title='Growth-Oriented'
                  content={data.investment_opportunity_for_growth_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
            </Grid>
            <Grid item sm={12} lg={5.5} container direction="column">
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.investment_opportunity_score_for_speculative_investor*10}
                  title='Speculative'
                  content={data.investment_opportunity_for_speculative_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.investment_opportunity_score_for_income_investor*10}
                  title='Income-Focused'
                  content={data.investment_opportunity_for_income_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent
                  value={data.investment_opportunity_score_for_value_investor*10}
                  title='Value-Driven'
                  content={data.investment_opportunity_for_value_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

ScoreInvestment.defaultProps = {
  showProgress: false,
};

ScoreInvestment.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  showProgress: PropTypes.bool,
};

export default ScoreInvestment;
