import { useState } from "react";

// @mui material components
import Link from "@mui/material/Link";

// Lottie animation
import Lottie from 'react-lottie';

// @mui icons
import YouTubeIcon from '@mui/icons-material/YouTube';

// Argon Dashboard 2 MUI components
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ContactUsDialog from "components/ContactUsDialog";

// Argon Dashboard 2 MUI context
import { useArgonController } from "context";

// Images
import questionData from "assets/images/question.json";

function SidenavFooter() {
  const [controller] = useArgonController();
  const { miniSidenav, darkSidenav } = controller;
  const [openContactUs, setOpenContactUs] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: questionData,
    rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }};

  const handleOpenContactUs = () => {
    setOpenContactUs(true);
  };

  return (
    <ArgonBox opacity={miniSidenav ? 0 : 1} sx={{ transition: "opacity 200ms linear" }}>
      <ArgonBox position="relative" textAlign="center">
        <Lottie 
            options={defaultOptions}
            // height={400}
            width="40%"
        />
        <ArgonBox
          width="100%"
          pb={2}
          px={2}
          color={darkSidenav ? "white" : "dark"}
          textAlign="center"
          lineHeight={0}
        >
          <ArgonTypography color="inherit" variant="h6">
            Have questions?
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonButton
          component={Link}
          color="dark"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          onClick={() => handleOpenContactUs()}
        >
          Contact Us
        </ArgonButton>
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonButton
          component={Link}
          href="https://www.youtube.com/playlist?list=PLDaGoiQQwE6-et0nDRf0tDg7WZx7RIWWj"
          target="_blank"
          color="warning"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
        >
          <YouTubeIcon />
          &nbsp; View Tutorial
        </ArgonButton>
      </ArgonBox>
      <ContactUsDialog open={openContactUs} setOpen={setOpenContactUs} />
    </ArgonBox>
  );
}

export default SidenavFooter;
