// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import MenuItem from '@mui/material/MenuItem';
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI context
import { useArgonController } from "context";

// Argon Dashboard 2 MUI examples
import Table from "examples/Tables/Table";

// SPAI components
import SpaiLinearProgressWithLabelAndContent from "components/SpaiLinearProgressWithLabelAndContent";

function get_relevant_indicators(data) {
  if (data["relevant_indicators"]) {
    const relevant_indicators_fixed = data["relevant_indicators"].replaceAll("'", '"');
    const objs = JSON.parse(relevant_indicators_fixed);
    return objs.map((item) => {
      return {
        "indicator": item.name,
        "value": item.value,
        "growth": item.growth
      };
    });
  }
  return [];
}

function ScoreIndustry({ title, data, industry_name, showProgress,
  selectedIndustry, changeIndustryAction }) {
  const [controller,] = useArgonController();
  const { metaStats, glossary } = controller;
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [captionText, setCaptionText] = useState("");

  let indicators = get_relevant_indicators(data);

  useEffect(() => {
    setColumns([
      { name: "indicator", align: "left" },
      { name: "value", align: "center" },
      { name: "change", align: "center" },
    ]);

    setRows(
      indicators.map((item) => {
        return renderRow(item.indicator, item.value, item.growth);
      })
    );
  }, [data, indicators]);

  function renderRow(indicator, value, growth) {
    const need_pct_sign = indicator.includes('Rate') ? true : false;
    return {
      indicator: (
        <ArgonButton
          variant="text" color="secondary" size="small"
          onClick={() => setCaptionText(Utils.getKeyValue(glossary, indicator))}
        >
          {indicator}
        </ArgonButton>
      ),
      value: (
        <ArgonTypography
          variant="caption"
          color={need_pct_sign ? Utils.getGrowthPctColor(value) : "text"}
          fontWeight="medium"
        >
          {Utils.formatNumber(value, '', need_pct_sign ? '%' : '')}
        </ArgonTypography>
      ),
      change: (
        <ArgonTypography
          variant="caption"
          color={Utils.getGrowthPctColor(growth)}
          fontWeight="medium"
        >
          {Utils.formatNumber(growth, growth > 0 ? '+' : '', '%')}
        </ArgonTypography>
      ),
    }
  }

  const isValid = (Object.keys(data).length > 0);
  if (!isValid) {
    return (
      <Card>
        <Stack pt={2} px={2} direction="row">
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {title}
            </ArgonTypography>
          </ArgonBox>
        </Stack>
        <ArgonBox px={2} py={1}>
          <ArgonTypography variant="button" fontWeight='regular' color="text">
            {Constants.NO_AUTHORIZED_MESSAGE}
          </ArgonTypography>
        </ArgonBox>
      </Card>
    )
  }

  return (
    <Card>
      <Stack pt={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml: 'auto', mr: '5px' }}>
          {showProgress ? <CircularProgress size={26} color='info' /> : (
            <Tooltip title="Show Details">
              <ArgonButton
                color="info"
                size="small"
                circular
                iconOnly
                onClick={() => {
                  setShowAnalysis(!showAnalysis);
                }}
              >
                {showAnalysis ? <Icon>arrow_upward</Icon> : <Icon>arrow_downward</Icon>}
              </ArgonButton>
            </Tooltip>
          )}
        </ArgonBox>
      </Stack>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2} pb={2} px={2}
      >
        <Grid container rowSpacing={2}>
          {selectedIndustry ? (
            <Grid item xs={12} display='flex' justifyContent='center'>
              <ArgonBox style={{ width: '300px' }}>
                <Select
                  value={selectedIndustry}
                  onChange={(e) => changeIndustryAction(e.target.value)}
                  autoWidth
                  label="Select Industry"
                  sx={{
                    borderColor: '#11cdef',
                  }}
                >
                  {metaStats['industries'].map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {Utils.convertKeyToString(item)}
                      </MenuItem>
                    )
                  })}
                </Select>
              </ArgonBox>
            </Grid>
          ) : null}
          {data.analysis.length > 0 ? (
            <Grid item xs={12}>
              <SpaiLinearProgressWithLabelAndContent
                title={industry_name}
                value={data.outlook_score * 10}
                content={data.analysis}
                showContent={showAnalysis}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ArgonTypography variant="button" fontWeight="bold" color="text">
                {Constants.NO_SECTOR_ANALYSIS_MESSAGE}
              </ArgonTypography>
            </Grid>
          )}
          {data.analysis.length > 0 ? (
            <Grid item xs={12}>
              <ArgonTypography variant="button" fontWeight="bold" color="text">
                Most Impactful Indicators
              </ArgonTypography>
              <ArgonBox pt={1}>
                <Table columns={columns} rows={rows} caption={captionText} />
              </ArgonBox>
            </Grid>

          ) : null }
        </Grid>
      </ArgonBox>
    </Card>
  );
}

ScoreIndustry.defaultProps = {
  showProgress: false,
  showIndustrySelect: false
};

ScoreIndustry.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  industry_name: PropTypes.string.isRequired,
  showProgress: PropTypes.bool,
  selectedIndustry: PropTypes.string,
  changeIndustryAction: PropTypes.func
};

export default ScoreIndustry;
