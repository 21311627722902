// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

// SPAI components
import SpaiCircularScoreWithLabel from "components/SpaiCircularScoreWithLabel";
import SpaiLinearProgressWithLabelAndContent from "components/SpaiLinearProgressWithLabelAndContent";
import SpaiLinearProgressWithLabelAndMultiContent from "components/SpaiLinearProgressWithLabelAndMultiContent";
import SpaiMultiTypographyWithLabel from "components/SpaiMultiTypographyWithLabel";

// Shared config & utils
import * as Constants from "config/Constants";
import useWindowDimensions from "utils/useWindowDimensions";

// Page components
import TableHTMLDialog from "components/TableHTMLDialog";

let resultAPICallMap = new Map();

function ScoreEarningCall({title, data, showProgress}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { _, width } = useWindowDimensions();
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [summary, setSummary] = useState("");

  useEffect(() => {
    if (!loadingSummary || data['code'].length === 0) {
      return;
    };

    let api_cmd = Constants.API_IP_ADDRESS
      + 'get_earning_call_summary?code=' + data['code'];
    const fetchData = async () => {
      fetch(api_cmd)
      .then((response) => response.json())
      .then((data) => {
        handleDataFetched(data);
        resultAPICallMap.set(api_cmd, data);
      })
      .catch((err) => {
        setLoadingSummary(false);
        console.log(api_cmd, err.message);
      });
    };

    const handleDataFetched = (data) => {
      setLoadingSummary(false);
      
      if (typeof data === 'string' || data instanceof String) {
        setSummary(data);
      } else {
        console.error('Invalid HTML', data);
        setSummary("");
      }
    }

    if (resultAPICallMap.has(api_cmd)) {
      handleDataFetched(resultAPICallMap.get(api_cmd));
    } else {
      fetchData();
    }  
  }, [ loadingSummary ]);

  const isValid = (Object.keys(data).length > 0 && data['code'].length > 0);
  if (!isValid) {
    return (
      <Card>
        <Stack pt={2} px={2} direction="row">
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {title}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
            {showProgress ? <CircularProgress size={26} color='info' /> : (
              <Tooltip title="Request Analysis">
                <ArgonButton
                  color="info"
                  size="small" 
                  circular 
                  iconOnly 
                  onClick={() => {
                    // TODO: add mail calling here
                    console.log('Report missing earning call');
                  }}
                >
                  <Icon>email</Icon>
                </ArgonButton>
              </Tooltip>
            )}
          </ArgonBox>
        </Stack>
        <ArgonBox px={3} py={1}>
          <ArgonTypography variant="caption" color="text">
            Not available. If you would like to request this analysis, 
            please submit a request in the top right button of this section.
          </ArgonTypography>
        </ArgonBox>
      </Card>
    )
  }

  let overviewStackSize = Constants.CIRCULAR_SCORE_WITH_LABEL_SIZE;
  if (width < 700) {
    overviewStackSize = width * 0.8;
  }

  return (
    <Card>
      <Stack pt={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title + ": " + data['code']}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          {showProgress ? <CircularProgress size={26} color='info' /> : (
            <Tooltip title="Show Details">
              <ArgonButton
                color="info"
                size="small" 
                circular 
                iconOnly 
                onClick={() => {
                  setShowAnalysis(!showAnalysis);
                }}
              >
                {showAnalysis ? <Icon>arrow_upward</Icon> : <Icon>arrow_downward</Icon>}
              </ArgonButton>
            </Tooltip>
          )}
        </ArgonBox>
      </Stack>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2} pb={2} px={2}
      >
        <Grid container columnSpacing={2}>
          <Grid item alignItems="center" display="flex" justifyContent="center" alignContent="center">
            <Stack width={overviewStackSize}>
              <ArgonBox display="flex" justifyContent="center">
                <SpaiCircularScoreWithLabel
                  title="Sentiment"
                  value={data.outlook_sentiment_score * 10}
                  size={Constants.CIRCULAR_SCORE_SIZE}
                />
              </ArgonBox>
              <ArgonBox pt={1}>
                {showAnalysis ? (
                  <ArgonBox py={1}>
                    <ArgonBox p={1} borderRadius="md"
                      sx={({ palette: { grey, background } }) => ({
                        backgroundColor: darkMode ? background.default : grey[100],
                        "&:hover": {
                          background: grey[200]
                        }
                      })}
                    >
                      <ArgonTypography variant="caption" color="text" textGradient>
                        {data.conclusion}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                ) : null}
              </ArgonBox>
              {summary.length > 0 ? (
                <ArgonBox py={2} display="flex" justifyContent="center">
                  <ArgonButton
                    color="info"
                    size="small" 
                    onClick={() => setOpenBackdrop(true)}
                  >
                    Transcript Summary
                  </ArgonButton>
                </ArgonBox>
              ) : null }
            </Stack>
          </Grid>
          <Grid item sm container columnSpacing={4}>
            <Grid item md={12} lg={5} container direction="column">
              <Grid item xs>
                <SpaiMultiTypographyWithLabel 
                  label="Financial Highlights"
                  contents={[data.financial_highlights]}
                  showContent={true}
                />
              </Grid>
              <Grid item xs>
                <SpaiMultiTypographyWithLabel 
                  label="Revenue & Earning Highlights"
                  contents={[data.revenue_and_earnings_highlights]}
                  showContent={true}
                />
              </Grid>
              <Grid item xs>
                <SpaiMultiTypographyWithLabel 
                  label="Next Quarter Guidance"
                  contents={[data.next_quarter_guidance]}
                  showContent={true}
                />
              </Grid>
              <Grid item xs>
                <SpaiMultiTypographyWithLabel 
                  label="Next Year Guidance"
                  contents={[data.next_year_guidance]}
                  showContent={true}
                />
              </Grid>
            </Grid>
            <Grid item md={12} lg={7} container direction="column">
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.guidance_and_expectations_score*10}
                  title='Guidance vs. Expectations'
                  content={data.guidance_vs_expectations}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndMultiContent
                  value={data.competitive_strategy_score*10}
                  title='Competitive Landscape'
                  contents={[
                    data.competitive_positioning,
                    data.new_products_and_markets,
                    data.disruptive_technologies,
                    data.industry_trends_and_challenges
                  ]}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndMultiContent
                  value={data.operational_efficiency_score*10}
                  title='Operational Efficiency'
                  contents={[
                    data.cost_management_and_efficiency,
                    data.ongoing_investments_and_restructuring,
                  ]}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndMultiContent
                  value={data.management_score*10}
                  title='Management Effectiveness'
                  contents={[
                    data.management_perception,
                    data.management_response,
                  ]}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndMultiContent
                  value={data.external_factors_score*10}
                  title='External Factors'
                  contents={[
                    data.supply_chain_and_inflation_impact,
                    data.macroeconomic_factors_impact,
                  ]}
                  showContent={showAnalysis}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
      <TableHTMLDialog
        open={openBackdrop} 
        setOpen={setOpenBackdrop}
        table_html={summary} 
        title={"Transcript Summary: " + data['code']}
      />
    </Card>
  );
}

ScoreEarningCall.defaultProps = {
  showProgress: false,
};

ScoreEarningCall.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  showProgress: PropTypes.bool,
};

export default ScoreEarningCall;
