// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

// Argon Dashboard 2 MUI examples
import ArgonButton from "components/ArgonButton";
import Table from "examples/Tables/Table";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// import { useArgonController } from "context";

// Spai
import SpaiCircularScoreWithLabel from "components/SpaiCircularScoreWithLabel";

let resultAPICallMap = new Map();

function RankInvestment({ apiKey, hideName }) {
  // const [controller,] = useArgonController();
  // const { accessInfo } = controller;
  const [errorMsg, setErrorMsg] = useState('');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [selectedCriteria, setSelectedCriteria] = useState(0);
  const [page, setPage] = useState(1);
  const numPages = Constants.RANKING_INVESTMENT_TOP_N / Constants.RANKING_TOP_N_PER_PAGE;

  const handlePageChange = (e, value) => {
    setPage(value);
    setLoadingCompanies(true);
  };

  const metrics = [
    'Conservative',
    'Balanced',
    'Growth-oriented',
    'Speculative',
    'Income-focused',
    'Value-driven'
  ]

  useEffect(() => {
    if (!loadingCompanies) {
      return;
    }

    const api_cmd = Constants.API_IP_ADDRESS
      + 'get_investment_ranking_list?'
      + 'ranked_by=' + metrics[selectedCriteria].toLowerCase()
      + '&top_n=' + Constants.RANKING_INVESTMENT_TOP_N
      + '&api-key=' + apiKey;
    const fetchData = async () => {
      fetch(api_cmd)
      .then((response) => response.json())
      .then((data) => {
        handleDataFetched(data);
        resultAPICallMap.set(api_cmd, data);
      })
      .catch((err) => {
        console.log(err.message);
        setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
        setLoadingCompanies(false);
      });
    };

    const handleDataFetched = (data) => {
      setupTable(data);
      setLoadingCompanies(false);
    };

    if (resultAPICallMap.has(api_cmd)) {
      handleDataFetched(resultAPICallMap.get(api_cmd));
    } else {
      fetchData();
    }
  }, [ loadingCompanies, apiKey, selectedCriteria ]);

  function setupTable(data) {
    if (data === undefined) {
      return;
    }

    // paging the data
    const data_page = data.slice(
      (page - 1) * Constants.RANKING_TOP_N_PER_PAGE,
      page * Constants.RANKING_TOP_N_PER_PAGE
    )

    setColumns([
      { name: "rank", align: "center", width: "2px" },
      { name: "company", align: "center" },
      { name: "market_cap", align: "center" },
      { name: "conservative", align: "center" },
      { name: "balanced", align: "center" },
      { name: "growth", align: "center" },
      { name: "speculative", align: "center" },
      { name: "income", align: "center" },
      { name: "value", align: "center" },
    ]);
    
    setRows(
      data_page.map((item, index) => {
        return renderRow((page - 1) * Constants.RANKING_TOP_N_PER_PAGE + index + 1,
                         item.ticker,
                         item.short_name,
                         item.market_cap,
                         item.conservative_investor_score,
                         item.balanced_investor_score,
                         item.growth_investor_score,
                         item.speculative_investor_score,
                         item.income_investor_score,
                         item.value_investor_score);
      })
    );
  }

  function renderRow(rank,
                     ticker,
                     name,
                     marketCap,
                     conservative_investor_score,
                     balanced_investor_score,
                     growth_investor_score,
                     speculative_investor_score,
                     income_investor_score,
                     value_investor_score) {
    return {
      rank: (
        <ArgonTypography variant="caption" color="dark" fontWeight="medium" padding="none">
          {rank}
        </ArgonTypography>
      ),
      company: (
      //   <ArgonButton
      //     variant="text" color="secondary" size="small"
      //     href={"/company/" + ticker}
      //   >
      //     {Utils.cutLongText(name, Constants.SCORE_PEER_MAX_LENGTH_COMPANY_NAME)}
      //   </ArgonButton>
      // ),
        hideName ? (
          <ArgonBox
            sx={{
              width: 150,
              height: 25,
              borderRadius: 2,
              bgcolor: '#cccccc',
            }}
          />
        ) : (
          <ArgonButton
          variant="text" color="secondary" size="small"
          href={"/company/" + ticker}
        >
          {Utils.cutLongText(name, Constants.RANKING_LENGTH_COMPANY_NAME)}
        </ArgonButton>
        )
      ),
      market_cap: (
        hideName ? (
          <ArgonBox
            sx={{
              width: 70,
              height: 25,
              borderRadius: 2,
              bgcolor: '#cccccc',
            }}
          />
        ) : (
          <ArgonTypography
            variant="caption"
            color="dark"
            fontWeight="regular"
          >
            {Utils.formatNumberLonger(marketCap, '$', '')}
          </ArgonTypography>
        )
      ),
      conservative: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(conservative_investor_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      balanced: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(balanced_investor_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      growth: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(growth_investor_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      speculative: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(speculative_investor_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      income: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(income_investor_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      value: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(value_investor_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
    }
  }

  return (
    <Card style={{ overflow: 'auto' }}>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <ArgonTypography variant="h6">
          Our Top Picks for Various Investor Profiles
        </ArgonTypography>
        {loadingCompanies ? <CircularProgress /> : null}
      </ArgonBox>
      {errorMsg.length === 0 ? (
        <ArgonBox pb={1} display='flex' justifyContent='center' alignContent='center'>
          <ArgonBox style={{ width: '200px' }}>
            <Select
              defaultValue={selectedCriteria}
              label="Criteria"
              onChange={(e) => {
                setSelectedCriteria(e.target.value);
                setLoadingCompanies(true);
              }}
              sx={{
                borderColor: '#11cdef',
              }}
            >
              {metrics.map((item, index) => {
                return <MenuItem value={index}>Sort by {item}</MenuItem>
              })}
            </Select>
          </ArgonBox>
        </ArgonBox>
      ) : null }
      {(errorMsg.length === 0 && numPages > 1) ? (
        <Pagination
          color='warning' siblingCount={1} size="small"
          count={numPages}
          page={page}
          onChange={handlePageChange}
        />
      ) : null}
      {errorMsg.length === 0 ? (
        <ArgonBox
          pt={1}
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={columns} rows={rows} />
        </ArgonBox>
      ) : (
        <ArgonBox px={3} pb={1}>
          <ArgonTypography variant="button" fontWeight='regular' color="text">
            {errorMsg}
          </ArgonTypography>
        </ArgonBox>
      )}
    </Card>
  );
}

RankInvestment.defaultProps = {
  hideName: false
};

RankInvestment.propTypes = {
  title: PropTypes.string.isRequired,
  hideName: PropTypes.bool,
  apiKey: PropTypes.string.isRequired
};

export default RankInvestment;
