// react-routers components
import React, { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

// Shared config & utils
import * as Constants from "config/Constants"
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

// SPAI components
import SpaiCircularScoreWithLabel from "components/SpaiCircularScoreWithLabel";
import SpaiLinearProgressWithLabelAndContent from "components/SpaiLinearProgressWithLabelAndContent";
import SpaiMultiTypographyWithLabel from "components/SpaiMultiTypographyWithLabel";

import useWindowDimensions from "utils/useWindowDimensions";

function ScoreIncome({title, data, ticker, showProgress}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [showAnalysis, setShowAnalysis] = useState(false);
  const { _, width } = useWindowDimensions();
  let rangePeriod = Utils.getPeriodRange(data.periods);

  let overviewStackSize = Constants.CIRCULAR_SCORE_WITH_LABEL_SIZE;
  if (width < 700) {
    overviewStackSize = width * 0.8;
  }

  const isValid = (Object.keys(data).length > 0);
  if (!isValid) {
    return (
      <Card>
        <Stack pt={2} px={2} direction="row">
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {title}
            </ArgonTypography>
          </ArgonBox>
        </Stack>
        <ArgonBox px={2} py={1}>
          <ArgonTypography variant="button" fontWeight='regular' color="text">
            {Constants.NO_AUTHORIZED_MESSAGE}
          </ArgonTypography>
        </ArgonBox>
      </Card>
    )
  }

  return (
    <Card>
      <Stack pt={2} px={2} pb={1} direction="row">
        <ArgonBox display="flex" flexDirection="column">
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
          <ArgonTypography variant="caption" color="secondary">
            {rangePeriod[0]} &#8594; {rangePeriod[1]}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          {showProgress ? <CircularProgress size={26} color='info' /> : (
            <Tooltip title="Show Details">
              <ArgonButton
                color="info"
                size="small" 
                circular 
                iconOnly 
                onClick={() => {
                  setShowAnalysis(!showAnalysis);
                }}
              >
                {showAnalysis ? <Icon>arrow_upward</Icon> : <Icon>arrow_downward</Icon>}
              </ArgonButton>
            </Tooltip>
          )}
        </ArgonBox>
      </Stack>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2} pb={2} px={2}
      >
        <Grid container columnSpacing={2}>
          <Grid item alignItems="center" display="flex" justifyContent="center" alignContent="center">
            <Stack width={overviewStackSize} pb={1}>
              <ArgonBox display="flex" justifyContent="center">
                <SpaiCircularScoreWithLabel
                  value={data.overall_financial_health_outlook_score * 10}
                  size={Constants.CIRCULAR_SCORE_SIZE}
                />
              </ArgonBox>
              <ArgonBox py={1}>
                {showAnalysis ? (
                  <ArgonBox py={1}>
                    <ArgonBox p={1} borderRadius="md"
                      sx={({ palette: { grey, background } }) => ({
                        backgroundColor: darkMode ? background.default : grey[100],
                      })}
                    >
                      <ArgonTypography variant="caption" color="dark" display="block">
                        {data.conclusion}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                ) : null}
              </ArgonBox>
              <ArgonButton
                color="info"
                size="small"
                href={"https://finance.yahoo.com/quote/" + ticker + "/financials/"}
                target="blank"
              >
                <Icon>launch</Icon>&nbsp;
                Check Data
              </ArgonButton>
            </Stack>
          </Grid>
          <Grid item sm container columnSpacing={4}>
            <Grid item md={12} lg={6} container direction="column">
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.revenue_growth_outlook_score*10}
                  title="Revenue"
                  content={data.revenue_growth_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.gross_profit_margin_outlook_score*10}
                  title='Gross Profit Margin'
                  content={data.gross_profit_margin_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.operating_expenses_outlook_score*10}
                  title='Operating Expenses'
                  content={data.operating_expenses_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.operating_income_outlook_score*10}
                  title='Operating Income'
                  content={data.operating_income_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.non_operating_income_outlook_score*10}
                  title='Non-Operating Income'
                  content={data.non_operating_income_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
            </Grid>
            <Grid item md={12} lg={6} container direction="column">
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.net_income_outlook_score*10}
                  title='Net Income'
                  content={data.net_income_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={data.earnings_per_share_outlook_score*10}
                  title='EPS'
                  content={data.earnings_per_share_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiMultiTypographyWithLabel 
                  label='Earnings Forecast'
                  contents={[
                    data.earnings_forecast,
                  ]}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiMultiTypographyWithLabel 
                  label='Capital Allocation Decisions'
                  contents={[
                    data.capital_allocation_decisions,
                  ]}
                  showContent={showAnalysis}
                />
              </Grid>
              <Grid item xs>
                <SpaiMultiTypographyWithLabel 
                  label='Risk & Opportunity Identification'
                  contents={[
                    data.potential_red_flags,
                    data.improvement_opportunities,
                  ]}
                  showContent={showAnalysis}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

ScoreIncome.defaultProps = {
  showProgress: false,
};

ScoreIncome.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  ticker: PropTypes.string.isRequired,
  showProgress: PropTypes.bool,
};

export default ScoreIncome;
