// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

// Argon Dashboard 2 MUI examples
import ArgonButton from "components/ArgonButton";
import Table from "examples/Tables/Table";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// import { useArgonController } from "context";

// Spai
import SpaiCircularScoreWithLabel from "components/SpaiCircularScoreWithLabel";

let resultAPICallMap = new Map();

function RankFinance({loadingRanking, setLoadingRanking, hideName, apiKey,
                      rankingOptions, setSelectedIndustry, setOpenPeers,}){
  // const [controller,] = useArgonController();
  // const { accessInfo } = controller;
  const [errorMsg, setErrorMsg] = useState(Constants.NO_AUTHORIZED_MESSAGE);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const numPages = Constants.RANKING_TOP_N / Constants.RANKING_TOP_N_PER_PAGE;

  const handlePageChange = (event, value) => {
    setPage(value);
    setLoadingRanking(true);
  };

  // useEffect(() => {
  //   // setTimeout(function() {
  //   //   if (loadingRanking) {
  //   //     setLoadingRanking(false);
  //   //     setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
  //   //   }
  //   // }, 2000);
  //   if (apiKey.length === 0) {
  //     setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
  //   }
  // }, [ apiKey ]);

  useEffect(() => {
    if (!loadingRanking) {
      return;
    }

    // if (apiKey.length === 0 ) {
    //   return;
    // }

    const api_cmd = Constants.API_IP_ADDRESS +
      'get_ranking_list?top_n=' + Constants.RANKING_TOP_N +
      '&market_cap_min=' + rankingOptions.marketCapRange[0] +
      '&market_cap_max=' + rankingOptions.marketCapRange[1] +
      '&liquidity_weight=' + rankingOptions.liquidityWeight +
      '&profitability_weight=' + rankingOptions.profitabilityWeight +
      '&valuation_weight=' + rankingOptions.valuationWeight +
      '&dividends_weight=' + rankingOptions.dividendsWeight +
      '&growth_weight=' + rankingOptions.growthWeight +
      '&cash_flow_weight=' + rankingOptions.cashFlowWeight +
      '&risk_and_volatility_weight=' + rankingOptions.volatilityWeight +
      '&macroeconomy_weight=' + rankingOptions.macroeconomyWeight +
      '&api-key=' + apiKey;
    const fetchData = async () => {
      fetch(api_cmd)
        .then((response) => response.json())
        .then((data) => {
          handleDataFetched(data);
          resultAPICallMap.set(api_cmd, data);
        })
        .catch((err) => {
          setLoadingRanking(false);
          console.error(err.message);
          setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
        });
    };

    const handleDataFetched = (data) => {
      setLoadingRanking(false);

      if (!Array.isArray(data)) {
        setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
      }
      setErrorMsg('');

      // paging the data
      const data_page = data.slice(
        (page - 1) * Constants.RANKING_TOP_N_PER_PAGE,
        page * Constants.RANKING_TOP_N_PER_PAGE
      )

      let tmpColumns = [
        { name: "rank", align: "center", width: "2px" },
        { name: "company", align: "center" },
        { name: "market_cap", align: "center" },
        { name: "industry", align: "left" },
        { name: "overall", align: "center" }
      ]
      if (rankingOptions.liquidityWeight > 0) {
        tmpColumns.push(
          { name: "LIQU", align: "center" }
        )
      }
      if (rankingOptions.profitabilityWeight > 0) {
        tmpColumns.push(
          { name: "PROF", align: "center" }
        )
      }
      if (rankingOptions.growthWeight > 0) {
        tmpColumns.push(
          { name: "GROW", align: "center" }
        )
      }
      if (rankingOptions.valuationWeight > 0) {
        tmpColumns.push(
          { name: "VALU", align: "center" }
        )
      }
      if (rankingOptions.cashFlowWeight > 0) {
        tmpColumns.push(
          { name: "CFLOW", align: "center" }
        )
      }
      if (rankingOptions.dividendsWeight > 0) {
        tmpColumns.push(
          { name: "DIVI", align: "center" }
        )
      }
      if (rankingOptions.volatilityWeight > 0) {
        tmpColumns.push(
          { name: "RISK", align: "center" }
        )
      }
      if (rankingOptions.macroeconomyWeight > 0) {
        tmpColumns.push(
          { name: "MECO", align: "center" }
        )
      }
      setColumns(tmpColumns);

      setRows(
        data_page.map((item, index) => {
          return renderRow(
            (page - 1) * Constants.RANKING_TOP_N_PER_PAGE + index + 1,
            item.ticker,
            item.short_name,
            item.market_cap,
            item.industry,
            item.financial_overall_score,
            item.liquidity_and_solvency_score,
            item.profitability_score,
            item.growth_and_innovation_score,
            item.valuation_score,
            item.cash_flow_and_efficiency_score,
            item.dividends_and_payouts_score,
            item.risk_and_volatility_score,
            item.macroeconomy_score
          );
        })
      );
    };

    if (resultAPICallMap.has(api_cmd)) {
      handleDataFetched(resultAPICallMap.get(api_cmd));
    } else {
      fetchData();
    }
  }, [ loadingRanking, page, rankingOptions ]);

  function renderRow(rank,
    ticker,
    name,
    market_cap,
    industry,
    financial_overall_score,
    liquidity_and_solvency_score,
    profitability_score,
    growth_and_innovation_score,
    valuation_score,
    cash_flow_and_efficiency_score,
    dividends_and_payouts_score,
    risk_and_volatility_score,
    macroeconomy_score) {
    return {
      rank: (
        <ArgonTypography variant="caption" color="dark" fontWeight="medium" padding="none">
          {rank}
        </ArgonTypography>
      ),
      company: (
        hideName ? (
          <ArgonBox
            sx={{
              width: 150,
              height: 25,
              borderRadius: 2,
              bgcolor: '#cccccc',
            }}
          />
        ) : (
          <ArgonButton
          variant="text" color="secondary" size="small"
          href={"/company/" + ticker}
        >
          {Utils.cutLongText(name, Constants.RANKING_LENGTH_COMPANY_NAME)}
        </ArgonButton>
        )
      ),
      market_cap: (
        hideName ? (
          <ArgonBox
            sx={{
              width: 70,
              height: 25,
              borderRadius: 2,
              bgcolor: '#cccccc',
            }}
          />
        ) : (
          <ArgonTypography variant="caption" color="text" fontWeight="medium">
            {Utils.formatNumberLonger(market_cap)}
          </ArgonTypography>
        )
      ),
      industry: (
        <ArgonButton
          variant="text" color="secondary" size="small"
          onClick={() => {
            setSelectedIndustry(industry);
            setOpenPeers(true);
          }}
        >
          {industry}
        </ArgonButton>
      ),
      overall: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(financial_overall_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      LIQU: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(liquidity_and_solvency_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      PROF: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(profitability_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      GROW: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(growth_and_innovation_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      VALU: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(valuation_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      CFLOW: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(cash_flow_and_efficiency_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      DIVI: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(dividends_and_payouts_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      RISK: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(risk_and_volatility_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
      MECO: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(macroeconomy_score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
    }
  }

  return (
    <Card style={{ overflow: 'auto' }}>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <ArgonTypography variant="h6">
          Top Choices: Financial Strength Leaders
        </ArgonTypography>
        {loadingRanking ? <CircularProgress /> : null}
      </ArgonBox>
      {(errorMsg.length === 0 && numPages > 1) ? (
        <Pagination
          color='warning' siblingCount={1} size="small"
          count={numPages}
          page={page}
          onChange={handlePageChange}
        />
      ) : null}
      {errorMsg.length === 0 ? (
        <ArgonBox
          pt={1}
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={columns} rows={rows} hideName={true} />
        </ArgonBox>
      ) : (
        <ArgonBox px={3} pb={1}>
          <ArgonTypography variant="button" fontWeight='regular' color="text">
            {errorMsg}
          </ArgonTypography>
        </ArgonBox>
      )}
    </Card>
  );
}

RankFinance.defaultProps = {
  industryName: "",
  loadingCompanies: false,
  hideName: false
};

RankFinance.propTypes = {
  loadingRanking: PropTypes.bool.isRequired,
  setLoadingRanking: PropTypes.func.isRequired,
  rankingOptions: PropTypes.object.isRequired,
  setSelectedIndustry: PropTypes.func.isRequired,
  setOpenPeers: PropTypes.func.isRequired,
  hideName: PropTypes.bool,
  apiKey: PropTypes.string.isRequired
};

export default RankFinance;
