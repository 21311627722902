import { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

// EmailJS
import emailjs from '@emailjs/browser';

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

function ContactUsDialog({open, setOpen, message}) {
  const [sending, setSending] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [validName, setValidName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validMessage, setValidMessage] = useState(false);

  const handleClose = () => {
    setSending(false);
    setOpen(false);
    setErrorMsg('');
  };

  const handleNameValidation = (e) => {
    //test whether input is valid
    setValidName(Utils.validateName(e.target.value));
  };

  const handleEmailValidation = (e) => {
    //test whether input is valid
    setValidEmail(Utils.validateEmail(e.target.value));
  };

  const handleMessageValidation = (e) => {
    //test whether input is valid
    setValidMessage(e.target.value.length > 0);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          setSending(true);

          if (!validEmail || !validMessage || !validName) {
            return;
          }

          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          emailjs
            .send(Constants.EMAILJS_SERVICE_ID, Constants.EMAILJS_TEMPLATE_ID, formJson, {
              publicKey: Constants.EMAILJS_PUBLIC_KEY,
            })
            .then(
              () => handleClose(),
              (error) => setErrorMsg('There was issue sending your email. Please try again later.'),
            );
        },
      }}
    >
      <DialogTitle>Contact us</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {errorMsg}
        </DialogContentText>
        <DialogContentText pt={2} pb={1}>
          <ArgonInput
            autoFocus
            required
            name="email"
            type="from_email" 
            placeholder="Your email"
            onChange={(e) => handleEmailValidation(e)}
            error={!validEmail}
          />
        </DialogContentText>
        <DialogContentText py={1}>
          <ArgonInput
            required
            name="from_name"
            placeholder="Your Name"
            onChange={(e) => handleNameValidation(e)}
            error={!validName}
          />
        </DialogContentText>
        <DialogContentText pt={1} pb={2}>
          <ArgonInput
            required
            name="message"
            placeholder="Message"
            value={message}
            multiline rows={5}
            onChange={(e) => handleMessageValidation(e)}
            error={!validMessage}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ArgonButton
          color='info'
          variant="contained"
          disabled={sending || !validEmail || !validMessage || !validName}
          type="submit"
          sx={{ minWidth: '300px' }}
        >
          <Icon>send</Icon>&nbsp;
          Send
        </ArgonButton>
      </DialogActions>
    </Dialog>
  );
}

ContactUsDialog.defaultProps = {
  message: "",
};

// Typechecking props for the ReportListInfo
ContactUsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default ContactUsDialog;