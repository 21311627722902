const LOGOS=[
{
	brand: 'A',
	website: ['https://www.agilent.com'],
	logo: 'https://www.agilent.com/cs/home_images/favicon.ico',
},
{
	brand: 'AAPL',
	website: ['https://www.apple.com'],
	logo: 'https://www.apple.com/apple-touch-icon.png',
},
{
	brand: 'ABBNY',
	website: ['https://global.abb/group/en'],
	logo: 'https://global.abb/etc.clientlibs/abb/group/clientlibs/clientlib-base/resources/favicon-web.png',
},
{
	brand: 'ABBV',
	website: ['https://www.abbvie.com'],
	logo: '',
},
{
	brand: 'ABEV',
	website: ['https://www.ambev.com.br'],
	logo: '',
},
{
	brand: 'ABNB',
	website: ['https://www.airbnb.com'],
	logo: 'https://www.airbnb.com/favicon.ico',
},
{
	brand: 'ABT',
	website: ['https://www.abbott.com'],
	logo: 'https://www.abbott.com/etc.clientlibs/abbott-platform/clientlibs/favicons/clientlib-favicon-abbott/resources/icon-192x192.png',
},
{
	brand: 'ACGL',
	website: ['https://www.archgroup.com'],
	logo: 'https://www.archgroup.com/wp-content/uploads/2020/11/cropped-Arch-site-icon-512-192x192.jpg',
},
{
	brand: 'ACN',
	website: ['https://www.accenture.com'],
	logo: 'https://www.accenture.com/favicon.ico',
},
{
	brand: 'ADBE',
	website: ['https://www.adobe.com'],
	logo: '',
},
{
	brand: 'ADI',
	website: ['https://www.analog.com'],
	logo: '',
},
{
	brand: 'ADM',
	website: ['https://www.adm.com'],
	logo: 'https://www.adm.com/dist/assets/images/ADM_Primary_favicon_32x32.png',
},
{
	brand: 'ADP',
	website: ['https://www.adp.com'],
	logo: '',
},
{
	brand: 'ADSK',
	website: ['https://www.autodesk.com'],
	logo: '',
},
{
	brand: 'AEM',
	website: ['https://www.agnicoeagle.com'],
	logo: '',
},
{
	brand: 'AEP',
	website: ['https://www.aep.com'],
	logo: 'https://www.aep.com/favicon.ico',
},
{
	brand: 'AFL',
	website: ['https://www.aflac.com'],
	logo: 'https://www.aflac.com/_Global-Assets/images/favicons/apple_touch_icon_152x152.png',
},
{
	brand: 'AIG',
	website: ['https://www.aig.com'],
	logo: 'https://www.aig.com/favicon.ico',
},
{
	brand: 'AIQUY',
	website: ['https://www.airliquide.com'],
	logo: 'https://www.airliquide.com/themes/custom/al/favicon.ico',
},
{
	brand: 'AJG',
	website: ['https://www.ajg.com'],
	logo: 'https://www.ajg.com/assetbundles/GSC.Site.Gallagher/img/favicon/apple-touch-icon.png',
},
{
	brand: 'ALC',
	website: ['https://www.alcon.com'],
	logo: 'https://www.alcon.com/themes/custom/bedrock/images/favicon/apple-touch-icon.png',
},
{
	brand: 'ALL',
	website: ['https://www.allstate.com'],
	logo: 'https://www.allstate.com/favicon.ico',
},
{
	brand: 'AMAT',
	website: ['https://www.appliedmaterials.com'],
	logo: '',
},
{
	brand: 'AMD',
	website: ['https://www.amd.com'],
	logo: '',
},
{
	brand: 'AME',
	website: ['https://www.ametek.com'],
	logo: 'https://www.ametek.com/favicon.ico',
},
{
	brand: 'AMGN',
	website: ['https://www.amgen.com'],
	logo: '',
},
{
	brand: 'AMP',
	website: ['https://www.ameriprise.com'],
	logo: 'https://www.ameriprise.com/webfiles/1714103176390/assets/ampcom/images/ameriprise-touch-icon.png',
},
{
	brand: 'AMT',
	website: ['https://www.americantower.com'],
	logo: 'https://www.americantower.com/sites/default/themes/custom/www_bootstrap/ATC_Favicon_Corp.png',
},
{
	brand: 'AMX',
	website: ['https://www.americamovil.com'],
	logo: '',
},
{
	brand: 'AMZN',
	website: ['https://www.aboutamazon.com'],
	logo: 'https://www.aboutamazon.com/apple-touch-icon.png',
},
{
	brand: 'ANET',
	website: ['https://www.arista.com'],
	logo: 'https://www.arista.com/templates/arista_v2/favicon.ico',
},
{
	brand: 'ANSS',
	website: ['https://www.ansys.com'],
	logo: '',
},
{
	brand: 'ANYYY',
	website: ['https://www.aena.es'],
	logo: 'https://www.aena.es/apple-touch-icon-precomposed.png',
},
{
	brand: 'ANZGY',
	website: ['https://www.anz.com.au'],
	logo: 'https://www.anz.com.au/apps/settings/wcm/designs/commons/images/appicons/favicon-196x196.png',
},
{
	brand: 'AON',
	website: ['https://www.aon.com'],
	logo: 'https://www.aon.com/lib/icons/favicon.png',
},
{
	brand: 'APD',
	website: ['https://www.airproducts.com'],
	logo: 'https://www.airproducts.com/favicon.ico',
},
{
	brand: 'APH',
	website: ['https://www.amphenol.com'],
	logo: 'https://www.amphenol.com/files/apple-touch-icon.png',
},
{
	brand: 'APO',
	website: ['https://www.apollo.com'],
	logo: '',
},
{
	brand: 'APP',
	website: ['https://www.applovin.com'],
	logo: 'https://www.applovin.com/wp-content/uploads/2019/09/cropped-applovin-favicon-rev-9-2019-192x192.png',
},
{
	brand: 'ARES',
	website: ['https://www.aresmgmt.com'],
	logo: '',
},
{
	brand: 'ARM',
	website: ['https://www.arm.com'],
	logo: 'https://www.arm.com/shared/common/img/favicon/android-chrome-256x256.png?v=1.0.8872.17097',
},
{
	brand: 'ASML',
	website: ['https://www.asml.com'],
	logo: 'https://www.asml.com/favicon.ico',
},
{
	brand: 'ATEYY',
	website: ['https://www.advantest.com'],
	logo: 'https://www.advantest.com/common_img/apple-touch-icon.png',
},
{
	brand: 'ATLKY',
	website: ['https://www.atlascopcogroup.com'],
	logo: 'https://www.atlascopcogroup.com/etc.clientlibs/settings/wcm/designs/corporate/clientlib-preloaded-v2/resources/favicon.ico',
},
{
	brand: 'AVB',
	website: ['https://www.avalonbay.com'],
	logo: 'https://www.avaloncommunities.com:443/pf/resources/icons/favicon.ico?d=166',
},
{
	brand: 'AVGO',
	website: ['https://www.broadcom.com'],
	logo: 'https://www.broadcom.com/favicon-96x96.png',
},
{
	brand: 'AWK',
	website: ['https://www.amwater.com'],
	logo: '',
},
{
	brand: 'AXON',
	website: ['https://www.axon.com'],
	logo: 'https://axon-com-static-files.s3.us-west-2.amazonaws.com/public/static/img/favicon/apple-touch-icon.png?v=OmJlpPw8bQ',
},
{
	brand: 'AXP',
	website: ['https://www.americanexpress.com'],
	logo: 'https://www.americanexpress.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'AZN',
	website: ['https://www.astrazeneca.com'],
	logo: 'https://www.astrazeneca.com/etc/designs/az/img/favicons/icon-apple-touch-icon-152x152.png',
},
{
	brand: 'AZO',
	website: ['https://www.autozone.com'],
	logo: 'https://www.autozone.com/favicon.ico',
},
{
	brand: 'BA',
	website: ['https://www.boeing.com'],
	logo: 'https://www.boeing.com/favicon.ico',
},
{
	brand: 'BABA',
	website: ['https://www.alibabagroup.com'],
	logo: 'https://static.alibabagroup.com/static/favicon.ico',
},
{
	brand: 'BAC',
	website: ['https://www.bankofamerica.com'],
	logo: 'https://www2.bac-assets.com/homepage/spa-assets/images/assets-images-global-favicon-android-chrome-192x192-CSXafb7d716.png',
},
{
	brand: 'BAESY',
	website: ['https://www.baesystems.com'],
	logo: '',
},
{
	brand: 'BBD',
	website: ['https://banco.bradesco'],
	logo: 'https://banco.bradesco/favicon.ico',
},
{
	brand: 'BBVA',
	website: ['https://www.bbva.com'],
	logo: 'https://www.bbva.com/wp-content/themes/coronita-bbvacom/assets/images/comun/favicon.ico',
},
{
	brand: 'BCS',
	website: ['https://home.barclays'],
	logo: 'https://home.barclays/content/dam/icons/favicons/barclays/apple-touch-icon-180x180.png',
},
{
	brand: 'BDORY',
	website: ['https://www.bb.com.br'],
	logo: 'https://www.bb.com.br/favicon.ico',
},
{
	brand: 'BDX',
	website: ['https://www.bd.com'],
	logo: 'https://www.bd.com/content/dam/bd-assets/bd-com/en-us/logos/bd/BD_PWAs_icon.png',
},
{
	brand: 'BHP',
	website: ['https://www.bhp.com'],
	logo: 'https://www.bhp.com/-/media/favicons/ms-icon-310x310.png',
},
{
	brand: 'BIDU',
	website: ['https://ir.baidu.com'],
	logo: 'https://ir.baidu.com/sites/g/files/knoqqb24286/files/favicon_0.ico',
},
{
	brand: 'BIIB',
	website: ['https://www.biogen.com'],
	logo: 'https://www.biogen.com/content/dam/corporate/international/global/en-US/global/favicon.ico',
},
{
	brand: 'BK',
	website: ['https://www.bnymellon.com'],
	logo: 'https://www.bnymellon.com/etc.clientlibs/bnymellon/clientlibs/clientlib-site/resources/images/favicons/favicon-152.png',
},
{
	brand: 'BKNG',
	website: ['https://www.bookingholdings.com'],
	logo: 'https://www.bookingholdings.com/wp-content/uploads/2018/02/cropped-b-favicon-192x192.png',
},
{
	brand: 'BKR',
	website: ['https://www.bakerhughes.com'],
	logo: 'https://www.bakerhughes.com/favicon.ico',
},
{
	brand: 'BLK',
	website: ['https://www.blackrock.com'],
	logo: 'https://www.blackrock.com/favicon.ico',
},
{
	brand: 'BMO',
	website: ['https://www.bmo.com'],
	logo: 'https://www.bmo.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'BMY',
	website: ['https://www.bms.com'],
	logo: 'https://www.bms.com/assets/bms/us/en-us/www/bms.ico',
},
{
	brand: 'BN',
	website: ['https://www.bn.brookfield.com'],
	logo: '',
},
{
	brand: 'BNPQY',
	website: ['https://group.bnpparibas'],
	logo: '',
},
{
	brand: 'BNS',
	website: ['https://www.scotiabank.com'],
	logo: 'https://www.scotiabank.com/content/dam/scotiabank/enterprise/images/logos/favicon.ico',
},
{
	brand: 'BP',
	website: ['https://www.bp.com'],
	logo: 'https://www.bp.com/favicon.ico',
},
{
	brand: 'BRK-B',
	website: ['https://www.berkshirehathaway.com'],
	logo: '',
},
{
	brand: 'BRO',
	website: ['https://www.bbinsurance.com'],
	logo: 'https://www.bbinsurance.com/wp-content/themes/corporate/favicon.png',
},
{
	brand: 'BSBR',
	website: ['https://www.santander.com.br'],
	logo: 'https://www.santander.com.br/sites/WPC_CMS/imagem/21-03-26_095020_M_favicon.png',
},
{
	brand: 'BSX',
	website: ['https://www.bostonscientific.com'],
	logo: 'https://www.bostonscientific.com/etc.clientlibs/ewp/clientlibs/clientlib-corporate/resources/images/apple-touch-icon.png',
},
{
	brand: 'BTI',
	website: ['https://www.bat.com'],
	logo: 'https://www.bat.com/content/dam/batcom/icons/icon-192x192.png',
},
{
	brand: 'BUD',
	website: ['https://www.ab-inbev.com'],
	logo: '',
},
{
	brand: 'BX',
	website: ['https://www.blackstone.com'],
	logo: 'https://www.blackstone.com/wp-content/uploads/sites/2/2020/01/bx-favicon-192.png?w=192',
},
{
	brand: 'C',
	website: ['https://www.citigroup.com'],
	logo: 'https://www.citigroup.com/global/citigroup-ui/akpublic/images/gpa_favicon.ico',
},
{
	brand: 'CAH',
	website: ['https://www.cardinalhealth.com'],
	logo: 'https://www.cardinalhealth.com/favicon.ico',
},
{
	brand: 'CARR',
	website: ['https://www.corporate.carrier.com'],
	logo: 'https://www.corporate.carrier.com/system/v2402140851/favicon.ico',
},
{
	brand: 'CAT',
	website: ['https://www.caterpillar.com'],
	logo: '',
},
{
	brand: 'CB',
	website: ['https://www.chubb.com/us-en'],
	logo: 'https://www.chubb.com/content/dam/aem-chubb-global/logo/favicon.ico',
},
{
	brand: 'CBRE',
	website: ['https://www.cbre.com'],
	logo: '',
},
{
	brand: 'CCEP',
	website: ['https://www.cocacolaep.com'],
	logo: 'https://www.cocacolaep.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'CCI',
	website: ['https://www.crowncastle.com'],
	logo: 'https://www.crowncastle.com/images/favicons/favicon-196x196.png',
},
{
	brand: 'CDNS',
	website: ['https://www.cadence.com'],
	logo: '',
},
{
	brand: 'CDW',
	website: ['https://www.cdw.com'],
	logo: 'https://webobjects2.cdw.com/is/image/CDW/cdw23-logo-without-tagline-red-rgb',
},
{
	brand: 'CEG',
	website: ['https://www.constellationenergy.com'],
	logo: 'https://www.constellationenergy.com/content/dam/constellationenergy/icons/miscellaneous/favicon.ico',
},
{
	brand: 'CFRUY',
	website: ['https://www.richemont.com'],
	logo: 'https://www.richemont.com/favicon/apple-touch-icon.png',
},
{
	brand: 'CHD',
	website: ['https://www.churchdwight.com'],
	logo: '',
},
{
	brand: 'CHT',
	website: ['https://www.cht.com.tw'],
	logo: 'https://web-eshop.cdn.hinet.net/eShop%20Images/Consumer/Faicon/Faicon_logo_192x192.png',
},
{
	brand: 'CHTR',
	website: ['https://www.corporate.charter.com'],
	logo: '',
},
{
	brand: 'CI',
	website: ['https://www.cigna.com'],
	logo: 'https://www.cigna.com/favicon.ico',
},
{
	brand: 'CL',
	website: ['https://www.colgatepalmolive.com'],
	logo: 'https://www.colgatepalmolive.com/etc/designs/zg/cpcorporate2021/desktop/assets/img/favicon.ico',
},
{
	brand: 'CM',
	website: ['https://www.cibc.com'],
	logo: 'https://www.cibc.com/content/dam/favicon/cibc-favicon/favicon.ico',
},
{
	brand: 'CMCSA',
	website: ['https://corporate.comcast.com'],
	logo: 'https://corporate.comcast.com/favicon.ico',
},
{
	brand: 'CME',
	website: ['https://www.cmegroup.com'],
	logo: 'https://www.cmegroup.com/favicon.ico',
},
{
	brand: 'CMG',
	website: ['https://www.chipotle.com'],
	logo: 'https://www.chipotle.com/etc.clientlibs/cmgaemacs/clientlibs/clientlib-base/resources/icon-192x192.png',
},
{
	brand: 'CMI',
	website: ['https://www.cummins.com'],
	logo: 'https://www.cummins.com/favicon.ico',
},
{
	brand: 'CNC',
	website: ['https://www.centene.com'],
	logo: 'https://www.centene.com/content/dam/centenedotcom/logos/centene-favicon.ico',
},
{
	brand: 'CNI',
	website: ['https://www.cn.ca'],
	logo: 'https://www.cn.ca/Images/CN/CN-Logo-Red-144X144.png?v=-30255',
},
{
	brand: 'CNQ',
	website: ['https://www.cnrl.com'],
	logo: 'https://www.cnrl.com/content/uploads/2022/11/cnrlicon.png',
},
{
	brand: 'CODYY',
	website: ['https://www.saint-gobain.com'],
	logo: 'https://www.saint-gobain.com/sites/saint-gobain.com/themes/custom/sg_radix/favicon.ico',
},
{
	brand: 'COF',
	website: ['https://www.capitalone.com'],
	logo: 'https://www.capitalone.com/apple-touch-icon.png',
},
{
	brand: 'COIN',
	website: ['https://www.coinbase.com'],
	logo: 'https://www.coinbase.com/apple-touch-icon.png',
},
{
	brand: 'COP',
	website: ['https://www.conocophillips.com'],
	logo: 'https://www.conocophillips.com/favicons/apple-touch-icon.png',
},
{
	brand: 'COR',
	website: ['https://www.cencora.com'],
	logo: '',
},
{
	brand: 'COST',
	website: ['https://www.costco.com'],
	logo: 'https://www.costco.com/favicon.ico',
},
{
	brand: 'CP',
	website: ['https://www.cpkcr.com'],
	logo: 'https://www.cpkcr.com/content/dam/cpkc/system/favicon/favicon.ico',
},
{
	brand: 'CPNG',
	website: ['https://www.aboutcoupang.com'],
	logo: '',
},
{
	brand: 'CPRT',
	website: ['https://www.copart.com'],
	logo: 'https://www.copart.com/favicon.ico',
},
{
	brand: 'CQP',
	website: ['https://cqpir.cheniere.com'],
	logo: 'https://d1io3yog0oux5.cloudfront.net/_d42e765472c01767547df3747e16b1b5/chenierepartners/files/theme/site-files/20200619/favicon.ico',
},
{
	brand: 'CRARY',
	website: ['https://www.credit-agricole.com'],
	logo: 'https://www.credit-agricole.com/assets/build/images/icons/favicon.ico',
},
{
	brand: 'CRH',
	website: ['https://www.crh.com'],
	logo: '',
},
{
	brand: 'CRM',
	website: ['https://www.salesforce.com'],
	logo: 'https://www.salesforce.com/c2/public/app/favicon.ico',
},
{
	brand: 'CRWD',
	website: ['https://www.crowdstrike.com'],
	logo: 'https://www.crowdstrike.com/etc.clientlibs/crowdstrike/clientlibs/crowdstrike-common/resources/favicon.ico',
},
{
	brand: 'CSCO',
	website: ['https://www.cisco.com'],
	logo: 'https://www.cisco.com/favicon.ico',
},
{
	brand: 'CSGP',
	website: ['https://www.costargroup.com'],
	logo: '',
},
{
	brand: 'CSLLY',
	website: ['https://www.csl.com.au'],
	logo: '',
},
{
	brand: 'CSX',
	website: ['https://www.csx.com'],
	logo: 'https://www.csx.com/sites/wwwcsx15/includes/themes/wwwcsx/images/favicon.ico',
},
{
	brand: 'CTA-PA',
	website: ['https://www.corteva.com'],
	logo: 'https://www.corteva.com/etc/designs/app/android-icon-192x192.png',
},
{
	brand: 'CTAS',
	website: ['https://www.cintas.com'],
	logo: 'https://www.cintas.com/favicon.ico',
},
{
	brand: 'CTSH',
	website: ['https://www.cognizant.com'],
	logo: 'https://www.cognizant.com/etc.clientlibs/cognizant-dot-com/clientlibs/clientlib-site/resources/images/favicons/favicon.ico',
},
{
	brand: 'CTVA',
	website: ['https://www.corteva.com'],
	logo: 'https://www.corteva.com/etc/designs/app/android-icon-192x192.png',
},
{
	brand: 'CVE',
	website: ['https://www.cenovus.com'],
	logo: 'https://www.cenovus.com/-/media/Project/WWW/Images/Brand/wwwfavicon/www-favicon/www-favicon-16.png?rev=39cd1631474a4c3ab7c988b1c29bc09f&sc_lang=en',
},
{
	brand: 'CVS',
	website: ['https://www.cvshealth.com'],
	logo: 'https://www.cvshealth.com/favicon.ico',
},
{
	brand: 'CVX',
	website: ['https://www.chevron.com'],
	logo: 'https://www.chevron.com/assets/fav/apple-touch-icon.png?v=7k4MjPGMX6',
},
{
	brand: 'CX',
	website: ['https://www.cemex.com'],
	logo: 'https://www.cemex.com/documents/44331/0/favicon.ico/bb8f7f36-5366-238f-2afc-c4066f351a97',
},
{
	brand: 'D',
	website: ['https://www.dominionenergy.com'],
	logo: 'https://www.dominionenergy.com/apple-touch-icon-iphone6-hidef-precomposed.png',
},
{
	brand: 'DAL',
	website: ['https://www.delta.com'],
	logo: 'https://www.delta.com/favicon.ico',
},
{
	brand: 'DASH',
	website: ['https://www.doordash.com'],
	logo: '',
},
{
	brand: 'DB',
	website: ['https://www.db.com'],
	logo: 'https://www.db.com/favicon.ico',
},
{
	brand: 'DD',
	website: ['https://www.dupont.com'],
	logo: 'https://www.dupont.com/content/dam/dupont/global-assets/icons/DuPont_favicon_Shorthand.ico',
},
{
	brand: 'DDOG',
	website: ['https://www.datadoghq.com'],
	logo: 'https://www.datadoghq.com/favicon.ico',
},
{
	brand: 'DE',
	website: ['https://www.deere.com'],
	logo: 'https://www.deere.com/assets/images/image-icons/favicon.ico',
},
{
	brand: 'DELL',
	website: ['https://www.dell.com'],
	logo: '',
},
{
	brand: 'DEO',
	website: ['https://www.diageo.com'],
	logo: 'https://www.diageo.com/icons/apple-touch-icon.png',
},
{
	brand: 'DFS',
	website: ['https://www.discover.com'],
	logo: 'https://www.discover.com/apple-touch-icon.png',
},
{
	brand: 'DG',
	website: ['https://www.dollargeneral.com'],
	logo: '',
},
{
	brand: 'DHI',
	website: ['https://www.drhorton.com'],
	logo: 'https://www.drhorton.com/favicon.ico',
},
{
	brand: 'DHR',
	website: ['https://www.danaher.com'],
	logo: 'https://www.danaher.com/themes/custom/danaher/favicon.ico',
},
{
	brand: 'DIDIY',
	website: ['https://www.didiglobal.com'],
	logo: 'https://website.didiglobal.com/DDlogo.ico',
},
{
	brand: 'DIS',
	website: ['https://www.thewaltdisneycompany.com'],
	logo: 'https://thewaltdisneycompany.com/app/uploads/cropped-512x512-copy5-300x300.png',
},
{
	brand: 'DKILY',
	website: ['https://www.daikin.com'],
	logo: 'https://www.daikin.com/-/media/Project/Daikin/daikin_com/favicon%20ico',
},
{
	brand: 'DLR',
	website: ['https://www.digitalrealty.com'],
	logo: 'https://www.digitalrealty.com/favicon/favicon1.png',
},
{
	brand: 'DLTR',
	website: ['https://www.dollartree.com'],
	logo: 'https://www.dollartree.com/file/v8569639453284500459/general/Dollar-Tree-Icon-RGB-SM.png',
},
{
	brand: 'DNZOY',
	website: ['https://www.denso.com/global/en/'],
	logo: 'https://www.denso.com:443/global/en/-/media/common/favicon.ico?h=10&w=10&rev=a8357586116c4ffe957d2f13de0042e3&hash=EC5795E47DD6DDC0627CA99F6C3536D1',
},
{
	brand: 'DOV',
	website: ['https://www.dovercorporation.com'],
	logo: 'https://www.dovercorporation.com/Content/images/favicon.png',
},
{
	brand: 'DOW',
	website: ['https://www.dow.com'],
	logo: 'https://www.dow.com/content/dam/images/public-images/web/favicons/apple-touch-icon-180x180.png',
},
{
	brand: 'DSCSY',
	website: ['https://www.disco.co.jp'],
	logo: 'https://www.disco.co.jp/favicon.ico',
},
{
	brand: 'DUK',
	website: ['https://www.duke-energy.com'],
	logo: 'https://www.duke-energy.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'DVN',
	website: ['https://www.devonenergy.com'],
	logo: 'https://www.devonenergy.com/favicons/favicon-196x196.png',
},
{
	brand: 'DXCM',
	website: ['https://www.dexcom.com'],
	logo: 'https://dexcom.a.bigcontent.io/v1/static/favicon-196x196',
},
{
	brand: 'E',
	website: ['https://www.eni.com'],
	logo: 'https://www.eni.com/content/dam/enicom/images/favicons/apple-touch-icon-precomposed.png',
},
{
	brand: 'EA',
	website: ['https://www.ea.com'],
	logo: 'https://www.ea.com/assets/images/favicon.png',
},
{
	brand: 'EADSY',
	website: ['https://www.airbus.com'],
	logo: 'https://www.airbus.com/themes/custom/airbus_web_experience_ui/appicons/appicon-180x180.png',
},
{
	brand: 'EBAY',
	website: ['https://www.ebayinc.com'],
	logo: 'https://www.ebayinc.com/favicon.ico',
},
{
	brand: 'EC',
	website: ['https://www.ecopetrol.com.co'],
	logo: 'https://www.ecopetrol.com.co/wps/contenthandler/dav/themelist/eco-tema-principal1/images/favicon-48x48.ico',
},
{
	brand: 'ECL',
	website: ['https://www.ecolab.com'],
	logo: 'https://www.ecolab.com/favicon.ico',
},
{
	brand: 'ED',
	website: ['https://www.conedison.com'],
	logo: 'https://cdnc-dcxprod2-sitecore.azureedge.net/-/media/images/conedison/conedison_favicon-16x16.ico?rev=10469e2c236f4e75be69210e006a7764',
},
{
	brand: 'EFX',
	website: ['https://www.equifax.com'],
	logo: 'https://assets.equifax.com/global/images/icons/apple-touch-icon-57x57.png',
},
{
	brand: 'EIX',
	website: ['https://www.edison.com'],
	logo: 'https://www.edison.com/media/sites/406/images/favicon.ico',
},
{
	brand: 'EL',
	website: ['https://www.elcompanies.com'],
	logo: 'https://www.elcompanies.com/favicon.ico',
},
{
	brand: 'ELV',
	website: ['https://www.elevancehealth.com'],
	logo: 'https://www.elevancehealth.com/content/dam/elevance-health-non-index/logos/Elevance_Health_Avatar_1200x1200.png',
},
{
	brand: 'EMR',
	website: ['https://www.emerson.com'],
	logo: 'https://www.emerson.com/resource/blob/emerson-electric-usa-home-page-favicon-60.ico',
},
{
	brand: 'ENB',
	website: ['https://www.enbridge.com'],
	logo: 'https://www.enbridge.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'EOG',
	website: ['https://www.eogresources.com'],
	logo: 'https://www.eogresources.com/favicon.ico',
},
{
	brand: 'EPD',
	website: ['https://www.enterpriseproducts.com'],
	logo: 'https://www.enterpriseproducts.com/images/icons/favicon-96x96.png',
},
{
	brand: 'EQIX',
	website: ['https://www.equinix.com'],
	logo: '',
},
{
	brand: 'EQNR',
	website: ['https://www.equinor.com'],
	logo: 'https://www.equinor.com/favicon-32x32.png',
},
{
	brand: 'EQR',
	website: ['https://www.equityapartments.com'],
	logo: 'https://www.equityapartments.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'ET',
	website: ['https://energytransfer.com'],
	logo: 'https://www.energytransfer.com/static/img/favicons/apple-touch-icon.png',
},
{
	brand: 'ETN',
	website: ['https://www.eaton.com'],
	logo: '',
},
{
	brand: 'EW',
	website: ['https://www.edwards.com'],
	logo: 'https://www.edwards.com/favicon.ico',
},
{
	brand: 'EXC',
	website: ['https://www.exeloncorp.com'],
	logo: 'https://www.exeloncorp.com/content/dam/exelon/favicons/favicon.ico',
},
{
	brand: 'EXR',
	website: ['https://www.extraspace.com'],
	logo: 'https://www.extraspace.com/favicon.ico',
},
{
	brand: 'F',
	website: ['https://www.ford.com'],
	logo: '',
},
{
	brand: 'FANG',
	website: ['https://www.diamondbackenergy.com'],
	logo: 'https://www.diamondbackenergy.com/sites/g/files/knoqqb82556/files/favicon.ico',
},
{
	brand: 'FANUY',
	website: ['https://www.fanuc.co.jp'],
	logo: 'https://www.fanuc.co.jp/android-chrome.png',
},
{
	brand: 'FAST',
	website: ['https://www.fastenal.com'],
	logo: '',
},
{
	brand: 'FCNCA',
	website: ['https://www.firstcitizens.com'],
	logo: 'https://www.firstcitizens.com/etc.clientlibs/firstcitizens/clientlibs/clientlib-aem/resources/favicon/apple-touch-icon.png?v=2',
},
{
	brand: 'FCX',
	website: ['https://fcx.com'],
	logo: 'https://fcx.com/themes/fcx/favicon.ico',
},
{
	brand: 'FDX',
	website: ['https://www.fedex.com'],
	logo: '',
},
{
	brand: 'FERG',
	website: ['https://www.corporate.ferguson.com'],
	logo: '',
},
{
	brand: 'FI',
	website: ['https://www.fiserv.com'],
	logo: 'https://www.fiserv.com/content/dam/fiserv-com/favicon-med.ico',
},
{
	brand: 'FICO',
	website: ['https://www.fico.com'],
	logo: 'https://www.fico.com/themes/custom/fico_main/assets/images/favicons/android-chrome-192x192.png',
},
{
	brand: 'FIS',
	website: ['https://www.fisglobal.com'],
	logo: '',
},
{
	brand: 'FITB',
	website: ['https://www.53.com'],
	logo: '',
},
{
	brand: 'FLUT',
	website: ['https://www.flutter.com'],
	logo: 'https://www.flutter.com/media/ue4od1pb/android-chrome-144x144.png',
},
{
	brand: 'FMX',
	website: ['https://www.femsa.com'],
	logo: '',
},
{
	brand: 'FNV',
	website: ['https://www.franco-nevada.com'],
	logo: '',
},
{
	brand: 'FRFHF',
	website: ['https://www.fairfax.ca'],
	logo: 'https://www.fairfax.ca/wp-content/uploads/cropped-ffh-favicon-192x192.png',
},
{
	brand: 'FTNT',
	website: ['https://www.fortinet.com'],
	logo: 'https://www.fortinet.com/content/dam/fortinet/images/favicon/android-chrome-512x512.png',
},
{
	brand: 'FTV',
	website: ['https://www.fortive.com'],
	logo: 'https://www.fortive.com/themes/fortive/favicon.ico',
},
{
	brand: 'FUJIY',
	website: ['https://holdings.fujifilm.com'],
	logo: 'https://holdings.fujifilm.com/themes/custom/fujifilm_com_g2/apple-touch-icon-precomposed.png',
},
{
	brand: 'GD',
	website: ['https://www.gd.com'],
	logo: 'https://www.gd.com/-/media/gd-corporate/global/favicon/android-chrome-192x192.ashx',
},
{
	brand: 'GE',
	website: ['https://www.geaerospace.com'],
	logo: 'https://www.geaerospace.com/themes/custom/aero_sub_theme/favicon.ico',
},
{
	brand: 'GEHC',
	website: ['https://www.gehealthcare.com'],
	logo: 'https://www.gehealthcare.com/cdn/res/images/apple-touch-icon.png',
},
{
	brand: 'GEV',
	website: ['https://www.gevernova.com'],
	logo: 'https://www.gevernova.com/themes/custom/ge_vernova_unified/favicon.ico',
},
{
	brand: 'GFS',
	website: ['https://gf.com'],
	logo: 'https://gf.com/wp-content/uploads/2022/03/cropped-logo-192x192.png',
},
{
	brand: 'GIB',
	website: ['https://www.cgi.com'],
	logo: 'https://www.cgi.com/sites/default/files/favicons/android-chrome-192x192.png?v=XByRe4N9y3',
},
{
	brand: 'GILD',
	website: ['https://www.gilead.com'],
	logo: 'https://www.gilead.com/-/media/gilead-corporate/images/favicon_180.png',
},
{
	brand: 'GIS',
	website: ['https://www.generalmills.com'],
	logo: 'https://www.generalmills.com/-/media/project/gmi/corporate/corporate-master/icons/apple-touch-icon.png?rev=4f592f9308b94cf29648a6612a1776f5',
},
{
	brand: 'GLNCY',
	website: ['https://www.glencore.com'],
	logo: '',
},
{
	brand: 'GLW',
	website: ['https://www.corning.com'],
	logo: 'https://www.corning.com/etc.clientlibs/settings/wcm/designs/corning/resources/images/global/favicon.ico',
},
{
	brand: 'GM',
	website: ['https://www.gm.com'],
	logo: 'https://www.gm.com/content/dam/company/img/us/en/gmcom/favicons/1/GM_BUG_FAV_ICON_180x180.png',
},
{
	brand: 'GOLD',
	website: ['https://www.barrick.com'],
	logo: '',
},
{
	brand: 'GOOGL',
	website: ['https://abc.xyz'],
	logo: 'https://www.abc.xyz/assets/d8/4f/fb18973f4082809d50604239e2ef/favicon-194x194.png=s180',
},
{
	brand: 'GPN',
	website: ['https://www.globalpayments.com'],
	logo: '',
},
{
	brand: 'GRMN',
	website: ['https://www.garmin.com'],
	logo: 'https://static.garmin.com/favicons/apple-touch-icon.png',
},
{
	brand: 'GS',
	website: ['https://www.goldmansachs.com'],
	logo: 'https://www.goldmansachs.com/favicon.ico',
},
{
	brand: 'GSK',
	website: ['https://www.gsk.com'],
	logo: 'https://www.gsk.com:443/assets/img/favicons/apple-touch-icon-152x152.png?v=1507259222',
},
{
	brand: 'GWLIF',
	website: ['https://www.greatwestlifeco.com'],
	logo: '',
},
{
	brand: 'GWW',
	website: ['https://www.grainger.com'],
	logo: 'https://www.grainger.com/apple-touch-icon.png',
},
{
	brand: 'HAL',
	website: ['https://www.halliburton.com'],
	logo: 'https://cdn.brandfolder.io/3I2CY2XL/as/ttqvsvbm46pcrm38bncstf/favicon.ico',
},
{
	brand: 'HCA',
	website: ['https://www.hcahealthcare.com'],
	logo: '',
},
{
	brand: 'HD',
	website: ['https://www.homedepot.com'],
	logo: '',
},
{
	brand: 'HDB',
	website: ['https://www.hdfcbank.com'],
	logo: 'https://www.hdfcbank.com/static/features/%5BBBHOST%5D/theme-nb-hdfc/favicon.ico',
},
{
	brand: 'HEI',
	website: ['https://www.heico.com'],
	logo: 'https://heico.com/wp-content/uploads/2022/09/cropped-favicon-heico-192x192.png',
},
{
	brand: 'HES',
	website: ['https://www.hess.com'],
	logo: 'https://www.hess.com/Sitefinity/WebsiteTemplates/HessBootstrap/App_Themes/HessBootstrap/Images/favicon.ico',
},
{
	brand: 'HIG',
	website: ['https://www.thehartford.com'],
	logo: 'https://www.thehartford.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'HLN',
	website: ['https://www.haleon.com'],
	logo: 'https://www.haleon.com/etc.clientlibs/haleon/corporate/components/structure/basepage/clientlibs/resources/images/favicons/favicon.png',
},
{
	brand: 'HLT',
	website: ['https://www.hilton.com'],
	logo: '',
},
{
	brand: 'HMC',
	website: ['https://www.honda.co.jp'],
	logo: 'https://www.honda.co.jp/honda-touch-icon.png',
},
{
	brand: 'HON',
	website: ['https://www.honeywell.com'],
	logo: 'https://www.honeywell.com/etc.clientlibs/baseline-tenant/clientlibs/clientlib-general/resources/icons/favicon.ico',
},
{
	brand: 'HPQ',
	website: ['https://www.hp.com'],
	logo: 'https://www.hp.com/content/dam/sites/worldwide/dems/favicons/hp-blue-favicon.png',
},
{
	brand: 'HSBC',
	website: ['https://www.hsbc.com'],
	logo: 'https://www.hsbc.com/assets/images/touchicon/touch-icon.png',
},
{
	brand: 'HSY',
	website: ['https://www.thehersheycompany.com'],
	logo: 'https://www.thehersheycompany.com/etc.clientlibs/hershey/clientlibs/clientlib-site-revised/resources/icon-192x192.png',
},
{
	brand: 'HUBS',
	website: ['https://www.hubspot.com'],
	logo: 'https://www.hubspot.com/favicon.ico',
},
{
	brand: 'HUM',
	website: ['https://www.humana.com'],
	logo: 'https://www.humana.com/favicon.ico',
},
{
	brand: 'HWM',
	website: ['https://www.howmet.com'],
	logo: 'https://www.howmet.com/wp-content/uploads/sites/3/2023/05/fav-icon.png?w=145',
},
{
	brand: 'IBKR',
	website: ['https://www.interactivebrokers.com'],
	logo: 'https://www.interactivebrokers.com/images/web/favicons/home-screen-icon-192x192.png',
},
{
	brand: 'IBM',
	website: ['https://www.ibm.com'],
	logo: 'https://www.ibm.com/apple-touch-icon.png',
},
{
	brand: 'IBN',
	website: ['https://www.icicibank.com'],
	logo: 'https://www.icicibank.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'ICE',
	website: ['https://www.ice.com'],
	logo: 'https://www.ice.com/publicdocs/images/favicon_ice.ico',
},
{
	brand: 'ICLR',
	website: ['https://www.iconplc.com'],
	logo: 'https://www.iconplc.com/themes/custom/iconplc/favicon.ico',
},
{
	brand: 'IDXX',
	website: ['https://www.idexx.com'],
	logo: 'https://www.idexx.com/static/favicon/idexxcom/favicon-196x196.21a4d06601e4.png',
},
{
	brand: 'IMO',
	website: ['https://www.imperialoil.ca'],
	logo: 'https://www.imperialoil.ca/-/media/imperial/icons/imperial_favicon.ico',
},
{
	brand: 'INFY',
	website: ['https://www.infosys.com'],
	logo: 'https://www.infosys.com/content/dam/infosys-web/en/global-resource/icons/apple-touch-icon.png',
},
{
	brand: 'ING',
	website: ['https://www.ing.com'],
	logo: '',
},
{
	brand: 'INTC',
	website: ['https://www.intel.com'],
	logo: '',
},
{
	brand: 'INTU',
	website: ['https://www.intuit.com'],
	logo: 'https://www.intuit.com/oidam/intuit/ic/en_us/images/01-g/apple-touch-icon-152x152.png',
},
{
	brand: 'IQV',
	website: ['https://www.iqvia.com'],
	logo: 'https://www.iqvia.com/Content/Shotgun/img/favicon/apple-icon-precomposed.png?v=4',
},
{
	brand: 'IR',
	website: ['https://www.irco.com'],
	logo: 'https://www.irco.com/favicon.ico',
},
{
	brand: 'ISRG',
	website: ['https://www.intuitive.com'],
	logo: '',
},
{
	brand: 'IT',
	website: ['https://www.gartner.com'],
	logo: '',
},
{
	brand: 'ITUB',
	website: ['https://www.itau.com.br'],
	logo: '',
},
{
	brand: 'ITW',
	website: ['https://www.itw.com'],
	logo: 'https://www.itw.com/icons/android-icon-192x192.png',
},
{
	brand: 'IX',
	website: ['https://www.orix.co.jp'],
	logo: 'https://www.orix.co.jp/ORIX_favicon_152x152.png',
},
{
	brand: 'JCI',
	website: ['https://www.johnsoncontrols.com'],
	logo: '',
},
{
	brand: 'JNJ',
	website: ['https://www.jnj.com'],
	logo: '',
},
{
	brand: 'JPM',
	website: ['https://www.jpmorganchase.com'],
	logo: 'https://www.jpmorganchase.com/etc.clientlibs/settings/wcm/designs/sites/jpmorganchase/jpmorganchase-base/v0/resources/images/global/favicon-152.png',
},
{
	brand: 'KDP',
	website: ['https://www.keurigdrpepper.com'],
	logo: 'https://www.keurigdrpepper.com/wp-content/themes/keurigdrpepper/img/favicons/android-icon-192x192.png?2',
},
{
	brand: 'KEYS',
	website: ['https://www.keysight.com'],
	logo: 'https://www.keysight.com/etc/designs/keysight/img/content/touch-icon.png',
},
{
	brand: 'KHC',
	website: ['https://www.kraftheinzcompany.com'],
	logo: '',
},
{
	brand: 'KKR',
	website: ['https://www.kkr.com'],
	logo: 'https://www.kkr.com/etc.clientlibs/kkr/clientlibs/clientlib-site/resources/apple-touch-icon.png',
},
{
	brand: 'KLAC',
	website: ['https://www.kla.com'],
	logo: 'https://www.kla.com/wp-content/uploads/android-chrome-256x256-100x100.png',
},
{
	brand: 'KMB',
	website: ['https://www.kimberly-clark.com'],
	logo: 'https://www.kimberly-clark.com/images/favicon_48x48.ico',
},
{
	brand: 'KMI',
	website: ['https://www.kindermorgan.com'],
	logo: 'https://www.kindermorgan.com/metadata/apple-touch-icon.png',
},
{
	brand: 'KO',
	website: ['https://www.coca-colacompany.com'],
	logo: 'https://www.coca-cola.com/onexp-theme/edcc0d80cc741e505800aecb5714e86c4108826dbc10c96144cc0cade98883e6/resources/favicons/apple-touch-icon.png',
},
{
	brand: 'KR',
	website: ['https://www.thekrogerco.com'],
	logo: 'https://www.thekrogerco.com/wp-content/uploads/2019/11/cropped-kroger-favicon-192x192.png',
},
{
	brand: 'KVUE',
	website: ['https://www.kenvue.com'],
	logo: 'https://www.kenvue.com/apple-touch-icon.png',
},
{
	brand: 'LEN',
	website: ['https://www.lennar.com'],
	logo: 'https://www.lennar.com/favicon-512x512.png',
},
{
	brand: 'LHX',
	website: ['https://www.l3harris.com'],
	logo: 'https://www.l3harris.com/themes/custom/l3harris/favicon.ico',
},
{
	brand: 'LI',
	website: ['https://www.lixiang.com'],
	logo: 'https://s.ampmake.com/fed/m01/lixiang3/favicon.ico',
},
{
	brand: 'LIN',
	website: ['https://www.linde.com'],
	logo: 'https://assets.linde.com/-/media/shared/favicon/apple-touch-icon-64x64.png?r=9626e16d64',
},
{
	brand: 'LLY',
	website: ['https://www.lilly.com'],
	logo: 'https://www.lilly.com/favicon.ico',
},
{
	brand: 'LMT',
	website: ['https://www.lockheedmartin.com'],
	logo: 'https://www.lockheedmartin.com/apple-touch-icon.png',
},
{
	brand: 'LNG',
	website: ['https://www.cheniere.com'],
	logo: 'https://www.cheniere.com/favicon.ico',
},
{
	brand: 'LOW',
	website: ['https://www.lowes.com'],
	logo: '',
},
{
	brand: 'LRCX',
	website: ['https://www.lamresearch.com'],
	logo: 'https://www.lamresearch.com/wp-content/uploads/2021/07/cropped-Favicon_512-192x192.png',
},
{
	brand: 'LTMAY',
	website: ['https://www.latamairlinesgroup.net'],
	logo: '',
},
{
	brand: 'LULU',
	website: ['https://corporate.lululemon.com'],
	logo: 'https://corporate.lululemon.com/favicon.ico',
},
{
	brand: 'LVS',
	website: ['https://www.sands.com'],
	logo: 'https://www.sands.com/content/themes/base/assets/img/favicon/android-icon-192x192.png',
},
{
	brand: 'LYB',
	website: ['https://www.lyondellbasell.com'],
	logo: 'https://www.lyondellbasell.com/favicon.ico',
},
{
	brand: 'LYG',
	website: ['https://www.lloydsbankinggroup.com'],
	logo: '',
},
{
	brand: 'MA',
	website: ['https://www.mastercard.com'],
	logo: 'https://www.mastercard.ie/content/dam/dxp/favicon/mastercard-apple-touch-icon.png',
},
{
	brand: 'MAR',
	website: ['https://www.marriott.com'],
	logo: 'https://www.marriott.com/favicon.ico',
},
{
	brand: 'MCD',
	website: ['https://www.mcdonalds.com'],
	logo: 'https://www.mcdonalds.com/content/dam/sites/ie/nfl/logo/favicon.ico',
},
{
	brand: 'MCHP',
	website: ['https://www.microchip.com'],
	logo: 'https://www.microchip.com/favicon.ico',
},
{
	brand: 'MCK',
	website: ['https://www.mckesson.com'],
	logo: 'https://www.mckesson.com/globalassets/mckesson/images/icons/favicon.ico',
},
{
	brand: 'MCO',
	website: ['https://www.moodys.com'],
	logo: 'https://www.moodys.com/favicon.ico',
},
{
	brand: 'MDB',
	website: ['https://www.mongodb.com'],
	logo: 'https://www.mongodb.com/assets/images/global/favicon.ico',
},
{
	brand: 'MDLZ',
	website: ['https://www.mondelezinternational.com'],
	logo: 'https://www.mondelezinternational.com/icons/icon-512x512.png?v=af2d837f687754a78d28edb596b45c6c',
},
{
	brand: 'MDT',
	website: ['https://www.medtronic.com'],
	logo: 'https://www.medtronic.com/content/dam/medtronic-wide/public/channel-configuration/favicons/apple-touch-icon.png',
},
{
	brand: 'MELI',
	website: ['https://www.mercadolibre.com'],
	logo: 'https://http2.mlstatic.com/ui/navigation/5.6.1/mercadolibre/152x152-precomposed.png',
},
{
	brand: 'MET',
	website: ['https://www.metlife.com'],
	logo: 'https://www.metlife.com/static/images/favicon.ico',
},
{
	brand: 'META',
	website: ['https://www.facebook.com/'],
	logo: 'https://static.xx.fbcdn.net/rsrc.php/v3/yi/r/4Kv5U5b1o3f.png',
},
{
	brand: 'MFC',
	website: ['https://www.manulife.com'],
	logo: '',
},
{
	brand: 'MFG',
	website: ['https://www.mizuho-fg.co.jp'],
	logo: 'https://www.mizuho-fg.co.jp/favicon.ico',
},
{
	brand: 'MLM',
	website: ['https://www.martinmarietta.com'],
	logo: 'https://www.martinmarietta.com/favicon.ico',
},
{
	brand: 'MMC',
	website: ['https://www.mmc.com'],
	logo: 'https://www.marshmclennan.com/etc/designs/mmc-v2/favicon.ico',
},
{
	brand: 'MMM',
	website: ['https://www.3m.com'],
	logo: 'https://www.3m.com/favicon.ico',
},
{
	brand: 'MNST',
	website: ['https://www.monsterbevcorp.com'],
	logo: 'https://www.monsterbevcorp.com/apple-touch-icon.png',
},
{
	brand: 'MO',
	website: ['https://www.altria.com'],
	logo: 'https://www.altria.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'MPC',
	website: ['https://www.marathonpetroleum.com'],
	logo: '',
},
{
	brand: 'MPLX',
	website: ['https://www.mplx.com'],
	logo: '',
},
{
	brand: 'MPWR',
	website: ['https://www.monolithicpower.com'],
	logo: 'https://media.monolithicpower.com/favicon/default/MPS_favicon.png',
},
{
	brand: 'MRAAY',
	website: ['https://www.murata.com'],
	logo: 'https://www.murata.com/favicon.ico',
},
{
	brand: 'MRK',
	website: ['https://www.merck.com'],
	logo: 'https://www.merck.com/wp-content/themes/mhh-merck-mco-theme/images/apple-touch-icon.png',
},
{
	brand: 'MRNA',
	website: ['https://www.modernatx.com'],
	logo: 'https://www.modernatx.com/assets/favicons/apple-touch-icon.png',
},
{
	brand: 'MRVL',
	website: ['https://www.marvell.com'],
	logo: 'https://www.marvell.com/etc.clientlibs/marvell-com/clientlibs/clientlib-site/resources/icon-192x192.png',
},
{
	brand: 'MS',
	website: ['https://www.morganstanley.com'],
	logo: 'https://www.morganstanley.com/etc.clientlibs/msdotcomr4/clientlibs/clientlib-site/resources/img/favicon-196x196.png',
},
{
	brand: 'MSCI',
	website: ['https://www.msci.com'],
	logo: 'https://www.msci.com/favicon.ico',
},
{
	brand: 'MSFT',
	website: ['https://www.microsoft.com'],
	logo: 'https://www.microsoft.com/favicon.ico',
},
{
	brand: 'MSI',
	website: ['https://www.motorolasolutions.com'],
	logo: 'https://www.motorolasolutions.com/etc/designs/msi/favicon.ico',
},
{
	brand: 'MTB',
	website: ['https://www3.mtb.com'],
	logo: '',
},
{
	brand: 'MTD',
	website: ['https://www.mt.com'],
	logo: 'https://www.mt.com/etc/designs/mt/docroot/images/icons/mt-icon-192x192px.png',
},
{
	brand: 'MU',
	website: ['https://www.micron.com'],
	logo: 'https://www.micron.com/content/dam/micron/global/favicon/apple-icon-180x180.png',
},
{
	brand: 'MUFG',
	website: ['https://www.mufg.jp'],
	logo: 'https://www.mufg.jp/apple-touch-icon.png',
},
{
	brand: 'NDAQ',
	website: ['https://www.nasdaq.com'],
	logo: 'https://www.nasdaq.com/favicon.ico',
},
{
	brand: 'NEE',
	website: ['https://www.nexteraenergy.com'],
	logo: 'https://www.nexteraenergy.com/etc.clientlibs/nee-mobility/clientlibs/clientlib-base/resources/favicon.ico',
},
{
	brand: 'NEM',
	website: ['https://www.newmont.com'],
	logo: '',
},
{
	brand: 'NET',
	website: ['https://www.cloudflare.com'],
	logo: 'https://www.cloudflare.com/favicon.ico',
},
{
	brand: 'NFLX',
	website: ['https://www.netflix.com'],
	logo: 'https://assets.nflxext.com/us/ffe/siteui/common/icons/nficon2016.png',
},
{
	brand: 'NGG',
	website: ['https://www.nationalgrid.com'],
	logo: '',
},
{
	brand: 'NKE',
	website: ['https://www.nike.com'],
	logo: 'https://www.nike.com/android-icon-192x192.png',
},
{
	brand: 'NOC',
	website: ['https://www.northropgrumman.com'],
	logo: 'https://www.northropgrumman.com/favicon.ico',
},
{
	brand: 'NOW',
	website: ['https://www.servicenow.com'],
	logo: 'https://www.servicenow.com/favicon.ico',
},
{
	brand: 'NSC',
	website: ['https://www.norfolksouthern.com'],
	logo: 'https://www.norfolksouthern.com/etc.clientlibs/nscorp/clientlibs/clientlib-site/resources/images/favicons/favicon.png',
},
{
	brand: 'NTES',
	website: ['https://netease.gcs-web.com'],
	logo: '',
},
{
	brand: 'NTR',
	website: ['https://www.nutrien.com'],
	logo: 'https://www.nutrien.com/themes/custom/nutrien/favicon.png',
},
{
	brand: 'NU',
	website: ['https://www.nubank.com.br'],
	logo: 'https://nubank.com.br/favicons/apple-touch-icon.png',
},
{
	brand: 'NUE',
	website: ['https://www.nucor.com'],
	logo: 'https://nucor.com/favicon.ico',
},
{
	brand: 'NVDA',
	website: ['https://www.nvidia.com'],
	logo: 'https://www.nvidia.com/favicon.ico',
},
{
	brand: 'NVO',
	website: ['https://www.novonordisk.com'],
	logo: 'https://www.novonordisk.com/etc.clientlibs/nncorp/components/structure/page/clientlib/resources/favicon.ico',
},
{
	brand: 'NVS',
	website: ['https://www.novartis.com'],
	logo: 'https://www.novartis.com/themes/custom/polaris/favicon.ico',
},
{
	brand: 'NVZMY',
	website: ['https://www.novonesis.com'],
	logo: 'https://www.novonesis.com/favicon.ico',
},
{
	brand: 'NWG',
	website: ['https://www.natwestgroup.com'],
	logo: 'https://www.natwestgroup.com/apps/settings/wcm/designs/responsive_champion/clientlibs/resources/responsive_natwest_group_favicon.ico',
},
{
	brand: 'NXPI',
	website: ['https://www.nxp.com'],
	logo: 'https://www.nxp.com/favicon.ico',
},
{
	brand: 'O',
	website: ['https://www.realtyincome.com'],
	logo: 'https://www.realtyincome.com/sites/realty-income/files/realty-income/favicon.png',
},
{
	brand: 'ODFL',
	website: ['https://www.odfl.com'],
	logo: 'https://www.odfl.com/favicon.ico',
},
{
	brand: 'OEZVY',
	website: ['https://www.verbund.com'],
	logo: 'https://www.verbund.com/static/dist/images/favicons/apple-touch-icon.png',
},
{
	brand: 'OKE',
	website: ['https://www.oneok.com/'],
	logo: 'https://www.oneok.com/favicon.ico',
},
{
	brand: 'OLCLY',
	website: ['https://www.olc.co.jp'],
	logo: '',
},
{
	brand: 'ON',
	website: ['https://www.onsemi.com'],
	logo: 'https://www.onsemi.com/images/logo/onsemi_favicon_32.png',
},
{
	brand: 'ORAN',
	website: ['https://www.orange.com'],
	logo: 'https://www.orange.com/themes/theme_boosted/Small_Logo_RGB.png',
},
{
	brand: 'ORCL',
	website: ['https://www.oracle.com'],
	logo: 'https://www.oracle.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'ORLY',
	website: ['https://www.oreillyauto.com'],
	logo: 'https://www.oreillyauto.com/img/apple-touch-icon.png',
},
{
	brand: 'OTIS',
	website: ['https://www.otis.com'],
	logo: 'https://www.otis.com/favicon.ico',
},
{
	brand: 'OWL',
	website: ['https://www.blueowl.com'],
	logo: 'https://www.blueowl.com/themes/custom/blueowl/favicon.ico',
},
{
	brand: 'OXY',
	website: ['https://www.oxy.com'],
	logo: 'https://www.oxy.com/apple-touch-icon.png',
},
{
	brand: 'PANW',
	website: ['https://www.paloaltonetworks.com'],
	logo: 'https://www.paloaltonetworks.com/etc/clientlibs/pan/img/favicons2020/favicon-194x194.png',
},
{
	brand: 'PAYX',
	website: ['https://www.paychex.com'],
	logo: 'https://www.paychex.com/themes/custom/paychex2/favicon/paychex-favicon-196x196.png',
},
{
	brand: 'PBR',
	website: ['https://petrobras.com.br'],
	logo: 'https://petrobras.com.br/o/tema-site-externo-petrobras/images/favicon.ico',
},
{
	brand: 'PCAR',
	website: ['https://www.paccar.com'],
	logo: 'https://www.paccar.com/favicon.ico',
},
{
	brand: 'PCG',
	website: ['https://www.pgecorp.com'],
	logo: 'https://www.pgecorp.com/favicon.ico',
},
{
	brand: 'PDD',
	website: ['https://www.pddholdings.com'],
	logo: '',
},
{
	brand: 'PEG',
	website: ['https://investor.pseg.com/home/default.aspx'],
	logo: '',
},
{
	brand: 'PEP',
	website: ['https://www.pepsico.com'],
	logo: 'https://www.pepsico.com/assets/images/icons/favicons/favicon-228.png',
},
{
	brand: 'PFE',
	website: ['https://www.pfizer.com'],
	logo: 'https://www.pfizer.com/profiles/pfecpfizercomus_profile/themes/pfcorporate_helix/favicons/apple-touch-icon.png',
},
{
	brand: 'PG',
	website: ['https://www.pginvestor.com'],
	logo: 'https://s1.q4cdn.com/695946674/files/favicon.ico',
},
{
	brand: 'PGR',
	website: ['https://www.progressive.com'],
	logo: '',
},
{
	brand: 'PH',
	website: ['https://www.phstock.com'],
	logo: 'https://d1io3yog0oux5.cloudfront.net/_23d1076cb6940c03b0f3c147bcb421ba/parker/files/theme/images/favicons/apple-touch-icon.png',
},
{
	brand: 'PHG',
	website: ['https://www.philips.com'],
	logo: 'https://www.philips.ie/c-etc/philips/clientlibs/foundation-base/clientlibs-css/img/favicon/favicon-192x192.png',
},
{
	brand: 'PHM',
	website: ['https://www.pultegroup.com'],
	logo: '',
},
{
	brand: 'PLD',
	website: ['https://www.prologis.com'],
	logo: 'https://www.prologis.com/themes/custom/prologis_beta/images/favicons/apple-touch-icon.png',
},
{
	brand: 'PLTR',
	website: ['https://www.palantir.com'],
	logo: '',
},
{
	brand: 'PM',
	website: ['https://www.pmi.com'],
	logo: 'https://www.pmi.com/favicon.ico',
},
{
	brand: 'PNC',
	website: ['https://www.pnc.com'],
	logo: '',
},
{
	brand: 'PPERY',
	website: ['https://bankmandiri.co.id'],
	logo: '',
},
{
	brand: 'PPG',
	website: ['https://www.ppg.com'],
	logo: '',
},
{
	brand: 'PRU',
	website: ['https://www.prudential.com'],
	logo: 'https://www.prudential.com/etc.clientlibs/pru/clientlibs/clientlib-site/resources/images/favicon.ico',
},
{
	brand: 'PSA',
	website: ['https://www.publicstorage.com'],
	logo: 'https://www.publicstorage.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'PSX',
	website: ['https://www.phillips66.com'],
	logo: 'https://www.phillips66.com/wp-content/themes/phillips66/dist/images/psx-android-chrome-192x192.png',
},
{
	brand: 'PTCAY',
	website: ['https://www.chandra-asri.com'],
	logo: '',
},
{
	brand: 'PUBGY',
	website: ['https://www.publicisgroupe.com'],
	logo: 'https://www.publicisgroupe.com/favicon-194x194.png',
},
{
	brand: 'PUK',
	website: ['https://www.prudentialplc.com'],
	logo: 'https://www.prudentialplc.com/favicon.ico',
},
{
	brand: 'PWR',
	website: ['https://www.quantaservices.com'],
	logo: 'https://www.quantaservices.com/images/favicon.png',
},
{
	brand: 'PXD',
	website: ['https://www.pxd.com'],
	logo: 'https://www.pxd.com/apple-touch-icon.png',
},
{
	brand: 'PYPL',
	website: ['https://www.paypal.com'],
	logo: 'https://www.paypalobjects.com/webstatic/icon/pp258.png',
},
{
	brand: 'QCOM',
	website: ['https://www.qualcomm.com'],
	logo: 'https://www.qualcomm.com/etc.clientlibs/qcomm-martech/clientlibs/clientlib-react/resources/favicon.ico',
},
{
	brand: 'QSR',
	website: ['https://www.rbi.com'],
	logo: '',
},
{
	brand: 'RACE',
	website: ['https://www.ferrari.com'],
	logo: 'https://www.ferrari.com/static/favicon_256x256.png',
},
{
	brand: 'RCL',
	website: ['https://www.rclinvestor.com'],
	logo: 'https://www.rclinvestor.com/content/themes/base/img/fav/favicon.ico',
},
{
	brand: 'REGN',
	website: ['https://www.regeneron.com'],
	logo: 'https://www.regeneron.com/favicon.ico',
},
{
	brand: 'RELX',
	website: ['https://www.relx.com'],
	logo: 'https://www.relx.com/~/media/ABAA6DC2543F4E04A368E4F5BD0E3A21.ico',
},
{
	brand: 'RIO',
	website: ['https://www.riotinto.com'],
	logo: 'https://www.riotinto.com/-/media/project/riotinto/shared/favicon-32x32-new.png?rev=ca7eb2791f3040399e6332e59d664bbd',
},
{
	brand: 'RJF',
	website: ['https://www.raymondjames.com'],
	logo: '',
},
{
	brand: 'RKT',
	website: ['https://www.rocketcompanies.com'],
	logo: 'https://www.rocketcompanies.com/wp-content/uploads/2020/07/cropped-rocket-companies-favicon-192x192.png',
},
{
	brand: 'RMD',
	website: ['https://www.resmed.com'],
	logo: 'https://www.resmed.co.uk/wp-content/themes/resmed-emea-master/imgs/favicons/android-icon-192x192.png',
},
{
	brand: 'ROK',
	website: ['https://www.rockwellautomation.com'],
	logo: 'https://www.rockwellautomation.com/etc.clientlibs/rockwell-aem-base/clientlibs/clientlib-base/resources/favicons/apple-touch-icon.png',
},
{
	brand: 'ROP',
	website: ['https://www.ropertech.com'],
	logo: 'https://www.ropertech.com/favicon-192.png',
},
{
	brand: 'ROST',
	website: ['https://www.rossstores.com'],
	logo: 'https://www.rossstores.com/wp-content/uploads/2021/03/cropped-cropped-ross_favicon_master-192x192.png',
},
{
	brand: 'RSG',
	website: ['https://www.republicservices.com'],
	logo: 'https://www.republicservices.com/favicon.ico',
},
{
	brand: 'RTNTF',
	website: ['https://www.riotinto.com'],
	logo: 'https://www.riotinto.com/-/media/project/riotinto/shared/favicon-32x32-new.png?rev=ca7eb2791f3040399e6332e59d664bbd',
},
{
	brand: 'RTX',
	website: ['https://www.rtx.com'],
	logo: 'https://www.rtx.com/-/media/themes/raytheon-technologies/rtx-shared/rtx-theme/images/rtx-balck-favicon.png?rev=3da99ab1a2a54dca92c98a2ede690339',
},
{
	brand: 'RY',
	website: ['https://www.rbc.com'],
	logo: 'https://www.rbc.com/favicon.ico',
},
{
	brand: 'RYAAY',
	website: ['https://www.ryanair.com'],
	logo: 'http://assets.ryanair.com/resources/ui/ryanair3.0/favicons/apple-touch-icon-152x152.png',
},
{
	brand: 'SAN',
	website: ['https://www.santander.com'],
	logo: 'https://www.santander.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'SAP',
	website: ['https://www.sap.com'],
	logo: '',
},
{
	brand: 'SBUX',
	website: ['https://www.starbucks.com'],
	logo: 'https://www.starbucks.com/next_static/icons/pwa-icon-192.png',
},
{
	brand: 'SCCO',
	website: ['https://www.southerncoppercorp.com'],
	logo: 'https://southerncoppercorp.com/wp-content/uploads/2022/05/favicon.png',
},
{
	brand: 'SCHW',
	website: ['https://www.schwab.com'],
	logo: 'https://www.schwab.com/favicon.ico',
},
{
	brand: 'SE',
	website: ['https://www.sea.com'],
	logo: 'https://www.sea.com/favicon.ico',
},
{
	brand: 'SHECY',
	website: ['https://www.shinetsu.co.jp'],
	logo: 'https://www.shinetsu.co.jp/favicon.ico',
},
{
	brand: 'SHEL',
	website: ['https://www.shell.com'],
	logo: 'https://www.shell.com/etc.clientlibs/amidala/clientlibs/theme-base/resources/favicon/apple-touch-icon.png',
},
{
	brand: 'SHOP',
	website: ['https://www.shopify.com'],
	logo: 'https://cdn.shopify.com/shopifycloud/web/assets/v1/favicon-default-6cbad9de243dbae3.ico',
},
{
	brand: 'SHW',
	website: ['https://www.sherwin-williams.com'],
	logo: 'https://www.sherwin-williams.com/apple-touch-icon.png',
},
{
	brand: 'SLB',
	website: ['https://www.slb.com'],
	logo: 'https://www.slb.com/favicon.ico',
},
{
	brand: 'SLF',
	website: ['https://www.sunlife.com'],
	logo: 'https://www.sunlife.com/favicon.ico',
},
{
	brand: 'SMCI',
	website: ['https://www.supermicro.com'],
	logo: 'https://www.supermicro.com/favicon.ico',
},
{
	brand: 'SMFG',
	website: ['https://www.smfg.co.jp'],
	logo: 'https://www.smfg.co.jp/assets/img/apple-touch-icon.png',
},
{
	brand: 'SNAP',
	website: ['https://www.snap.com'],
	logo: '',
},
{
	brand: 'SNOW',
	website: ['https://www.snowflake.com'],
	logo: 'https://www.snowflake.com/etc.clientlibs/snowflake-site/clientlibs/clientlib-react/resources/apple-touch-icon.png?v=2',
},
{
	brand: 'SNPS',
	website: ['https://www.synopsys.com'],
	logo: 'https://www.synopsys.com/etc.clientlibs/synopsys/clientlibs/synopsys-pagelibs/resources/images/favicon.ico',
},
{
	brand: 'SNY',
	website: ['https://www.sanofi.com'],
	logo: 'https://www.sanofi.com/icons/favicon-180x180.png',
},
{
	brand: 'SO',
	website: ['https://www.southerncompany.com'],
	logo: 'https://www.southerncompany.com/etc.clientlibs/global/clientlibs/clientlib-site/resources/icon-192x192.png',
},
{
	brand: 'SONY',
	website: ['https://www.sony.com'],
	logo: 'https://www.sony.com/en/favicon.ico',
},
{
	brand: 'SPG',
	website: ['https://www.simon.com'],
	logo: 'https://cdn-fsly.yottaa.net/555a305b2bb0ac71b9002d22/4a6e24e07e33013b5e040ead9ecbf798.yottaa.net/v~4b.30b.0.0/SimonWebAssets_Images/favicons/favicon-194x194.png?v=A0v9Gll6ga&yocs=D_NA_',
},
{
	brand: 'SPGI',
	website: ['https://www.spglobal.com'],
	logo: 'https://www.spglobal.com/_assets/images/icons/spg_favicon_wht_192x192.png',
},
{
	brand: 'SPOT',
	website: ['https://www.spotify.com'],
	logo: 'https://www.spotify.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'SQ',
	website: ['https://www.block.xyz'],
	logo: 'https://block.xyz/apple-touch-icon-precomposed.png',
},
{
	brand: 'SRE',
	website: ['https://www.sempra.com'],
	logo: '',
},
{
	brand: 'STM',
	website: ['https://www.st.com'],
	logo: 'https://www.st.com/etc/clientlibs/st-site/media/app/images/favicon.ico',
},
{
	brand: 'STZ',
	website: ['https://www.cbrands.com'],
	logo: 'https://cdn.shopify.com/s/files/1/0614/2797/4201/files/logo-48.png',
},
{
	brand: 'SU',
	website: ['https://www.suncor.com'],
	logo: 'https://www.suncor.com/favicon.ico',
},
{
	brand: 'SVNDY',
	website: ['https://www.7andi.com'],
	logo: 'https://www.7andi.com/library/app-files/img/symbol/apple-touch-icon.png',
},
{
	brand: 'SYK',
	website: ['https://www.stryker.com'],
	logo: 'https://www.stryker.com/content/dam/stryker/global/icons/apple-touch-icon.png',
},
{
	brand: 'SYY',
	website: ['https://www.sysco.com'],
	logo: 'https://www.sysco.com/favicon.ico',
},
{
	brand: 'T',
	website: ['https://www.att.com'],
	logo: 'https://www.att.com/favicon.ico',
},
{
	brand: 'TAK',
	website: ['https://www.takeda.com'],
	logo: 'https://www.takeda.com/apple-touch-icon.png',
},
{
	brand: 'TD',
	website: ['https://www.td.com'],
	logo: 'https://www.td.com/etc.clientlibs/tdsite/clientlibs/clientlib-wealth/resources/images/wealth-favicon.ico',
},
{
	brand: 'TDG',
	website: ['https://www.transdigm.com'],
	logo: 'https://www.transdigm.com/wp-content/uploads/2017/05/cropped-transdigm-favicon-192x192.png',
},
{
	brand: 'TEAM',
	website: ['https://www.atlassian.com'],
	logo: 'https://www.atlassian.com/apple-touch-icon.png',
},
{
	brand: 'TECK',
	website: ['https://www.teck.com'],
	logo: 'https://www.teck.com/media/teck-favicon-64x64.png',
},
{
	brand: 'TEF',
	website: ['https://www.telefonica.com'],
	logo: 'https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJwYXRoIjoidGVsZWZvbmljYVwvYWNjb3VudHNcLzJkXC80MDAwNDcwXC9mYXZpY29uc1wvYzRcLzFcLzQ5MTliZTlhNDQxYWE1N2VlZjRlY2M4MmM1M2ZhNjVmLTE2MTkxNzczMzUucG5nIn0:telefonica:f1WW8WEiNAXO7Ttc8TjZokUZ2LVwPdFEo3CSJEVgV-A?width=196&rect=0,0,800,800&reference_width=800&format=png',
},
{
	brand: 'TEL',
	website: ['https://www.te.com'],
	logo: 'https://www.te.com/favicon.ico',
},
{
	brand: 'TFC',
	website: ['https://www.truist.com'],
	logo: 'https://www.truist.com/favicon.ico',
},
{
	brand: 'TGT',
	website: ['https://corporate.target.com'],
	logo: 'https://corporate.target.com/assets/images/apple-touch-icon.png',
},
{
	brand: 'TJX',
	website: ['https://www.tjx.com'],
	logo: 'https://www.tjx.com/ResourcePackages/Bootstrap5/assets/dist/images/favicon.ico',
},
{
	brand: 'TLGPY',
	website: ['https://www.telstra.com.au'],
	logo: 'https://www.telstra.com.au/etc/designs/telstra/images/t-logo-theme-brand-refresh-apple-touch.png',
},
{
	brand: 'TM',
	website: ['https://global.toyota/en'],
	logo: 'https://global.toyota/apple-touch-icon-precomposed.png',
},
{
	brand: 'TMO',
	website: ['https://www.thermofisher.com'],
	logo: 'https://www.thermofisher.com/favicon.ico',
},
{
	brand: 'TMUS',
	website: ['https://www.t-mobile.com'],
	logo: 'https://www.t-mobile.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'TOELY',
	website: ['https://www.tel.com'],
	logo: 'https://www.tel.com/irta3a00000001ah-img/irta3a00000001at.png',
},
{
	brand: 'TRGP',
	website: ['https://www.targaresources.com'],
	logo: 'https://www.targaresources.com/sites/g/files/knoqqb91441/files/favicon_0.ico',
},
{
	brand: 'TRI',
	website: ['https://www.thomsonreuters.com'],
	logo: 'https://www.thomsonreuters.com/favicon.ico',
},
{
	brand: 'TROW',
	website: ['https://www.troweprice.com'],
	logo: 'https://www.troweprice.com/content/dam/public/enterprise/apple-touch-icon.png',
},
{
	brand: 'TRP',
	website: ['https://www.tcenergy.com'],
	logo: 'https://www.tcenergy.com/siteassets/brand/tce-apple-touch-icon.png',
},
{
	brand: 'TRV',
	website: ['https://www.travelers.com'],
	logo: 'https://www.travelers.com/favicon.ico',
},
{
	brand: 'TSCO',
	website: ['https://www.tractorsupply.com'],
	logo: 'https://www.tractorsupply.com/etc.clientlibs/tractorsupplycompanycloudservice/clientlibs/clientlib-site/resources/images/tsc_icon_favicon.ico',
},
{
	brand: 'TSLA',
	website: ['https://www.tesla.com'],
	logo: '',
},
{
	brand: 'TSM',
	website: ['https://www.tsmc.com'],
	logo: '',
},
{
	brand: 'TT',
	website: ['https://www.tranetechnologies.com'],
	logo: 'https://www.tranetechnologies.com/content/dam/cs-corporate/images/logos/favicon.png',
},
{
	brand: 'TTD',
	website: ['https://www.thetradedesk.com'],
	logo: 'https://www.thetradedesk.com/apple-touch-icon.png',
},
{
	brand: 'TTE',
	website: ['https://www.totalenergies.com'],
	logo: 'https://totalenergies.com/themes/custom/totalenergies_com/dist/img/favicon.png',
},
{
	brand: 'TTWO',
	website: ['https://www.take2games.com'],
	logo: 'https://images.ctfassets.net/wn7ipiv9ue5v/1DxCIrUbhWllnMBuMyCje5/546552d68f1324b54b89d733f40f2d8f/favicon.png',
},
{
	brand: 'TU',
	website: ['https://www.telus.com'],
	logo: 'https://www.telus.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'TXN',
	website: ['https://www.ti.com'],
	logo: 'https://www.ti.com/favicon.ico',
},
{
	brand: 'UBER',
	website: ['https://www.uber.com'],
	logo: 'https://www.uber.com/apple-touch-icon.png',
},
{
	brand: 'UBS',
	website: ['https://www.ubs.com'],
	logo: 'https://www.ubs.com/favicon.ico',
},
{
	brand: 'UL',
	website: ['https://www.unilever.com'],
	logo: 'https://www.unilever.com/apple-touch-icon.png',
},
{
	brand: 'UNH',
	website: ['https://www.unitedhealthgroup.com'],
	logo: 'https://www.unitedhealthgroup.com/etc.clientlibs/uhg/clientlibs/clientlib-site/resources/images/favicon.ico',
},
{
	brand: 'UNP',
	website: ['https://www.up.com'],
	logo: 'https://www.up.com/graphics/uprr/favicon.png',
},
{
	brand: 'UPS',
	website: ['https://www.ups.com'],
	logo: '',
},
{
	brand: 'URI',
	website: ['https://www.unitedrentals.com'],
	logo: '',
},
{
	brand: 'USB',
	website: ['https://www.usbank.com'],
	logo: 'https://www.usbank.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'V',
	website: ['https://www.visa.com'],
	logo: '',
},
{
	brand: 'VALE',
	website: ['https://www.vale.com'],
	logo: 'https://vale.com/documents/44618/57981/fav_128px/550e3a42-f32c-7356-de52-41320d095395',
},
{
	brand: 'VEEV',
	website: ['https://www.veeva.com'],
	logo: 'https://www.veeva.com/favicon.ico',
},
{
	brand: 'VICI',
	website: ['https://www.viciproperties.com'],
	logo: 'https://viciproperties.com/wp-content/uploads/2022/01/cropped-Vici-favicon-192x192.png',
},
{
	brand: 'VLO',
	website: ['https://www.valero.com'],
	logo: 'https://www.valero.com/themes/custom/valero/favicon.ico',
},
{
	brand: 'VLTO',
	website: ['https://www.veralto.com'],
	logo: 'https://www.veralto.com/wp-content/uploads/2023/07/cropped-veralto_favicon_300x300-192x192.png',
},
{
	brand: 'VMC',
	website: ['https://www.vulcanmaterials.com'],
	logo: 'https://www.vulcanmaterials.com/favicon.ico',
},
{
	brand: 'VRSK',
	website: ['https://www.verisk.com'],
	logo: 'https://www.verisk.com/4a7dd9/contentassets/814105e257b64e3ca01c36bfdfaa155c/verisk_favicon_4.ico',
},
{
	brand: 'VRT',
	website: ['https://www.vertiv.com'],
	logo: 'https://www.vertiv.com/apple-touch-icon-precomposed.png',
},
{
	brand: 'VRTX',
	website: ['https://www.vrtx.com'],
	logo: 'https://www.vrtx.com/themes/custom/hogarth4/favicon.ico',
},
{
	brand: 'VST',
	website: ['https://vistracorp.com'],
	logo: 'https://vistracorp.com/wp-content/uploads/2016/10/cropped-cropped-site-icon-1-192x192.png',
},
{
	brand: 'VZ',
	website: ['https://www.verizon.com'],
	logo: '',
},
{
	brand: 'WAB',
	website: ['https://www.wabteccorp.com'],
	logo: 'https://www.wabteccorp.com/themes/custom/wabtec/favicon.ico',
},
{
	brand: 'WCN',
	website: ['https://www.wasteconnections.com'],
	logo: 'https://assets-global.website-files.com/64b7636f12949eb74511aeb3/64dbaaf308976295c88ba20e_WasteConnections_webclip.png',
},
{
	brand: 'WDAY',
	website: ['https://www.workday.com'],
	logo: 'https://www.workday.com/favicon.ico',
},
{
	brand: 'WDC',
	website: ['https://www.westerndigital.com'],
	logo: 'https://www.westerndigital.com/content/dam/store/en-us/portal-assets/logos/shop-fav-icon.ico',
},
{
	brand: 'WDS',
	website: ['https://www.woodside.com'],
	logo: 'https://www.woodside.com/assets/static/images/icons/apple-touch-icon.png',
},
{
	brand: 'WEC',
	website: ['https://www.wecenergygroup.com'],
	logo: 'https://www.wecenergygroup.com/favicon.ico',
},
{
	brand: 'WELL',
	website: ['https://www.welltower.com'],
	logo: 'https://www.welltower.com/favicon.ico',
},
{
	brand: 'WFC',
	website: ['https://www.wellsfargo.com'],
	logo: 'https://www17.wellsfargomedia.com/assets/images/icons/icon-hires_192x192.png',
},
{
	brand: 'WIT',
	website: ['https://www.wipro.com'],
	logo: 'https://www.wipro.com/content/dam/nexus/en/favicon.png',
},
{
	brand: 'WM',
	website: ['https://www.wm.com'],
	logo: '',
},
{
	brand: 'WMB',
	website: ['https://www.williams.com'],
	logo: 'https://www.williams.com/wp-content/uploads/sites/8/2019/11/williams_logo_favicon.jpg?w=108',
},
{
	brand: 'WMT',
	website: ['https://corporate.walmart.com'],
	logo: 'https://corporate.walmart.com/etc/clientlibs/adp/base/images/favicon/apple-icon.png',
},
{
	brand: 'WPM',
	website: ['https://www.wheatonpm.com'],
	logo: '',
},
{
	brand: 'WST',
	website: ['https://www.westpharma.com'],
	logo: 'https://www.westpharma.com/assets/WestPharma/images/apple-touch-icon.png',
},
{
	brand: 'WTKWY',
	website: ['https://wolterskluwer.com'],
	logo: 'https://www.wolterskluwer.com/-/media/project/wolterskluwer/oneweb/www/images/logo/favicon.png?rev=939e1a5cbc2d4c8ca7d3dfda28250865',
},
{
	brand: 'WTW',
	website: ['https://www.wtwco.com'],
	logo: 'https://www.wtwco.com/favicon.ico',
},
{
	brand: 'XEL',
	website: ['https://www.xcelenergy.com'],
	logo: 'https://my.xcelenergy.com/favicon.ico',
},
{
	brand: 'XOM',
	website: ['https://corporate.exxonmobil.com'],
	logo: 'https://corporate.exxonmobil.com/-/media/global/icons/xx-favicon.ico',
},
{
	brand: 'XYL',
	website: ['https://www.xylem.com'],
	logo: 'https://www.xylem.com/favicon.ico',
},
{
	brand: 'YUM',
	website: ['https://www.yum.com'],
	logo: 'https://www.yum.com/wps/contenthandler/yumbrands/!ut/p/digest!pGIUH9dmhbnp5XX9PeE6EQ/dav/fs-type1/themes/yumdotcomtheme2019/images/favicon.ico',
},
{
	brand: 'ZBH',
	website: ['https://www.zimmerbiomet.com'],
	logo: 'https://www.zimmerbiomet.com/etc.clientlibs/zb-corporate/clientlibs/clientlib-base/resources/img/favicon.ico',
},
{
	brand: 'ZS',
	website: ['https://www.zscaler.com'],
	logo: 'https://www.zscaler.com/favicons/apple-touch-icon.png',
},
{
	brand: 'ZTS',
	website: ['https://www.zoetis.com'],
	logo: 'https://www.zoetis.com/global-assets/favicons/apple-touch-icon-152x152.png',
},
];
const LogoData = {LOGOS};
export default LogoData;
