import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// SPAI components
import SpaiTypographyWithGlossary from "components/SpaiTypographyWithGlossary";

// Page components
import ContactUsDialog from "components/ContactUsDialog";

function PricingCard({ title, price, subPrice, specifications, dialogMessage, action }) {
  const [openContactUs, setOpenContactUs] = useState(false);

  const renderSpecifications = specifications.map(({ label, includes }) => (
    <ArgonBox key={label} display="flex" alignItems="center" p={1}>
      <ArgonBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="1.5rem"
        height="1.5rem"
        borderRadius="50%"
        shadow="md"
        bgColor={includes ? "success" : "secondary"}
        variant="gradient"
        mr={2}
      >
        <ArgonTypography variant="button" color="white" sx={{ lineHeight: 0 }}>
          <Icon sx={{ fontWeight: "bold" }}>{includes ? "done" : "remove"}</Icon>
        </ArgonTypography>
      </ArgonBox>
      <SpaiTypographyWithGlossary
        text={label}
        variant='subtitle2'
        color='text'
        fontWeight='regular'
      />
    </ArgonBox>
  ));

  return (
    <Card sx={{ minWidth: '255px' }}>
      <ArgonBox pt={3} pb={1} px={2} textAlign="center">
        <ArgonTypography variant="body2" color="dark" textTransform="uppercase" fontWeight="medium">
          {title}
        </ArgonTypography>
        <ArgonBox my={1}>
          <ArgonTypography variant="h1">
            <ArgonTypography display="inline" variant="body2">
              {price.currency} 
            </ArgonTypography>
            {price.value}
          </ArgonTypography>
          <ArgonTypography variant="body2" color="text">
            {subPrice}
          </ArgonTypography>
        </ArgonBox>
        <Divider />
      </ArgonBox>
      <ArgonBox pb={3} px={3}>
        {renderSpecifications}
        {action ? (
					<ArgonBox mt={3}>
            <ArgonButton color={action.color} fullWidth
              onClick={() => setOpenContactUs(true)}
            >
              {action.label}&nbsp;
              {action.showArrow ? 
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              : null}
            </ArgonButton>
          </ArgonBox>
        ) : (null)
        }
      </ArgonBox>
      <ContactUsDialog open={openContactUs} setOpen={setOpenContactUs} message={dialogMessage} />
    </Card>
  );
}


PricingCard.defaultProps = {
  subPrice: "",
  dialogMessage: ""
};

// Typechecking props for the PricingCard
PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  subPrice: PropTypes.string,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  dialogMessage: PropTypes.string,
  action: PropTypes.shape({
    // type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    showArrow: PropTypes.bool,
  }),
};

export default PricingCard;
