// react-routers components
import React, { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Shared config & utils
import * as Constants from "config/Constants";  
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

// SPAI components
import SpaiSliderBarWithLabel from "components/SpaiSliderBarWithLabel";
import SpaiRangeSliderBar from "components/SpaiRangeSliderBar";

function scaleValue(value) {
  return 2 ** value;
}

function FilterOption({title, showProgress, action}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const [marketCapRange, setMarketCapRange] = React.useState([
    Constants.FILTER_MARKET_CAP_SLIDER_MIN,
    Constants.FILTER_MARKET_CAP_SLIDER_MAX
  ]);
  const [liquidityWeight, setLiquidityWeight] = useState(
    Constants.FILTER_LIQUIDITY_WEIGHT
  );
  const [profitabilityWeight, setProfitabilityWeight] = useState(
    Constants.FILTER_PROFITABILITY_WEIGHT
  );
  const [valuationWeight, setValuationWeight] = useState(
    Constants.FILTER_VALUATION_WEIGHT
  );
  const [dividendsWeight, setDividendsWeight] = useState(
    Constants.FILTER_DIVIDENDS_WEIGHT
  );
  const [growthWeight, setGrowthWeight] = useState(
    Constants.FILTER_GROWTH_WEIGHT
  );
  const [cashFlowWeight, setCashFlowWeight] = useState(
    Constants.FILTER_CASH_FLOW_WEIGHT
  );
  const [volatilityWeight, setVolatilityWeight] = useState(
    Constants.FILTER_VOLATILITY_WEIGHT
  );
  const [macroeconomyWeight, setMacroeconomyWeight] = useState(
    Constants.FILTER_MACROECONOMY_WEIGHT
  );
  const [show, setShow] = useState(false);

  function handleApply() {
    action({
      marketCapRange: [
        scaleValue(marketCapRange[0]), scaleValue(marketCapRange[1])
      ],
      liquidityWeight: liquidityWeight/10,
      profitabilityWeight: profitabilityWeight/10,
      valuationWeight: valuationWeight/10,
      dividendsWeight: dividendsWeight/10,
      growthWeight: growthWeight/10,
      cashFlowWeight: cashFlowWeight/10,
      volatilityWeight: volatilityWeight/10,
      macroeconomyWeight: macroeconomyWeight/10
    });
    setShow(false);
  }

  return (
    <Card>
      <Stack py={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          {showProgress ? <CircularProgress size={22} color='dark' /> : (
            <Stack
              direction="row"
              spacing={2}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {show ? (
                <ArgonButton
                  color="info"
                  size="small" 
                  onClick={() => handleApply()}
                >
                  Apply
                </ArgonButton>
              ) : null}
              <ArgonButton
                color="info"
                size="small" 
                circular 
                iconOnly
                onClick={() => setShow(!show)}
              >
                {show ? <Icon>arrow_upward</Icon> : <Icon>arrow_downward</Icon>}
              </ArgonButton>
            </Stack>
          )}
        </ArgonBox>
      </Stack>
      {show ? (
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={0} pb={2} px={2}
        >
          <Grid container columnSpacing={4}>
            <Grid item xs={12} sm={4}>
              <Grid item>
                <ArgonBox pt={1}>
                  <ArgonTypography variant="caption" fontWeight="bold" color="text">
                    Market Cap Range: &nbsp;
                  </ArgonTypography>
                  <ArgonTypography variant="caption" fontWeight="medium" color="dark">
                    &nbsp; &nbsp; {Utils.formatNumber(scaleValue(marketCapRange[0]))}
                    &nbsp; &#x21e8; &nbsp;
                    {Utils.formatNumber(scaleValue(marketCapRange[1]))}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox pt={1} px={2} borderRadius="md"
                  sx={({ palette: { grey, background } }) => ({
                    backgroundColor: darkMode ? background.default : grey[100],
                  })}
                >
                  <SpaiRangeSliderBar
                    min={Constants.FILTER_MARKET_CAP_SLIDER_MIN}
                    max={Constants.FILTER_MARKET_CAP_SLIDER_MAX}
                    from={Constants.FILTER_MARKET_CAP_SLIDER_MIN}
                    to={Constants.FILTER_MARKET_CAP_SLIDER_MAX}
                    setRange={setMarketCapRange}
                  />
                </ArgonBox>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid item>
                <SpaiSliderBarWithLabel
                  label="Liquidity (LIQU) Weighting Factor"
                  value={liquidityWeight}
                  setValue={setLiquidityWeight}
                />
              </Grid>
              <Grid item>
                <SpaiSliderBarWithLabel
                  label="Profitability (PROF) Weighting Factor"
                  value={profitabilityWeight}
                  setValue={setProfitabilityWeight}
                />
              </Grid>
              <Grid item>
                <SpaiSliderBarWithLabel
                  label="Growth (GROW) Weighting Factor"
                  value={growthWeight}
                  setValue={setGrowthWeight}
                />
              </Grid>
              <Grid item>
                <SpaiSliderBarWithLabel
                  label="Valuation (VALU) Weighting Factor"
                  value={valuationWeight}
                  setValue={setValuationWeight}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid item>
                <SpaiSliderBarWithLabel
                  label="Cash Flow (CFLOW) Weighting Factor"
                  value={cashFlowWeight}
                  setValue={setCashFlowWeight}
                />
              </Grid>
              <Grid item>
                <SpaiSliderBarWithLabel
                  label="Dividends (DIVI) Weighting Factor"
                  value={dividendsWeight}
                  setValue={setDividendsWeight}
                />
              </Grid>
              
              <Grid item>
                <SpaiSliderBarWithLabel
                  label="Risk & Volativity (RISK) Weighting Factor"
                  value={volatilityWeight}
                  setValue={setVolatilityWeight}
                />
              </Grid>
              <Grid item>
                <SpaiSliderBarWithLabel
                  label="Macroeconomy (MECO) Weighting Factor"
                  value={macroeconomyWeight}
                  setValue={setMacroeconomyWeight}
                />
              </Grid>
            </Grid>
          </Grid>
        </ArgonBox>
      ) : null }
    </Card>
  );
}

FilterOption.defaultProps = {
  showProgress: false,
};

FilterOption.propTypes = {
  title: PropTypes.string.isRequired,
  showProgress: PropTypes.bool,
  action: PropTypes.func.isRequired
};

export default FilterOption;
