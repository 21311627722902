import { Interweave } from "interweave";

// @mui material components
import Icon from "@mui/material/Icon";

// Shared config & utils
import * as Constants from "config/Constants";

// Data
import LogoData from "assets/data/LogoData";

export function addNotification(notifications, newItem, maxSize){
  notifications.unshift(newItem);
  if (notifications.length > maxSize) {
    notifications.pop();
  }
}

export function createNewNotification(message) {
  return {
    'title': message,
    'date': new Date().toLocaleString(),
    'icon': (
      <Icon 
        fontSize="small" 
        sx={{ 
          color: ({ palette: { white } }) => white.main 
        }}
      >
        compare
      </Icon>
    )
  };
}

export function parseFinancialSummary(data_raw) {
  if (data_raw === undefined) {
    return {};
  }

  // Make sure the string content can be parsed back to object.
  const stringifyContent = (str) => {
    const obj = {};
    const pairs = str.split(', ');
    pairs.forEach((pair) => {
      const [key, value] = pair.split(': ');
      obj[key.replaceAll("'", "").replaceAll("{", "")] = `${value.replaceAll("'", "").replaceAll("}", "")}`;
    });
    return JSON.stringify(obj);
  };

  try {
    var data_filtered = stringifyContent(data_raw.data);
    var data_obj = JSON.parse(data_filtered);
    data_obj['summary'] = data_raw.summary;
    return data_obj;
  } catch (err) {
    console.error('parseFinancialSummary failed', err);
    return {};
  }
}

export const getGrowthPctColor = (value) => {
  if (value > 0) {
    return "goodScore";
  } else if (value < 0) {
    return "error";
  }
  return "text";
}

export const formatNumber = (num, prefix = "$", suffix="") => {
  try {
    const to_fixed = (Number.isInteger(num) && num < 1000) ? 0 : 2; 
    if (num >= 1000000000000) {
      return prefix + `${(num / 1000000000000).toFixed(to_fixed)}T`;
    } else if (num >= 1000000000) {
      return prefix + `${(num / 1000000000).toFixed(to_fixed)}B`;
    } else if (num >= 1000000) {
      return prefix + `${(num / 1000000).toFixed(to_fixed)}M`;
    } else if (num >= 1000) {
      return prefix + `${(num / 1000).toFixed(to_fixed)}K`;
    } else {
      return prefix + num.toFixed(to_fixed) + suffix;
    }
  } catch (err) {
    return num;
  }
};

export const formatNumberLonger = (num, prefix = "$", suffix="") => {
  try {
    const to_fixed = Number.isInteger(num) ? 0 : 2;
    if (num >= 1000000000000) {
      return prefix + `${(num / 1000000000000).toFixed(2)} Trillions`;
    } else if (num >= 1000000000) {
      return prefix + `${(num / 1000000000).toFixed(2)} Billions`;
    } else if (num >= 1000000) {
      return prefix + `${(num / 1000000).toFixed(2)} Millions`;
    } else if (num >= 1000) {
      return prefix + `${(num / 1000).toFixed(2)} Thousands`;
    } else {
      return prefix + num.toFixed(to_fixed) + suffix;
    }
  } catch (err) {
    return num;
  }
};

export const formatNumberInt = (num, prefix = "$", suffix="") => {
  if (num === undefined) {
    return "";
  }
  return prefix + num.toFixed(0) + suffix;
};

export function convertShortNumber(input) {
  input = input.replaceAll('/quarter', '');
  // Define a regular expression to match the short number notation
  const shortNumberRegex = /(\d+(?:\.\d+)?)((?:K|M|B|T|%))/g;

  // Replace the short number notation with the original form
  return input.replace(shortNumberRegex, (match, value, unit) => {
    let multiplier = 1;
    switch (unit) {
      case 'K':
        multiplier = 1000;
        break;
      case 'M':
        multiplier = 1000000;
        break;
      case 'B':
        multiplier = 1000000000;
        break;
      case 'T':
        multiplier = 1000000000000;
        break;
      case '%':
        multiplier = 0.01;
        break;
    }
    return `${(parseFloat(value) * multiplier).toFixed(2)}`;
  });
}

export const cutLongText = (text, maxLength) => {
  if (text.length < maxLength) {
    return text;
  } else {
    return text.slice(0, maxLength) + '...';
  }
}

export const renderMultilineText = (html_text) => {
  return (
    <Interweave content={preprocessHTML(html_text)} />
  )
}

export const getKeyValue = (obj, key) => {
  if (obj && obj.hasOwnProperty(key)) {
    return obj[key];
  }
  const keyShort = key.replaceAll(' Quarterly', '')
                      .replaceAll(' Yearly', '')
                      .replaceAll(' Quarter', '')
                      .replaceAll(' Year', '')
                      .replaceAll(' QoQ', '')
                      .replaceAll(' YoY', '');;
  if (obj && obj.hasOwnProperty(keyShort)) {
    return obj[keyShort];
  }
  return Constants.INVALID_PROPERTY_VALUE;
}

export const convertToTitleCase = (str) => {
  return str.replace(/([A-Z])/g, ' $1')
            .replace(/^./, function(match) {
              return match.toUpperCase();
            });
}

export const convertArrayToString = (arr) => {
  return arr.join(', ');
}

export const convertKeyToString = (str) => {
  return str.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
}

export function extractNumbers(str) {
  // Define a regular expression to match only numbers
  const numRegex = /\d+(?:\.\d+)?/g;

  // Use the match() method to find all the numbers in the string
  const numbers = str.match(numRegex);

  // Join the extracted numbers into a single string
  return numbers ? numbers.join('') : '';
}

export const formatTimestamp = (timestamp) => {
  const now = new Date();
  const postDate = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - postDate.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return `Just now`;
  } else if (diffInSeconds < 3600) {
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const diffInHours = Math.floor(diffInSeconds / 3600);
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
  } else {
    const diffInDays = Math.floor(diffInSeconds / 86400);
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
  }
}

export const renderAddCompareButton = (ticker, compareTickers) => {
  if (compareTickers.has(ticker)) {
    return <Icon>check</Icon>;
  } else {
    return <Icon>add</Icon>;
  }
}

export const preprocessHTML = (html_text) => {
  return html_text.replaceAll('\n<p>', '\n<br><p>')
                  .replaceAll('\n<h2>', '\n<br><h2>')
                  .replaceAll('\n<h3>', '<br><h3>')
                  .replaceAll('<ol>', '')
                  .replaceAll('</ol>', '')
                  .replaceAll('- ', ' ')
}

export function isLink(str) {
  // Regular expression to match URLs
  const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

  return urlRegex.test(str);
}

// Function to save a Set to local storage
export function saveSetToLocalStorage(key, set) {
  localStorage.setItem(key, JSON.stringify(Array.from(set)));
}

// Function to read a Set from local storage
export function readSetFromLocalStorage(key) {
  const storedData = localStorage.getItem(key);
  return storedData ? new Set(JSON.parse(storedData)) : new Set();
}

export function extractQandA(text) {
  if (!text) {
    return [];
  }
  var result = [];
  try {
    var sections = text.split("\n==================TITLE: ");
    for (var i = 0; i < sections.length; i++) {
      if (sections[i].length === 0) {
        continue;
      }
      var bp = sections[i].indexOf("\n");
      if (bp > 0 && bp < sections[i].length) {
        result.push({
          title: sections[i].substring(0, bp).replace('TITLE: ', ''),
          detail: sections[i].substring(bp + 1, sections[i].length)
                             .replaceAll('h1>', 'h3>')
                             .replaceAll('h2>', 'h3>')
        });
      }
    }
  } catch (err) {
    console.error('extractQandA failed', err);
  }
  return result;
}

export const getLogoForTicker = (ticker) => {
  let logos = LogoData.LOGOS;
  for (var i = 0; i < logos.length; i++) {
    if (logos[i].brand === ticker) {
      return logos[i].logo;
    }
  }
  return null;
};

export function getCompanyName(ticker, company_name_and_tickers) {
  for (const company of company_name_and_tickers) {
    if (company.ticker === ticker) {
      return company.name;
    }
  }
  return '';
}

export const separateDataArrayIntoPosAndNev = (dataArray) => {
  const positiveData = [];
  const negativeData = [];

  dataArray.forEach((item) => {
    if (item.data >= 0) {
      positiveData.push({ sector: item.sector, data: item.data });
      negativeData.push({ sector: item.sector, data: 0 });
    } else {
      positiveData.push({ sector: item.sector, data: 0 });
      negativeData.push({ sector: item.sector, data: item.data });
    }
  });

  return [ positiveData, negativeData ];
};

export const validateName = (name) => {
  // Define the regular expression pattern for a valid name
  const namePattern = /^[a-zA-Z\s-]+$/;

  //test whether input is valid
  return (namePattern.test(name));
};

export const validateEmail = (email) => {
  // Define the regular expression pattern for a valid email address
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //test whether input is valid
  return (emailPattern.test(email));
};


export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const aaa = date.toLocaleDateString('en-US', {
    month: '2-digit',
    // day: '2-digit',
    year: 'numeric' 
  });
  return aaa;
}

export function getScoreLabelAndColor(value) {
  var color = "";
  var label = "";
  if (value !== undefined) {
    if (value >= 90) {
      color = "greatScore";
      label = "Booming";
    } else if (value >= 70) {
      color = "goodScore";
      label = "Positive";
    } else if (value >= 50) {
      color = "averageScore";
      label = "Neutral";
    } else if (value >= 30) {
      color = "badScore";
      label = "Negative";
    } else{
      color = "veryBadScore";
      label = "Dire";
    }
  }
  return [color, label];
}

export function getPeriodRange(periods) {
  let range = ['', ''];
  if (periods) {
    if (periods.length > 0) {
      range[0] = periods.at(0);
      if (periods.length > 1) {
        range[1] = periods.at(-1);
      }
    }
  }
  return range;
}

export const loadAccessKeyFromLocalStorage = () => {
  const storedData = localStorage.getItem('access_key');
  if (storedData) {
    try {
      const parsedData = JSON.parse(storedData);
      return parsedData;
    } catch (error) {
      console.error('Error loading data from localStorage:', error);
    }
  }
  return null;
};

export const saveAccessKeyToLocalStorage = (data) => {
  try {
    localStorage.setItem('access_key', JSON.stringify(data));
  } catch (error) {
    console.error('Error saving data to localStorage:', error);
  }
};

export const deleteAccessKeyFromLocalStorage = () => {
  try {
    localStorage.removeItem('access_key');
  } catch (error) {
    console.error('Error deleting data from localStorage:', error);
  }
};