import { useEffect, useState } from "react";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI examples
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useArgonController } from "context";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Page components
import FilterOption from "layouts/ranking/components/FilterOption";
import RankFinance from "layouts/ranking/components/RankFinance";
import RankInvestment from "layouts/ranking/components/RankInvestment";
import ScorePeer from "layouts/company/components/ScorePeer";

function Ranking() {
  const [controller,] = useArgonController();
  const { accessInfo, metaStats } = controller;
  // const [loadingRanking, setLoadingRanking] = useState(true);
  const [loadingRanking, setLoadingRanking] = useState(false);
  const [rankingOptions, setRankingOptions] = useState(
    Constants.RANKING_FILTER_DEFAULT
  );
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [openPeers, setOpenPeers] = useState(false);

  const handleApplyFilter = (options) => {
    setRankingOptions(options);
    setLoadingRanking(true);
  };

  useEffect(() => {
    if (accessInfo.api_key.length > 0) {
      setLoadingRanking(true);
    }
  }, [ accessInfo ]);

  return (
    <DashboardLayout bgColor="success">
      <DashboardNavbar />
      <ArgonBox py={1}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Company"
              count={metaStats['tickers'].length}
              icon={{ color: "info", component: <i className="ni ni-building" /> }}
              percentage={{ color: "info", count: "", text: "publicly traded companies" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Industry"
              count={metaStats['industries'].length}
              icon={{ color: "error", component: <i className="ni ni-world" /> }}
              percentage={{
                color: "success",
                count: "",
                text: "across " + metaStats['sectors'].length + " different sectors"
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Stock Exchanges"
              count={metaStats['exchanges'].length}
              icon={{ color: "success", component: <i className="ni ni-money-coins" /> }}
              percentage={{
                color: "info",
                count: "",
                text: Utils.convertArrayToString(metaStats['exchanges'])
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="Macroeconomy"
              count={metaStats['economic_indicators']}
              icon={{ color: "warning", component: <i className="ni ni-planet" /> }}
              percentage={{ color: "success", count: "", text: "key economic indicators" }}
            />
          </Grid>
        </Grid>
        <ArgonBox mb={3}>
          <Grid container display='flex' justifyContent='center' alignContent='center'>
            <Grid item xs={12} sm={8} lg={4}>
              <Card p={1}>
              <AppBar position="static">
                <Tabs
                  orientation='horizontal'
                  value={tabValue}
                  onChange={(e, newValue) => {
                    if (newValue === 0) {
                      setLoadingRanking(true);
                    }
                    setTabValue(newValue);
                  }}
                >
                  <Tab label="By Financial Health" />
                  <Tab label="By Opportunity" />
                </Tabs>
              </AppBar>
              </Card>
            </Grid>
          </Grid>
        </ArgonBox>
        {tabValue === 0 ? (
          <div>
            <ArgonBox mb={2}>
              <FilterOption
                title="Ranking Options"
                action={handleApplyFilter}
              />
            </ArgonBox>
            <ArgonBox mb={3}>
              <RankFinance
                loadingRanking={loadingRanking}
                setLoadingRanking={setLoadingRanking}
                rankingOptions={rankingOptions}
                setSelectedIndustry={setSelectedIndustry}
                setOpenPeers={setOpenPeers}
                hideName={Constants.RANKING_HIDE_NAME}
                apiKey={accessInfo.api_key}
              />
            </ArgonBox>
          </div>
        ) : null }
        {tabValue === 1 ? (
          <ArgonBox mb={3}>
            <RankInvestment
              title="Investment Strategy Ranking"
              hideName={Constants.RANKING_HIDE_NAME}
              apiKey={accessInfo.api_key}
            />
          </ArgonBox>
        ) : null }
      </ArgonBox>
      <Backdrop
        id='backdrop-score-peer'
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openPeers}
        onClick={(e) => {
          if (e.target.id === 'backdrop-score-peer') {
            setOpenPeers(false);
          }
        }}
      >
        <ScorePeer
          title={selectedIndustry}
          industryName={selectedIndustry}
          loadingCompanies={openPeers}
          hideName={Constants.RANKING_HIDE_NAME}
        />
      </Backdrop>
      <Footer />
    </DashboardLayout>
  );
}

export default Ranking;
