// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, EffectCube, Pagination } from 'swiper/modules';

// import './styles.css';
import 'assets/css/swiper_cube.css';

// Images
import score1 from "assets/images/score1.jpg";
import score2 from "assets/images/score2.jpg";
import score3 from "assets/images/score3.jpg";
import score4 from "assets/images/score4.jpg";
import score5 from "assets/images/score5.jpg";
import score6 from "assets/images/score6.jpg";

const SwiperScore = () => {
  // const progressCircle = useRef(null);

  // const onAutoplayTimeLeft = (s, time, progress) => {
  //   progressCircle.current.style.setProperty('--progress', 1 - progress);
  // };

  return (
    <Swiper
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      speed={600}
      effect={'cube'}
      grabCursor={true}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }}
      pagination={true}
      modules={[Autoplay, EffectCube, Pagination]}
      className="swiper-cube"
      // onAutoplayTimeLeft={onAutoplayTimeLeft}
      loop={true}
      style={{
        width: '380px',
        height: '490px',
      }}
    >
      <SwiperSlide className="swiper-slide-cube">
        <div>
          <img src={score1} alt="na" width="100%" loading="lazy" />
        </div>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide-cube">
        <div>
          <img src={score2} alt="na" width="100%" loading="lazy" />
        </div>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide-cube">
        <div>
          <img src={score3} alt="na" width="100%" loading="lazy" />
        </div>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide-cube">
        <div>
          <img src={score4} alt="na" width="100%" loading="lazy" />
        </div>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide-cube">
        <div>
          <img src={score5} alt="na" width="100%" loading="lazy" />
        </div>
      </SwiperSlide>
      <SwiperSlide className="swiper-slide-cube">
        <div>
          <img src={score6} alt="na" width="100%" loading="lazy" />
        </div>
      </SwiperSlide>
      {/* <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
      </div> */}
    </Swiper>
  );
};

export default SwiperScore;