// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Shared config & utils
import * as Utils from "utils/utils";

// SPAI components
import SpaiMultiTypographyWithLabel from "components/SpaiMultiTypographyWithLabel";

function ProfileOverview({title, overview, history, showProgress}) {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (overview === undefined || Object.keys(overview).length == 0) {
      return;
    }
    let total_employees = overview['total_employees_worldwide'];
    if (total_employees === 0) {
      total_employees = '';
    }

    const founders =
      overview['founders'] ?
      overview['founders'].replaceAll('[', '')
                          .replaceAll(']', '')
                          .replaceAll("'", '')
      : '';
    const company_infos = {
      'Name': overview['name'],
      'Founded Year': overview['founding_year'],
      'Founders': founders,
      'Total Employees': Utils.formatNumber(total_employees, '', ''),
      'Industry': overview['industry'],
      'CIK': overview['cik'],
      'Stock': overview['exchange'] + ':' + overview['ticker'],
    }
  
    setLabels(Object.keys(company_infos));
    setValues(Object.values(company_infos));

  }, [ overview, history ]);
  
  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <ArgonBox key={label} display="flex" py={1} pr={2} alignItems='center'>
      <ArgonTypography variant="button" fontWeight="bold" color="text">
        {label}: &nbsp;
      </ArgonTypography>
      {label !== 'Stock' ? (
        <ArgonTypography variant="button" fontWeight="regular" color="dark">
          &nbsp;{values[key]}
        </ArgonTypography>
      ) : (
        <Link
          variant="button" 
          fontWeight="regular"
          color="dark" 
          href={"https://finance.yahoo.com/quote/" + overview['ticker']}
          underline="hover"
        >
          &nbsp;{values[key]}
        </Link>
      )}
    </ArgonBox>
  ));

  return (
    <Card>
      <Stack pt={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          {showProgress ? <CircularProgress size={26} color='info' /> : null}
        </ArgonBox>
      </Stack>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2} pb={2} px={2}
      >
        <Stack>
          {renderItems}
          <SpaiMultiTypographyWithLabel 
            label='History & Milestones'
            contents={[
              history.company_history,
              history.company_history_milestones,
              history.mergers_and_acquisitions
            ]}
            showContent={true}
          />
        </Stack>
      </ArgonBox>
    </Card>
  );
}

ProfileOverview.defaultProps = {
  showProgress: false,
};

ProfileOverview.propTypes = {
  title: PropTypes.string.isRequired,
  overview: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showProgress: PropTypes.bool,
};

export default ProfileOverview;
