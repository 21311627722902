import { useState, useEffect } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";

// Argon Dashboard 2 MUI base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
// import burceMars from "assets/images/bruce-mars.jpg";

function Header({ logo, name, headquartersLocation, selectedTab, handleTabChanged, tabLabels, tabIconNames, showProgress }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => handleTabChanged(newValue);

  return (
    <ArgonBox position="relative">
      <ArgonBox height="30px" />
      <Card
        sx={{
          py: 2,
          px: 2,
          boxShadow: ({ boxShadows: { md } }) => md,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <ArgonAvatar
              src={logo ? logo : ""}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <ArgonBox height="100%" mt={0.5} lineHeight={1}>
              <ArgonTypography variant="h5" fontWeight="medium" pb={0.5}>
                {name}
              </ArgonTypography>
              <ArgonTypography variant="button" color="text" fontWeight="medium">
                {headquartersLocation}
              </ArgonTypography>
            </ArgonBox>
          </Grid>
          {showProgress ? <Grid item><CircularProgress /></Grid> : null}
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs 
                orientation={tabsOrientation}
                value={selectedTab}
                onChange={handleSetTabValue}
              >
                {tabLabels.map((label, index) =>
                  <Tab
                    key={index}
                    label={label}
                    icon={
                      // <Icon>
                      //   {tabIconNames.length === tabLabels.length ? "ni " + tabIconNames[index] : ""}
                      // </Icon>
                      tabIconNames[index]
                    }
                  />
                )}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </ArgonBox>
  );
}

// Setting default values for the props
Header.defaultProps = {
  city: "",
  state: "",
  country: "",
  showProgress: false,
  tabIconNames: [],
};

// Typechecking props for the ReportListInfo
Header.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  headquartersLocation: PropTypes.string,
  showProgress: PropTypes.bool,
  selectedTab: PropTypes.number,
  tabLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabIconNames: PropTypes.arrayOf(PropTypes.string),
  handleTabChanged: PropTypes.func.isRequired,
};

export default Header;
