// react-routers components
import React from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import CircularProgress from '@mui/material/CircularProgress';
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Shared config & utils
import * as Utils from "utils/utils";


function SpaiCircularScoreWithLabel({value, title, size, showValue}) {
  const [final_color, label] = Utils.getScoreLabelAndColor(value);
  const progress_props = {
    value: Number(value),
    size: size,
    color: final_color
  }

  if (progress_props.value === 0) {
    return (
      <ArgonBox sx={{ position: 'relative', display: 'inline-flex' }}>
        <ArgonBox
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </ArgonBox>
    )
  }

  return (
    <ArgonBox sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...progress_props}/>
      <ArgonBox
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack spacing={0}>
          {showValue ? (
            <ArgonTypography variant="button" color={final_color}>
              {Number(value)/10}
            </ArgonTypography>
          ) : (
            <div>
              <ArgonTypography variant="body2" color="text" textAlign="center">
                {title}
              </ArgonTypography>
              <ArgonTypography variant="h3" color={final_color}>
                {label}
              </ArgonTypography>
            </div>
          )}          
        </Stack>
      </ArgonBox>
    </ArgonBox>
  );
}

SpaiCircularScoreWithLabel.defaultProps = {
  color: "goodScore",
  showValue: false,
  title: "Overall",
};

SpaiCircularScoreWithLabel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number.isRequired,
  showValue: PropTypes.bool,
};

export default SpaiCircularScoreWithLabel;
