// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI examples
import Table from "examples/Tables/Table";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI context
import { useArgonController } from "context";

function FinancialData({title, data, showProgress}) {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [controller, _] = useArgonController();
  const { darkMode, glossary } = controller;
  const [description, setDescription] = useState('');

  useEffect(() => {
    setColumns([
      { name: "metric", align: "left" },
      { name: "value", align: "center" },
    ]);
    
    const keys = Object.keys(data);
    const values = Object.values(data);
    let list = [];
    for (var i = 0; i < keys.length; i++) {
      list.push({
        metric: keys[i],
        value: values[i]
      })
    }
    setRows(
      list.map((item) => {
        return renderRow(item.metric, item.value);
      })
    );
  }, [data]);

  function renderRow(metric, value) {
    let colorValue = 'dark';
    if (value.includes('%')) {
      colorValue = value[0] === '-' ? 'error' : 'goodScore';
    }
    return {
      metric: (
        <ArgonButton
          variant="text" color="secondary" size="small"
          onClick={(e) => {
            const label = Utils.convertKeyToString(metric);
            setDescription(label + ': ' + Utils.getKeyValue(glossary, label));
          }}
        >
          {Utils.convertKeyToString(metric)}
        </ArgonButton>
      ),
      value: (
        <ArgonTypography
          variant="caption"
          color={colorValue}
          fontWeight="regular"
        >
          {value}
        </ArgonTypography>
      ),
    }
  }

  return (
    <Card style={{ maxHeight: '90%', maxWidth: '80%', overflow: 'auto' }}>
      <Stack pt={2} px={2} direction="row">
        <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </ArgonTypography>
      </Stack>
      {description.length > 0 ? (
        <ArgonBox p={1} style={{ maxWidth: '400px' }}>
          <ArgonBox p={1} borderRadius="md"
            sx={({ palette: { grey, background } }) => ({
              backgroundColor: darkMode ? background.default : grey[100],
            })}
          >
            <ArgonTypography variant="caption" color="secondary" display="block">
              &#x27A3;  {description}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      ) : null }
      <ArgonBox p={1.5}>
        <Table columns={columns} rows={rows} maxHeight={Constants.BLACKDROP_MAX_HEIGHT} />
      </ArgonBox>
    </Card>
  );
}

FinancialData.defaultProps = {
  showProgress: false,
};

FinancialData.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  showProgress: PropTypes.bool,
};

export default FinancialData;
