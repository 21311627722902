// react-routers components
import React from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import CardActions from '@mui/material/CardActions';

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

// SPAI components
import SpaiLinearProgress from "components/SpaiLinearProgressWithLabel";

// Shared config & utils
import * as Utils from "utils/utils";

function SpaiLinearProgressWithLabelAndContent({value, title, content, 
                                                showContent, actionLabel, action}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [color, label] = Utils.getScoreLabelAndColor(value);

  return (
    <div>
      <SpaiLinearProgress
        value={value}
        color={color}
        variant="gradient"
        label={(title && title.length === 0) ? label : title}
      />
      {showContent ? (
        <ArgonBox p={1} borderRadius="md"
          sx={({ palette: { grey, background } }) => ({
            backgroundColor: darkMode ? background.default : grey[100],
          })}
        >
          <ArgonTypography variant="caption" color="dark" display="block">
            {content}
          </ArgonTypography>
          {action ? (
            <CardActions>
              <ArgonButton
                variant="text"
                size="small"
                color="info"
                py={0}
                sx={{ ml:'auto', mr:'2px' }}
                onClick={() => action()}
              >
                {actionLabel}
              </ArgonButton>
            </CardActions>
          ) : null }
        </ArgonBox>
      ) : null}
    </div>
  );
}

SpaiLinearProgressWithLabelAndContent.defaultProps = {
  content: "",
  showContent: false,
};

// Typechecking props for the ReportListInfo
SpaiLinearProgressWithLabelAndContent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number.isRequired,
  content: PropTypes.string,
  showContent: PropTypes.bool,
  actionLabel: PropTypes.string,
  action: PropTypes.func,
};

export default SpaiLinearProgressWithLabelAndContent;