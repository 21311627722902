import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Stack from "@mui/material/Stack";

// Custom styles for ArgonProgress
import ArgonProgressRoot from "components/ArgonProgress/ArgonProgressRoot";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// SPAI components
import SpaiTypographyWithGlossary from "components/SpaiTypographyWithGlossary";

const SpaiLinearProgress = forwardRef(({ variant, color, value, label, ...rest }, ref) => (
  <>
    {label.length > 0 && (
      // <div>
      <Stack direction="row" spacing={2}>
        <SpaiTypographyWithGlossary text={label} />
        <ArgonBox sx={{ pl:'auto', pr:'5px' }}>
          <ArgonTypography variant="button" color={color}>
            {value/10}/10
          </ArgonTypography>
        </ArgonBox>
      {/* </div> */}
      </Stack>
    )}
    <ArgonProgressRoot
      {...rest}
      ref={ref}
      variant="determinate"
      value={value}
      ownerState={{ color, value, variant }}
    />
  </>
));

// Setting default values for the props of SpaiLinearProgress
SpaiLinearProgress.defaultProps = {
  variant: "contained",
  color: "info",
  value: 0,
  label: "",
  content: "",
};

// Typechecking props for the SpaiLinearProgress
SpaiLinearProgress.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "greatScore",
    "goodScore",
    "averageScore",
    "badScore",
    "veryBadScore",
  ]),
  value: PropTypes.number,
  label: PropTypes.string,
  content: PropTypes.string,
};

export default SpaiLinearProgress;
