// react-routers components
import React from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// SPAI components
import SpaiMultiTypographyWithLabel from "components/SpaiMultiTypographyWithLabel";

function ProfileCompetition({title, competition, innovation, showProgress}) {
  return (
    <Card>
      <Stack pt={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          {showProgress ? <CircularProgress size={26} color='info' /> : null}
        </ArgonBox>
      </Stack>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2} pb={2} px={2}
      >
        <Grid item sm container rowSpacing={2}>
          <Grid item xs={12} container direction="column">
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Market Position'
                contents={[
                  competition.market_position,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Competitive Advantages'
                contents={[
                  competition.competitive_advantages,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Innovation Strategy'
                contents={[
                  innovation.innovation_strategy,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='R&D and Technology'
                contents={[
                  innovation.R_and_D_and_technology,
                ]}
                showContent={true}
              />
            </Grid>
            <Grid item xs>
              <SpaiMultiTypographyWithLabel 
                label='Emerging Tech Adoption'
                contents={[
                  innovation.emerging_tech_adoption,
                ]}
                showContent={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

ProfileCompetition.defaultProps = {
  showProgress: false,
};

ProfileCompetition.propTypes = {
  title: PropTypes.string.isRequired,
  competition: PropTypes.object.isRequired,
  innovation: PropTypes.object.isRequired,
  showProgress: PropTypes.bool,
};

export default ProfileCompetition;
