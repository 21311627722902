// react-routers components
import React, { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Backdrop from '@mui/material/Backdrop';
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

// Shared config & utils
import * as Constants from "config/Constants"

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

// SPAI components
import SpaiCircularScoreWithLabel from "components/SpaiCircularScoreWithLabel";
import SpaiLinearProgressWithLabelAndContent from "components/SpaiLinearProgressWithLabelAndContent";
import useWindowDimensions from "utils/useWindowDimensions";

// Page components
import FinancialData from "../FinancialData";

function ScoreFinance({title, analysis, finance, showProgress}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [showAnalysis, setShowAnalysis] = useState(false);
  const { _, width } = useWindowDimensions();
  const [openData, setOpenData] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selectedDataTitle, setSelectedDataTitle] = useState("");

  let overviewStackSize = Constants.CIRCULAR_SCORE_WITH_LABEL_SIZE;
  if (width < 700) {
    overviewStackSize = width * 0.8;
  }

  const isValid = (Object.keys(analysis).length > 0);
  if (!isValid) {
    return (
      <Card>
        <Stack pt={2} px={2} direction="row">
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {title}
            </ArgonTypography>
          </ArgonBox>
        </Stack>
        <ArgonBox px={2} py={1}>
          <ArgonTypography variant="button" fontWeight='regular' color="text">
            {Constants.NO_AUTHORIZED_MESSAGE}
          </ArgonTypography>
        </ArgonBox>
      </Card>
    )
  }

  return (
    <Card>
      <Stack pt={2} px={2} direction="row">
        <ArgonBox display="flex" flexDirection="column">
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
          <ArgonTypography variant="caption" color="secondary">
            Most recent quarter: {analysis['published_at']}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          {showProgress ? <CircularProgress size={26} color='info' /> : (
            <Tooltip title="Show Details">
              <ArgonButton
                color="info"
                size="small" 
                circular 
                iconOnly 
                onClick={() => {
                  setShowAnalysis(!showAnalysis);
                }}
              >
                {showAnalysis ? <Icon>arrow_upward</Icon> : <Icon>arrow_downward</Icon>}
              </ArgonButton>
            </Tooltip>
          )}
        </ArgonBox>
      </Stack>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2} pb={2} px={2}
      >
        <Grid container columnSpacing={2}>
          <Grid item alignItems="center" display="flex" justifyContent="center" alignContent="center">
            <Stack width={overviewStackSize}>
              <ArgonBox display="flex" justifyContent="center">
                <SpaiCircularScoreWithLabel
                  value={analysis.financial_overall_score * 10}
                  size={Constants.CIRCULAR_SCORE_SIZE}
                  action={() => setSelectedData(finance[''])}
                />
              </ArgonBox>
              <ArgonBox pt={1}>
                {showAnalysis ? (
                  <ArgonBox p={1}>
                    <ArgonBox p={1} borderRadius="md"
                      sx={({ palette: { grey, background } }) => ({
                        backgroundColor: darkMode ? background.default : grey[100],
                      })}
                    >
                      <ArgonTypography variant="caption" color="dark" display="block">
                        &#x27A3;  {analysis.finance_conclusion}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                ) : null}
              </ArgonBox>
            </Stack>
          </Grid>
          <Grid item sm container columnSpacing={4}>
            <Grid item md={12} lg={6} container direction="column">
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={analysis.liquidity_and_solvency_score * 10}
                  title="Liquidity & Solvency"
                  content={analysis.liquidity_and_solvency_analysis}
                  showContent={showAnalysis}
                  actionLabel="Show Data"
                  action={() => {
                    setOpenData(true);
                    setSelectedData(finance.liquidity_and_solvency);
                    setSelectedDataTitle('Liquidity & Solvency');
                  }}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={analysis.profitability_score * 10}
                  title='Profitability'
                  content={analysis.profitability_analysis}
                  showContent={showAnalysis}
                  actionLabel="Show Data"
                  action={() => {
                    setOpenData(true);
                    setSelectedData(finance.profitability);
                    setSelectedDataTitle('Profitability & Efficiency');
                  }}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={analysis.growth_and_innovation_score * 10}
                  title='Growth & Innovation'
                  content={analysis.growth_and_innovation_analysis}
                  showContent={showAnalysis}
                  actionLabel="Show Data"
                  action={() => {
                    setOpenData(true);
                    setSelectedData(finance.growth_and_innovation);
                    setSelectedDataTitle('Growth & Innovation');
                  }}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={analysis.valuation_score * 10}
                  title='Valuation'
                  content={analysis.valuation_analysis}
                  showContent={showAnalysis}
                  actionLabel="Show Data"
                  action={() => {
                    setOpenData(true);
                    setSelectedData(finance.valuation);
                    setSelectedDataTitle('Valuation');
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={12} lg={6} container direction="column">
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={analysis.cash_flow_and_efficiency_score * 10}
                  title='Cash Flow & Efficiency'
                  content={analysis.cash_flow_and_efficiency_analysis}
                  showContent={showAnalysis}
                  actionLabel="Show Data"
                  action={() => {
                    setOpenData(true);
                    setSelectedData(finance.cash_flow_and_efficiency);
                    setSelectedDataTitle('Cash Flow & Efficiency');
                  }}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={analysis.dividends_and_payouts_score * 10}
                  title='Dividends & Payouts'
                  content={analysis.dividends_and_payouts_analysis}
                  showContent={showAnalysis}
                  actionLabel="Show Data"
                  action={() => {
                    setOpenData(true);
                    setSelectedData(finance.dividends_and_payouts);
                    setSelectedDataTitle('Dividends & Payouts');
                  }}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={analysis.risk_and_volatility_score * 10}
                  title='Risk & Volatility'
                  content={analysis.risk_and_volatility_analysis}
                  showContent={showAnalysis}
                  actionLabel="Show Data"
                  action={() => {
                    setOpenData(true);
                    setSelectedData(finance.risk_and_volatility);
                    setSelectedDataTitle('Risk & Volatility');
                  }}
                />
              </Grid>
              <Grid item xs>
                <SpaiLinearProgressWithLabelAndContent 
                  value={analysis.macroeconomic_data_impact_score * 10}
                  title='Macroeconomic Trends'
                  content={analysis.macroeconomic_data_impact_analysis}
                  showContent={showAnalysis}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
      <Backdrop
        id='backdrop-financial-data'
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openData}
        onClick={(e) => {
          if (e.target.id === 'backdrop-financial-data'){
            setOpenData(false);
          }
        }}
      >
        <FinancialData title={selectedDataTitle} data={selectedData} />
      </Backdrop>
    </Card>
  );
}

ScoreFinance.defaultProps = {
  showProgress: false,
};

ScoreFinance.propTypes = {
  title: PropTypes.string.isRequired,
  finance: PropTypes.object,
  analysis: PropTypes.object,
  showProgress: PropTypes.bool,
};

export default ScoreFinance;
