// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI examples
import ArgonButton from "components/ArgonButton";
import Table from "examples/Tables/Table";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import { useArgonController } from "context";

// Spai
import SpaiCircularScoreWithLabel from "components/SpaiCircularScoreWithLabel";

let resultAPICallMap = new Map();

function ScorePeer({title, peers, industryName, loadingCompanies, subTitle, hideName}) {
  const [controller, _] = useArgonController();
  const { accessInfo } = controller;
  const [errorMsg, setErrorMsg] = useState('');
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [showProgress, setShowProgress] = useState(true);

  useEffect(() => {
    if (!loadingCompanies) {
      return;
    }

    if (!accessInfo || Object.keys(accessInfo).length === 0) {
      return;
    }

    const api_cmd = Constants.API_IP_ADDRESS
      + 'get_largest_companies_in_industry?'
      + 'industry_name=' + industryName.replaceAll('&', '[AND]')
      + '&top_n=' + Constants.INDUSTRY_PEER_TOP_N
      + '&api-key=' + accessInfo.api_key;
    const fetchData = async () => {
      fetch(api_cmd)
      .then((response) => response.json())
      .then((data) => {
        handleDataFetched(data);
        resultAPICallMap.set(api_cmd, data);
      })
      .catch((err) => {
        console.log(err.message);
        setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
      });
    };

    const handleDataFetched = (data) => {
      if (Array.isArray(data)) {
        setupTable(data);
      } else {
        setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
      }
    };

    if (resultAPICallMap.has(api_cmd)) {
      handleDataFetched(resultAPICallMap.get(api_cmd));
    } else {
      fetchData();
    }
  }, [ loadingCompanies, industryName ]);

  function setupTable(data) {
    if (data === undefined) {
      return;
    }

    setShowProgress(false);

    setColumns([
      { name: "company", align: "left" },
      { name: "market_cap", align: "center" },
      { name: "score", align: "center" },
    ]);
    
    setRows(
      data.map((item) => {
        return renderRow(item.ticker,
                         item.short_name,
                         item.market_cap,
                         item.financial_overall_score);
      })
    );
  }

  useEffect(() => {
    setupTable(peers);
  }, [peers]);

  function renderRow(ticker, name, marketCap, score) {
    return {
      company: (
        hideName ? (
          <ArgonBox
            sx={{
              width: 100,
              height: 25,
              borderRadius: 2,
              bgcolor: '#cccccc',
            }}
          />
        ) : (
          <ArgonButton
            variant="text" color="secondary" size="small"
            href={"/company/" + ticker}
          >
            {Utils.cutLongText(name, Constants.SCORE_PEER_MAX_LENGTH_COMPANY_NAME)}
          </ArgonButton>
        )
      ),
      market_cap: (
        hideName ? (
          <ArgonBox
            sx={{
              width: 70,
              height: 25,
              borderRadius: 2,
              bgcolor: '#cccccc',
            }}
          />
        ) : (
          <ArgonTypography
            variant="caption"
            color="dark"
            fontWeight="regular"
          >
            {Utils.formatNumberLonger(marketCap, '$', '')}
          </ArgonTypography>
        )
      ),
      score: (
        <SpaiCircularScoreWithLabel
          value={Utils.formatNumberInt(score * 10, "", "")}
          size={Constants.CIRCULAR_SCORE_SIZE_SMALL}
          showValue={true}
        />
      ),
    }
  }

  return (
    <Card style={{ overflow: 'auto' }}>
      <Stack pt={2} px={2} pb={1} direction="row">
        <ArgonBox display="flex" flexDirection="column">
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
          <ArgonTypography variant="caption" color="secondary">
            {subTitle}
          </ArgonTypography>
          {industryName.length > 0 ? (
            <ArgonBox py={1} style={{ maxWidth: '370px' }}>
              <ArgonTypography variant="caption" fontWeight="regular" color="dark" display="block">
                Top {industryName} Companies by Market Capitalization
              </ArgonTypography>
            </ArgonBox>
          ) : null }
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          { showProgress ? <CircularProgress size={26} color='info' /> : null }
        </ArgonBox>
      </Stack>
      <ArgonBox
        pt={1.5}
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} maxHeight={Constants.BLACKDROP_MAX_HEIGHT} />
      </ArgonBox>
    </Card>
  );
}

ScorePeer.defaultProps = {
  industryName: "",
  loadingCompanies: false,
  subTitle: "",
  hideName: false
};

ScorePeer.propTypes = {
  peers: PropTypes.array,
  title: PropTypes.string.isRequired,
  industryName: PropTypes.string,
  subTitle: PropTypes.string,
  loadingCompanies: PropTypes.bool,
  hideName: PropTypes.bool,
};

export default ScorePeer;
