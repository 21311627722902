/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 MUI layouts
// import Dashboard from "layouts/dashboard";
import DocumentInfo from "layouts/document";
import Home from "layouts/home";
// import Economy from "layouts/economy";
import Company from "layouts/company";
import Industry from "layouts/industry";
// import Comparison from "layouts/comparison";
// import ReportList from "layouts/reportList";
// import Pricing from "layouts/pricing";
import Ranking from "layouts/ranking";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

const routes = [
  // {
  //   type: "route",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   route: "/dashboard",
  //   icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
  //   component: <Dashboard />,
  // },
  // {
  //   type: "route",
  //   name: "Home",
  //   key: "Home",
  //   route: "/home",
  //   icon: (
  //     <ArgonBox component="i" color="#9370DB" fontSize="14px" className="ni ni-map-big" />
  //   ),
  //   component: <Home />,
  //   display: true,
  // },
  {
    type: "route",
    name: "Home",
    key: "home",
    route: "/home",
    icon: <ArgonBox component="i" color="#FFA500" fontSize="14px" className="ni ni-map-big" />,
    component: <Home />,
    display: true,
  },
  {
    type: "route",
    name: "Leaderboard",
    key: "ranking",
    route: "/ranking/",
    icon: <ArgonBox component="i" color="success" fontSize="14px" className="ni ni-trophy" />,
    component: <Ranking />,
    display: true,
  },
  // {
  //   type: "route",
  //   name: "Economy",
  //   key: "economy",
  //   route: "/economy",
  //   icon: <ArgonBox component="i" color="#1E90FF" fontSize="14px" className="ni ni-world" />,
  //   component: <Economy />,
  //   display: true,
  // },
  {
    type: "route",
    name: "Company",
    key: "company",
    route: "/company/:ticker",
    icon: <ArgonBox component="i" color="#FF6347" fontSize="14px" className="ni ni-building" />,
    component: <Company />,
    display: true,
  },
  {
    type: "route",
    name: "Industry",
    key: "industry",
    route: "/industry/",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-chart-bar-32" />,
    component: <Industry />,
    display: true,
  },
  // {
  //   type: "route",
  //   name: "SEC Filings",
  //   key: "report",
  //   route: "/report/:ticker",
  //   icon: <ArgonBox component="i" color="#008000" fontSize="14px" className="ni ni-books" />,
  //   component: <ReportList />,
  //   display: true,
  // },
  // {
  //   type: "route",
  //   name: "Comparison",
  //   key: "comparison",
  //   route: "/comparison",
  //   icon: <ArgonBox component="i" color="#FFA500" fontSize="14px" className="ni ni-chart-bar-32" />,
  //   component: <Comparison />,
  //   display: true,
  // },
  {
    type: "route",
    name: "Document",
    key: "document",
    route: "/document/:doc_id",
    icon: <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-money-coins" />,
    component: <DocumentInfo />,
    display: false,
  },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  // {
  //   type: "route",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   icon: <ArgonBox component="i" color="dark" fontSize="14px" className="ni ni-single-02" />,
  //   component: <Profile />,
  // },
  // {
  //   type: "route",
  //   name: "Sign In",
  //   key: "sign-in",
  //   route: "/authentication/sign-in",
  //   icon: (
  //     <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-single-copy-04" />
  //   ),
  //   component: <SignIn />,
  // },
  // {
  //   type: "route",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
  //   component: <SignUp />,
  // },
  { type: "title", title: "Glossary", key: "glossary-page" },
];

export default routes;
