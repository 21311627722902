// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Shared config & utils
import * as Utils from "utils/utils";

function DocumentBase({content}) {
  return (
    <Card>
      <ArgonBox p={2}>
        <ArgonTypography variant="button" color="text" fontWeight="medium">
          {content ? (
            Utils.renderMultilineText(content)
          ) : (
            'Document not found'
          )}          
        </ArgonTypography>
      </ArgonBox>
    </Card>
  )
}

// Typechecking props for the ReportListInfo
DocumentBase.propTypes = {
  content: PropTypes.string,
};

export default DocumentBase;