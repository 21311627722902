import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

import { useArgonController } from "context";

// Page components
import IndustryChart from "layouts/industry/components/IndustryChart";
import IndustryPeer from "layouts/industry/components/IndustryPeer";
import ScoreIndustry from "layouts/company/components/ScoreIndustry";

let resultAPICallMap = new Map();

function Industry() {
  const [controller,] = useArgonController();
  const { accessInfo } = controller;
  const [errorMsg, setErrorMsg] = useState(Constants.NO_AUTHORIZED_MESSAGE);
  const [loadingIndustries, setLoadingIndustries] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [industryScore, setIndustryScore] = useState({});
  const [bestPeers, setBestPeers] = useState([]);
  const [worstPeers, setWorstPeers] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState("");
  const [selectedIndustry, setSelectedIndustry] =
    useState(Constants.DEFAULT_SELECTED_INDUSTRY);

  function get_best_and_worst_items(allItems) {
    const length = allItems.length;
    const top_n = Constants.INDUSTRY_TOP_N_RANKING;
  
    setBestPeers(allItems.slice(0, Math.min(top_n, length)));
    setWorstPeers(allItems.slice(Math.max(0, length - top_n)));
  }

  function handleIndustryChanged(industryName) {
    setSelectedIndustry(industryName);
    setLoadingIndustries(true);
  }

  useEffect(() => {
    if (accessInfo.api_key.length > 0) {
      setLoadingIndustries(true);
    }
  }, [ accessInfo ]);

  useEffect(() => {
    if (!loadingIndustries) {
      return;
    }

    const api_cmd = Constants.API_IP_ADDRESS 
      + 'get_industry_page_display?'
      + 'industry_name=' + selectedIndustry
      + '&api-key=' + accessInfo.api_key;
    const fetchData = async () => {
      fetch(api_cmd)
      .then((response) => response.json())
      .then((data) => {
        handleDataFetched(data);
        resultAPICallMap.set(api_cmd, data);
      })
      .catch((err) => {
        console.error(err.message);
        setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
        setLoadingIndustries(false);
      });
    };

    const handleDataFetched = (data) => {
      setLoadingIndustries(false);
      if (Array.isArray(data['industry_compare'])) {
        setIndustries(data['industry_compare']);
        setIndustryScore({
          'name': Utils.getKeyValue(data['sector_analysis'], 'name'),
          'analysis': Utils.getKeyValue(data['sector_analysis'], 'analysis'),
          'outlook_score': Utils.getKeyValue(data['sector_analysis'], 'outlook_score'),
          'relevant_indicators': Utils.getKeyValue(data['sector_analysis'], 'relevant_indicators'),
          'gdp_contribution_pct': Utils.getKeyValue(data['sector_analysis'], 'gdp_contribution_pct'),
          'gdp_contribution_growth': Utils.getKeyValue(data['sector_analysis'], 'gdp_contribution_growth'),
          'num_peers': Utils.getKeyValue(data, 'peers'),
        });
        setErrorMsg('');
      } else {
        setErrorMsg(Constants.NO_AUTHORIZED_MESSAGE);
      }
    };

    if (resultAPICallMap.has(api_cmd)) {
      handleDataFetched(resultAPICallMap.get(api_cmd));
    } else {
      fetchData();
    }
  }, [ accessInfo, loadingIndustries, selectedIndustry ]);

  return (
    <DashboardLayout bgColor="info" >
      <DashboardNavbar />
      <ArgonBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>
            <IndustryChart
              title={'Industry Comparison by Key Metrics'}
              summaries={industries}
              description_prefix="Industry Financial Average sorted from highest to lowest based on "
              height="97.5rem"
              setAllItems={get_best_and_worst_items}
              setSelectedItem={setSelectedMetric}
              errorMessage={errorMsg}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ScoreIndustry
                  title="Macroeconomic Trends "
                  data={industryScore}
                  industry_name={selectedIndustry}
                  showProgress={loadingIndustries}
                  selectedIndustry={selectedIndustry}
                  changeIndustryAction={handleIndustryChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <IndustryPeer
                  title={"Highest " + Utils.convertKeyToString(selectedMetric)}
                  data={bestPeers}
                  errorMessage={errorMsg}
                />
              </Grid>
              <Grid item xs={12}>
                <IndustryPeer
                  title={"Lowest " + Utils.convertKeyToString(selectedMetric)}
                  data={worstPeers}
                  errorMessage={errorMsg}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Industry;
