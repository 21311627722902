// react-routers components
import React, { useEffect, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Backdrop from '@mui/material/Backdrop';
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI examples
import Table from "examples/Tables/Table";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Page components
import ScorePeer from "layouts/company/components/ScorePeer";

function IndustryPeer({title, data, showProgress, errorMessage}) {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [openPeers, setOpenPeers] = useState(false);

  useEffect(() => {
    setColumns([
      { name: "industry", align: "left" },
      { name: "value", align: "center" },
      { name: "total_market_cap", align: "center" },
    ]);
    
    setRows(
      data.map((item) => {
        return renderRow(item.label,
                         item.value,
                         item.total_companies,
                         item.total_market_cap);
      })
    );
  }, [data]);

  function renderRow(industry, value, companies, marketCap) {
    return {
      industry: (
        <ArgonButton
          variant="text" color="secondary" size="small"
          title={industry}
          onClick={(e) => {
            setSelectedIndustry(e.target.title);
            setOpenPeers(true);
          }}
        >
          {industry}
        </ArgonButton>
      ),
      value: (
        <ArgonTypography
          variant="caption"
          color={Utils.getGrowthPctColor(value)}
          fontWeight="regular"
        >
          {Utils.formatNumber(value, '', '%')}
        </ArgonTypography>
      ),
      total_market_cap: (
        <ArgonTypography
          variant="caption"
          color="text"
          fontWeight="regular"
        >
          {Utils.formatNumberLonger(marketCap, '$', '')}
        </ArgonTypography>
      ),
    }
  }

  return (
    <Card>
      <Stack pt={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize" color="dark">
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox sx={{ ml:'auto', mr:'5px' }}>
          { showProgress ? <CircularProgress size={26} color='info' /> : null }
        </ArgonBox>
      </Stack>
      {errorMessage.length === 0 ? (
        <ArgonBox p={1.5}>
          <Table columns={columns} rows={rows} />
        </ArgonBox>
      ) : (
        <ArgonBox px={3} pb={1}>
          <ArgonTypography variant="button" fontWeight='regular' color="text">
            {errorMessage}
          </ArgonTypography>
        </ArgonBox>
      )}
      <Backdrop
        id='backdrop-score-peer'
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openPeers}
        onClick={(e) => {
          if (e.target.id === 'backdrop-score-peer'){
            setOpenPeers(false);
          }
        }}
      >
        <ScorePeer
          title={selectedIndustry}
          industryName={selectedIndustry}
          loadingCompanies={openPeers}
          hideName={Constants.RANKING_HIDE_NAME}
        />
      </Backdrop>
    </Card>
  );
}

IndustryPeer.defaultProps = {
  showProgress: false,
  errorMessage: ""
};

IndustryPeer.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  showProgress: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default IndustryPeer;
