import { useState } from "react";

// @mui material components
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from "@mui/material/Divider";
import { blue } from '@mui/material/colors';
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Stack from '@mui/material/Stack';
import Switch from "@mui/material/Switch";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from '@mui/icons-material/YouTube';

// EmailJS
import emailjs from '@emailjs/browser';

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Argon Dashboard 2 MUI context
import {
  useArgonController,
  setOpenConfigurator,
  setDarkSidenav,
  setMiniSidenav,
  setDarkMode,
} from "context";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

// Page components
import AccessInfo from "./components/AccessInfo";

function Configurator() {
  const [controller, dispatch] = useArgonController();
  const { openConfigurator, miniSidenav, darkMode } = controller;
  const [subscribing, setSubscribing] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleDarkMode = () => {
    setDarkSidenav(dispatch, !darkMode);
    setDarkMode(dispatch, !darkMode);
  };
  const handleEmailValidation = (e) => {
    //test whether input is valid
    setValidEmail(Utils.validateEmail(e.target.value));
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ArgonBox>
          <ArgonTypography variant="h5">Configuration</ArgonTypography>
          <ArgonTypography variant="caption" color="text">
            {Constants.PRODUCT_NAME + ' (' + Constants.VERSION + ')'}
          </ArgonTypography>
        </ArgonBox>

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: darkMode ? white.main : dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </ArgonBox>

      <Divider />

      <ArgonBox px={3}>
        <AccessInfo />
      </ArgonBox>

      <Divider />

      <ArgonBox pt={1.25} pb={3} px={3}>
        <ArgonBox display="flex" justifyContent="space-between" lineHeight={1}>
          <ArgonTypography variant="h6">Light/Dark</ArgonTypography>
          <Switch checked={darkMode} onChange={handleDarkMode} />
        </ArgonBox>

        <Divider />

        {/* <ArgonBox display="flex" justifyContent="space-between" lineHeight={1}>
          <ArgonTypography variant="h6">Show Notification</ArgonTypography>

          <Switch checked={showNotification} onChange={handleShowNotification} />
        </ArgonBox> */}

        {/* <Divider /> */}

        <ArgonBox display="flex" justifyContent="space-between" lineHeight={1}>
          <ArgonTypography variant="h6">Minimize Sidebar</ArgonTypography>

          <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
        </ArgonBox>

        <Divider />

        <ArgonBox mt={3} lineHeight={1}>
          <ArgonTypography variant="h6">Subscribe to our newsletter</ArgonTypography>
          <Stack spacing={2} pt={2}>
            <ArgonInput 
              type="email" 
              placeholder="Email" 
              onChange={(e) => {
                handleEmailValidation(e);
                setSubscribeEmail(e.target.value);
              }}
              error={!validEmail}
            />
            <Box sx={{ m: 1, position: 'relative' }}>
              <ArgonButton
                color='info'
                variant="contained"
                disabled={!validEmail}
                fullWidth
                onClick={() => {
                  setSubscribing(true);
                  const formJson = {
                    from_email: subscribeEmail,
                    from_name: "Subscriber",
                    message: "There is new subscriber: " + subscribeEmail
                  }

                  emailjs
                    .send(Constants.EMAILJS_SERVICE_ID, Constants.EMAILJS_TEMPLATE_ID, formJson, {
                      publicKey: Constants.EMAILJS_PUBLIC_KEY,
                    })
                    .then(
                      () => {
                        setSubscribing(false);
                        handleCloseConfigurator();
                      },
                      (error) => setSubscribing(false),
                    );
                }}
              >
                <Icon>subscriptions</Icon>&nbsp;
                Subscribe
              </ArgonButton>
              {subscribing && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: blue[700],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
          </Stack>
        </ArgonBox>

        <Divider />

        <ArgonBox mt={5} mb={2}>
          {/* <ArgonBox mb={2}>
            <ArgonButton
              component={Link}
              href="/pricing"
              rel="noreferrer"
              color={darkMode ? "white" : "dark"}
              variant="outlined"
              fullWidth
            >
              Join Us
            </ArgonButton>
          </ArgonBox> */}
          <ArgonBox mb={2}>
            <ArgonButton
              component={Link}
              href="/document/terms"
              target="_blank"
              rel="noreferrer"
              color={darkMode ? "white" : "dark"}
              variant="outlined"
              fullWidth
            >
              Terms of Service
            </ArgonButton>
          </ArgonBox>
          <ArgonButton
            component={Link}
            href="/document/policy"
            target="_blank"
            rel="noreferrer"
            color={darkMode ? "white" : "dark"}
            variant="outlined"
            fullWidth
          >
            Privacy Policy
          </ArgonButton>
        </ArgonBox>

        <ArgonBox mt={3} textAlign="center">
          <ArgonBox mb={0.5} pb={2}>
            <ArgonTypography variant="h6">Thank you for sharing!</ArgonTypography>
          </ArgonBox>

          <ArgonBox display="flex" justifyContent="center">
            <ArgonBox mr={1.5}>
              <ArgonButton
                component={Link}
                href="//twitter.com/intent/tweet?text=Check%20Invests4U%20%20made%20by%20%40Speed%20AI&url=https%3A%2F%2Fwww.invests4u.com%2F"
                target="_blank"
                rel="noreferrer"
                color="dark"
                size='medium'
                iconOnly
              >
                <TwitterIcon />
                {/* &nbsp; Tweet */}
              </ArgonButton>
            </ArgonBox>

            <ArgonBox mr={1.5}>
              <ArgonButton
                component={Link}
                href="https://www.facebook.com/sharer/sharer.php?u=https://www.invests4u.com/"
                target="_blank"
                rel="noreferrer"
                color="dark"
                size='medium'
                iconOnly
              >
                <FacebookIcon />
                {/* &nbsp; Share */}
              </ArgonButton>
            </ArgonBox>
            
            <ArgonBox mr={1.5}>
              <ArgonButton
                component={Link}
                href="https://www.youtube.com/@SpeedAI-LLC"
                target="_blank"
                rel="noreferrer"
                color="dark"
                size='medium'
                iconOnly
              >
                <YouTubeIcon />
                {/* &nbsp; Channel */}
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
