// react-routers components
import { useState } from "react";
import { useParams } from 'react-router-dom'

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Page components
import DocumentBase from "layouts/document/DocumentBase";

// Data
import policy from "assets/data/PrivacyPolicy.txt"
import terms from "assets/data/TermsAndAgreements.txt"

function DocumentInfo() {
  const { doc_id } = useParams()
  const [termsContent, setTermsContent] = useState('');
  const [policyContent, setPolicyContent] = useState('');

  if (termsContent.length === 0) {
    fetch(terms)
    .then(r => r.text())
    .then(text => {
      setTermsContent(text);
    });
  }

  if (policyContent.length === 0) {
    fetch(policy)
    .then(r => r.text())
    .then(text => {
      setPolicyContent(text);
    });
  }
  

  const getDocContent = () => {
    if (doc_id === 'terms') {
      return termsContent;
    } else if (doc_id === 'policy') {
      return policyContent;
    } else {
      return undefined;
    }
  }

  return (
    <DashboardLayout bgColor="info">
      <DashboardNavbar />
      <ArgonBox py={3}>
        <DocumentBase content={getDocContent()} />
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DocumentInfo;
