// API Server
export const API_IP_ADDRESS = 'https://api-server-dmh6dmx66q-wn.a.run.app/'
// export const API_IP_ADDRESS = 'http://localhost:8080/'
export const INVALID_PROPERTY_VALUE = ''
export const PRODUCT_NAME = 'INVESTS4U'
export const VERSION = 'v1.2'

export const MAX_KEPT_NOTIFICATION = 5;
export const MIN_NEWS_KEEP = 3;
export const MIN_EXECUTIVE_KEEP = 3;
export const MIN_PROFILE_DESCRIPTION_LENGTH = 360;
export const NUM_TABLE_ROWS_IN_SHRUNK = 3;
export const TOP_N_COMPANY = 10;
export const TOP_N_COMPANY_PER_PAGE = 10;
export const METRIC_CHART_DEFAULT_KEY = 'revenue_growth_QoQ';
export const SCROLL_Y_MINIMUM = 100;

export const EMAILJS_PUBLIC_KEY = 'kqI9Rkj45XdV_sYpn'
export const EMAILJS_SERVICE_ID = 'service_04lcup6'
export const EMAILJS_TEMPLATE_ID = 'template_9xwfk65'

export const CIRCULAR_SCORE_SIZE = 180;
export const CIRCULAR_SCORE_WITH_LABEL_SIZE = 220;
export const CIRCULAR_SCORE_SIZE_SMALL = 31;

export const RANKING_LENGTH_COMPANY_NAME = 35;
export const SCORE_PEER_MAX_LENGTH_COMPANY_NAME = 25;

export const FILTER_MARKET_CAP_SLIDER_MIN = 20; // value = 2**20 = 1M
export const FILTER_MARKET_CAP_SLIDER_MAX = 44; // value = 2**44 = 18T
export const FILTER_SCORE_WEIGHT_MAX = 10; // need to scale back to 0 -> 1
export const FILTER_LIQUIDITY_WEIGHT = 10;
export const FILTER_PROFITABILITY_WEIGHT = 10;
export const FILTER_VALUATION_WEIGHT = 10;
export const FILTER_DIVIDENDS_WEIGHT = 2;
export const FILTER_GROWTH_WEIGHT = 10;
export const FILTER_CASH_FLOW_WEIGHT = 10;
export const FILTER_VOLATILITY_WEIGHT = 2;
export const FILTER_MACROECONOMY_WEIGHT = 10;

export const INDUSTRY_PEER_TOP_N = 15;

export const BLACKDROP_MAX_HEIGHT = "450px";

export const RANKING_TOP_N = 100;
export const RANKING_INVESTMENT_TOP_N = 100;
export const RANKING_TOP_N_PER_PAGE = 20;
export const RANKING_HIDE_NAME = true;
export const RANKING_FILTER_DEFAULT = {
  marketCapRange: [
    2 ** FILTER_MARKET_CAP_SLIDER_MIN, 2 ** FILTER_MARKET_CAP_SLIDER_MAX
  ],
  liquidityWeight: FILTER_LIQUIDITY_WEIGHT/10,
  profitabilityWeight: FILTER_PROFITABILITY_WEIGHT/10,
  valuationWeight: FILTER_VALUATION_WEIGHT/10,
  dividendsWeight: FILTER_DIVIDENDS_WEIGHT/10,
  growthWeight: FILTER_GROWTH_WEIGHT/10,
  cashFlowWeight: FILTER_CASH_FLOW_WEIGHT/10,
  volatilityWeight: FILTER_VOLATILITY_WEIGHT/10,
  macroeconomyWeight: FILTER_MACROECONOMY_WEIGHT/10
}

export const INDUSTRY_TOP_N_RANKING = 12;

export const DEFAULT_SELECTED_INDUSTRY = 'Semiconductors';

export const NO_AUTHORIZED_MESSAGE =
  'You are not authorized to view this content or exceeded your monthly quota.';
export const NO_SECTOR_ANALYSIS_MESSAGE =
  'Not available';
export const VALIDATION_FAILED_MESSAGE =
  'Invalid Access Key'