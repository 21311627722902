// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Argon Dashboard 2 MUI components
// import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Shared config & utils
import * as Utils from "utils/utils";

function TableHTMLDialog({open, setOpen, title, table_html}) {
  title = title || "";

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Card>
          <ArgonTypography
            variant="button"
            color="text"
            fontWeight="medium"
            sx={{ 
              overflowX: 'scroll',
              overflowY: 'scroll',
            }}>
            {Utils.renderMultilineText(table_html)}
          </ArgonTypography>
        </Card>
      </DialogContent>
      {/* <DialogActions>
        <ArgonButton
          color='secondary'
          variant="contained"
          disabled={sending}
          onClick={handleClose}
        >
          <Icon>cancel</Icon>&nbsp;
          Cancel
        </ArgonButton>
        <ArgonButton
          color='info'
          variant="contained"
          disabled={sending}
          type="submit"
        >
          <Icon>send</Icon>&nbsp;
          Send
        </ArgonButton>
      </DialogActions> */}
    </Dialog>
  );
}

// Typechecking props for the ReportListInfo
TableHTMLDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  table_html: PropTypes.string.isRequired,
};

export default TableHTMLDialog;