// react-routers components
import React, { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Backdrop from '@mui/material/Backdrop';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';

// Argon Dashboard 2 MUI components
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI context
import { useArgonController } from "context";

// Shared config & utils
import * as Utils from "utils/utils";

function SpaiTypographyWithGlossary({ text, variant, color, fontWeight, textTransform,
                                      verticalAlign, textGradient, opacity }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [controller, _] = useArgonController();
  const { glossary } = controller;
  const description = Utils.getKeyValue(glossary, text);

  return (
    <div>
      {description.length > 0 ? (
        <ArgonTypography variant={variant} fontWeight={fontWeight} color={color}
          title={text}
          sx={{
            "&:hover": {
              textDecoration: "underline"
            },
          }}
          onClick={(event) => {
            if (description.length > 0) {
              setAnchorEl(event.target);
            }
          }}
        >
          {text}
        </ArgonTypography>
      ) : (
        <ArgonTypography variant={variant} fontWeight={fontWeight} color={color}>
          {text}
        </ArgonTypography>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={anchorEl !== null && anchorEl.title === text}
      >
        <Popover
          id={text}
          open={anchorEl !== null && anchorEl.title === text}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Stack sx={{ maxWidth: "400px" }}>
            {description.split('. ').map((content, index) => {
              content = content.replaceAll('[]', '');
              if (content === undefined || content.length === 0) {
                return null;
              }
              return (
                <ArgonTypography key={index} variant="caption" color="dark" display="block" pb={0.5}>
                  &#x27A3; {content}
                </ArgonTypography>
              )}
            )}
          </Stack>
        </Popover>
      </Backdrop>
    </div>
  )
}

SpaiTypographyWithGlossary.defaultProps = {
  text: "",
  variant: "button",
  fontWeight: "bold",
  color: "text",
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};

SpaiTypographyWithGlossary.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "white",
    "greatScore",
    "goodScore",
    "averageScore",
    "badScore",
    "veryBadScore",
  ]),
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
  verticalAlign: PropTypes.oneOf([
    "unset",
    "baseline",
    "sub",
    "super",
    "text-top",
    "text-bottom",
    "middle",
    "top",
    "bottom",
  ]),
  textGradient: PropTypes.bool,
  opacity: PropTypes.number,
  variant: PropTypes.string.isRequired,
};

export default SpaiTypographyWithGlossary;