import { useEffect, useState } from "react";

import Chart from 'chart.js/auto';

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from "@mui/material/Stack";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

function IndustryChart({ title, default_metric, summaries, description_prefix,
                       height, setAllItems, setSelectedItem, errorMessage }) {
  const [currentLabels, setCurrentLabels] = useState([]);
  const [currentDataPos, setCurrentDataPos] = useState([]);
  const [currentDataNev, setCurrentDataNev] = useState([]);
  const [currentMetric, setCurrentMetric] = useState('');
  const ignoredMetricKeys = new Set(['name', 'total_companies']);
  const metricNames = getMetricKeys(summaries);

  function getMetricKeys (summaries) {
    if (summaries.length > 0) {
      // remove some keys specified in `ignoredMetricKeys`, then sort alphabetically
      return Object.keys(summaries[0])
                   .filter(key => !ignoredMetricKeys.has(key))
                   .sort();
    }
    return [];
  }

  function separateDataArrayIntoPosAndNev(dataArray) {
    const positiveData = [];
    const negativeData = [];
  
    dataArray.forEach((item) => {
      if (item.value >= 0) {
        positiveData.push({ label: item.label, value: item.value });
        negativeData.push({ label: item.label, value: 0 });
      } else {
        positiveData.push({ label: item.label, value: 0 });
        negativeData.push({ label: item.label, value: item.value });
      }
    });
  
    return [ positiveData, negativeData ];
  };

  function changeMetric(metric) {
    let data = [];
    for (var i = 0; i < summaries.length; i++) {
      data.push({
        label: summaries[i]['name'],
        value: summaries[i][metric],
        total_companies: summaries[i]['total_companies'],
        total_market_cap: summaries[i]['total_market_cap'],
      })
    }

    // separate the data into positive & negative sets
    data = data.sort((a, b) => a.value < b.value);
    const [posData, nevData] = separateDataArrayIntoPosAndNev(data);

    setCurrentLabels(data.map((item) => item.label));
    setCurrentDataPos(posData.map((item) => item.value ));
    setCurrentDataNev(nevData.map((item) => item.value ));
    setCurrentMetric(metric);

    if (setAllItems) {
      setAllItems(data);
    }
    
    if (setSelectedItem) {
      setSelectedItem(metric);
    }
  }

  useEffect(() => {
    if (summaries.length > 0 && currentLabels.length === 0) {
      changeMetric(default_metric);
    }
  }, [ currentLabels, summaries, default_metric ]);

  return (
    <Card id="delete-account">
      <Stack pt={2} px={2} direction="row">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </ArgonTypography>
        </ArgonBox>
      </Stack>
      {errorMessage.length === 0 ? (
        <ArgonBox px={1} pb={1}>
          <Grid container>
            <Grid item xs={12} display='flex' justifyContent='center' alignItems='center'>
              <ArgonBox style={{ width: '300px' }} py={2}>
                <Select
                  value={currentMetric}
                  onChange={(e) => changeMetric(e.target.value)}
                  autoWidth
                  label="Select metric"
                  sx={{
                    borderColor: '#11cdef',
                  }}
                >
                  {metricNames.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {Utils.convertKeyToString(item)}
                      </MenuItem>
                    )
                  })}
                </Select>
              </ArgonBox>
            </Grid>
            <Grid item xs={12}>
              {(currentLabels.length > 0) ? (
                <HorizontalBarChart
                  height={height}
                  thickness={13}
                  description={description_prefix + Utils.convertKeyToString(currentMetric)}
                  chart={{
                    labels: currentLabels,
                    datasets: [
                      {
                        label: Utils.convertKeyToString(currentMetric),
                        color: "success",
                        data: currentDataPos,
                      },
                      {
                        label: Utils.convertKeyToString(currentMetric),
                        color: "error",
                        data: currentDataNev,
                      },
                    ],
                  }}
                />
              ) : (null) }
            </Grid>
          </Grid>
        </ArgonBox>
      ) : (
        <ArgonBox px={3} pb={1}>
          <ArgonTypography variant="button" fontWeight='regular' color="text">
            {errorMessage}
          </ArgonTypography>
        </ArgonBox>
      )}
    </Card>
  );
}

IndustryChart.defaultProps = {
  default_metric: Constants.METRIC_CHART_DEFAULT_KEY,
  description_prefix: "",
  height: "20rem",
  errorMessage: ""
};

// Typechecking props for the NewsInfo
IndustryChart.propTypes = {
  title: PropTypes.string.isRequired,
  default_metric: PropTypes.string,
  description_prefix: PropTypes.string,
  summaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  height: PropTypes.string.isRequired,
  setAllItems: PropTypes.func,
  setSelectedItem: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default IndustryChart;
