import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components
import { blue } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from "@mui/material/Icon";
import Stack from '@mui/material/Stack';

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController, setAccessInfo } from "context";

// @mui icons
import HowToRegIcon from '@mui/icons-material/HowToReg';

// Shared config & utils
import * as Constants from "config/Constants";
import * as Utils from "utils/utils";

let resultCallMap = new Map(); // for caching API call results

function AccessInfo() {
  const [controller, dispatch] = useArgonController();
  const { darkMode } = controller;
  const [reloadAccessKey, setReloadAccessKey] = useState(true);
  const [validating, setValidating] = useState(false);
  const [accessKey, setAccessKey] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [validUntil, setValidUntil] = useState('');
  const [currentQuota, setCurrentQuota] = useState(0);
  const [maxQuota, setMaxQuota] = useState(0);
  const [validationMesg, setValidationMesg] = useState('');
  
  function handleDeleteClick() {
    Utils.deleteAccessKeyFromLocalStorage();
    setReloadAccessKey(true);
  }

  useEffect(() => {
    if (!validating) {
      return;
    }

    const api_cmd = Constants.API_IP_ADDRESS + 
      'validate_user_by_key?api_key=' + accessKey;
    const fetchData = async () => {
      fetch(api_cmd)
      .then((response) => response.json())
      .then((data) => {
        handleDataFetched(data);
        resultCallMap.set(api_cmd, data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    };

    const handleDataFetched = (data) => {
      setValidating(false);
      if (data) {
        Utils.saveAccessKeyToLocalStorage(data);
        setReloadAccessKey(true);
        setValidationMesg('');
      } else {
        setValidationMesg(Constants.VALIDATION_FAILED_MESSAGE);
      }
    };

    if (resultCallMap.has(api_cmd)) {
      handleDataFetched(resultCallMap.get(api_cmd));
    } else {
      fetchData();
    }
  }, [ accessKey, validating ]);

  useEffect(() => {
    if (!reloadAccessKey) {
      return;
    }
    const data = Utils.loadAccessKeyFromLocalStorage();
    if (data) {
      setSelectedPlan(data.selected_plan.toUpperCase());
      setValidUntil(data.expired_at);
      setCurrentQuota(data.current_month_ticker_viewed);
      setMaxQuota(data.total_ticker_viewed);
      setAccessInfo(dispatch, data);
    } else {
      setSelectedPlan('');
      setValidUntil('');
      setCurrentQuota(0);
      setMaxQuota(0);
    }
    setReloadAccessKey(false);
  }, [ dispatch, reloadAccessKey ]);

  // const runPeriodicMethod = () => {
  //   // Get the current date
  //   const today = new Date();
  //   const targetDate = parseDateTime(validUntil);
  
  //   // Compare the date values
  //   if (targetDate < today) {
  //     handleDeleteClick();
  //   }
  // }

  // function parseDateTime(dateTimeString) {
  //   // Split the string into parts
  //   const parts = dateTimeString.split('-');
  
  //   // Extract the individual components
  //   const day = parseInt(parts[1], 10);
  //   const month = parseInt(parts[0], 10) - 1; // Months are zero-indexed in JavaScript
  //   const year = parseInt(parts[2], 10);
  
  //   // Create a new Date object
  //   return new Date(year, month, day);
  // }

  // useEffect(() => {
  //   const interval = setInterval(runPeriodicMethod, 90000000); // Run the method every 5h

  //   // Clean up the interval when the component is unmounted
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <ArgonBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      borderRadius="lg"
      p={1}
      pl={1}
      sx={({ palette: { grey, background } }) => ({
        backgroundColor: darkMode ? background.default : grey[100],
      })}
    >
      {selectedPlan.length > 0 ? (
        <ArgonBox width="100%" display="flex" flexDirection="column">
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={1}
          >
            <ArgonTypography variant="button" fontWeight="medium" textTransform="capitalize">
              Access Key
            </ArgonTypography>

            <ArgonBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}
            >
              <ArgonBox mr={1}>
                <ArgonButton variant="text" color="error" onClick={handleDeleteClick}>
                  <Icon>delete</Icon>
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox mb={1} lineHeight={0}>
            <ArgonTypography variant="caption" color="text">
              Subscription Plan:&nbsp;&nbsp;&nbsp;
              <ArgonTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                {selectedPlan}
              </ArgonTypography>
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox mb={1} lineHeight={0}>
            <ArgonTypography variant="caption" color="text">
              Valid Until:&nbsp;&nbsp;&nbsp;
              <ArgonTypography variant="caption" fontWeight="medium">
                {validUntil}
              </ArgonTypography>
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox mb={1} lineHeight={0}>
            <ArgonTypography variant="caption" color="text">
              This Month Quota:&nbsp;&nbsp;&nbsp;
              <ArgonTypography variant="caption" fontWeight="medium">
                {currentQuota}/{maxQuota}
              </ArgonTypography>
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      ) : (
        <Stack spacing={1.5} px={1} pb={1} width="100%">
          <ArgonTypography color='error' variant="button" fontWeight="medium">
            {validationMesg}
          </ArgonTypography>
          <ArgonInput
            placeholder="Enter access key here..."
            startAdornment={<ArgonBox component="i" className="ni ni-key-25" mr={1} />}
            onChange={(e) => {
              setAccessKey(e.target.value);
            }}
          />
          <ArgonBox sx={{ m: 1, position: 'relative' }}>
            <ArgonButton
              color='info'
              variant="contained"
              fullWidth
              onClick={() => {
                setValidating(true);
              }}
            >
              <HowToRegIcon />&nbsp; Validate
            </ArgonButton>
            {validating && (
              <CircularProgress
                size={24}
                sx={{
                  color: blue[700],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                  zIndex: 1,
                }}
              />
            )}
          </ArgonBox>
        </Stack>
      )}
    </ArgonBox>
  );
}

// Setting default values for the props of AccessInfo
// AccessInfo.defaultProps = {
//   noGutter: false,
// };

// // Typechecking props for the AccessInfo
// AccessInfo.propTypes = {
//   expired_at: PropTypes.string.isRequired,
//   selected_plan: PropTypes.string.isRequired,
// };

export default AccessInfo;
