// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Stack from "@mui/material/Stack";

// Custom styles for ArgonTypography
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI contexts
import { useArgonController } from "context";

// SPAI components
import SpaiTypographyWithGlossary from "components/SpaiTypographyWithGlossary";

function SpaiMultiTypographyWithLabel({
  contents, label, showContent, labelVariant, labelColor, labelFontWeight,
  contentVariant, contentColor, contentFontWeight, spaceGap, contentIndent
}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <div>
      {showContent ? (
        <div>
          <SpaiTypographyWithGlossary text={label}
            variant={labelVariant} fontWeight={labelFontWeight} color={labelColor}
          />
          <ArgonBox p={1} pt={spaceGap} borderRadius="md"
            sx={({ palette: { grey, background } }) => ({
              backgroundColor: darkMode ? background.default : grey[100],
            })}
          >
            <Stack pt={0.5} pl={contentIndent}>
              {contents.map((content, index) => {
                if (content === undefined) {
                  return null;
                }
                content = content.replaceAll('[]', '')
                                 .replaceAll('N/A', '')
                                 .replaceAll("['", '')
                                 .replaceAll("']", '.')
                                 .replaceAll("', '", ', ');
                if (content.length === 0) {
                  return null;
                }
                return (
                  <ArgonTypography key={index}
                    variant={contentVariant} color={contentColor}
                    fontWeight={contentFontWeight} pb={1}
                  >
                    &#x27A3;  {content}
                  </ArgonTypography>
                )}
              )}
            </Stack>
          </ArgonBox>
        </div>
        ) : (
          <SpaiTypographyWithGlossary text={label} />
        )
      }
    </div>
  )
};

SpaiMultiTypographyWithLabel.defaultProps = {
  contents: "",
  label: "",
  showContent: false,
  labelVariant: "button",
  labelFontWeight: "bold",
  labelColor: "text",
  contentVariant: "caption",
  contentColor: "dark",
  contentFontWeight: "regular",
  spaceGap: 1,
  contentIndent: 0
};

SpaiMultiTypographyWithLabel.propTypes = {
  contents: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  showContent: PropTypes.bool,
  labelVariant: PropTypes.string,
  labelColor: PropTypes.string,
  labelFontWeight: PropTypes.string,
  contentVariant: PropTypes.string,
  contentColor: PropTypes.string,
  contentFontWeight: PropTypes.string,
  spaceGap: PropTypes.number,
  contentIndent: PropTypes.number,
};

export default SpaiMultiTypographyWithLabel;
