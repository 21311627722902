import { useEffect, useRef, useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Parallax, Pagination, Navigation } from 'swiper/modules';

// import './styles.css';
import "assets/css/swiper_parallax.css";

// Shared config & utils
import * as Utils from "utils/utils";

// Images
import intro_bg from "assets/images/intro_bg.jpg";

// Data
import intro from "assets/data/Introduction.txt"

const SwiperIntroduction = ({ title }) => {
  const progressCircle = useRef(null);
  const [loadingContent, setLoadingContent] = useState(true);
  const [pageContents, setPageContents] = useState([]);

  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
  };

  useEffect(() => {
    if (!loadingContent) {
      return;
    }
  
    fetch(intro)
    .then(r => r.text())
    .then(text => {
      // setIntroduction(text);
      setPageContents(text.split('<br>'));
      setLoadingContent(false);
    });
  }, [ loadingContent ]);

  return (
    <Swiper
      style={{
        // navigation configs
        '--swiper-navigation-color': '#fff',
        '--swiper-navigation-size': '20px',
        '--swiper-navigation-top-offset': '50%',
        '--swiper-navigation-sides-offset': '10px',
        // pagination configs
        '--swiper-pagination-color': '#fff',
        '--swiper-pagination-left': 'auto',
        '--swiper-pagination-right': '8px',
        '--swiper-pagination-bottom': '8px',
        '--swiper-pagination-top': 'auto',
        '--swiper-pagination-fraction-color': 'inherit',
        '--swiper-pagination-progressbar-bg-color': 'rgba(0, 0, 0, 0.25)',
        '--swiper-pagination-progressbar-size': '4px',
        '--swiper-pagination-bullet-size': '8px',
        '--swiper-pagination-bullet-width': '8px',
        '--swiper-pagination-bullet-height': '8px',
        '--swiper-pagination-bullet-inactive-color': '#fff',
        '--swiper-pagination-bullet-inactive-opacity': '0.2',
        '--swiper-pagination-bullet-opacity': '1',
        '--swiper-pagination-bullet-horizontal-gap': '4px',
        '--swiper-pagination-bullet-vertical-gap': '6px',
        // scrollbar
        // --swiper-scrollbar-border-radius: 10px;
        // --swiper-scrollbar-top: auto;
        // --swiper-scrollbar-bottom: 4px;
        // --swiper-scrollbar-left: auto;
        // --swiper-scrollbar-right: 4px;
        // --swiper-scrollbar-sides-offset: 1%;
        // --swiper-scrollbar-bg-color: rgba(0, 0, 0, 0.1);
        // --swiper-scrollbar-drag-bg-color: rgba(0, 0, 0, 0.5);
        // --swiper-scrollbar-size: 4px;        
        height: '100%',
        borderRadius: '20px',
      }}
      autoplay={{
        delay: 7000,
        disableOnInteraction: false,
      }}
      speed={600}
      navigation={true}
      parallax={true}
      pagination={{
        clickable: true,
      }}
      modules={[ Autoplay, Parallax, Pagination, Navigation ]}
      className="mySwiper"
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      loop={true}
    >
      <div
        slot="container-start"
        className="parallax-bg"
        style={{
          backgroundImage: `url(${intro_bg})`,
          opacity: '0.3',
        }}
        data-swiper-parallax="-23%"
      ></div>
      {pageContents.map((item, index) => 
        <SwiperSlide key={index}>
          <div className="title" data-swiper-parallax="-300">
            {title}
          </div>
          <div className="text" data-swiper-parallax="-100">
              {Utils.renderMultilineText(item)}
          </div>
        </SwiperSlide>
      )}
      <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
      </div>
    </Swiper>
  );
};

// SwiperIntroduction.defaultProps = {
//   showProgress: false,
// };

SwiperIntroduction.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SwiperIntroduction;